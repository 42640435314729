<div class="h-full">
  <div class="relative px-4 drag h-5/6 flex flex-col justify-center items-center" [ngClass]="{'no-file' : emptyFile }">
    <input type="file" id="fileDropRef" (change)="fileBrowseHandler($event.target)" class="file-uploader h-full cursor-pointer" data-test="uploadDocumentInputFile"/>
    <div>
      <mat-icon svgIcon="upload" color="primary"></mat-icon>
    </div>
    <span for="fileDropRef" class="text-center body-b2 cursor-pointer">{{'uploadDocuments.drag' | translate}}</span>
  </div>
  <div *ngIf="emptyFile" class="caption-c2 my-1 error-500 flex justify-center">{{'uploadDocuments.emptyFile' | translate}}</div>
  <div *ngIf="file" class="mt-2 body-b2 truncate">{{file.name}}</div>
</div>

