import { Location } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DEFAULT } from '../../../../../common/models/constants';
import { LimitMessage, Subscription, Usage } from '../../../../../common/models/main';
import { CustomQuery } from '../../../../../common/services/api.service';
import { MainHelperService } from '../../../../../common/shared/main-helper.service';

@Injectable({ providedIn: 'root' })
export class HelperService extends MainHelperService {

  constructor(protected translate: TranslateService,
              protected fb: FormBuilder,
              protected location: Location,
              protected router: Router,
              @Inject(LOCALE_ID) protected locale: string) {
    super(translate, fb, location, router, locale);
  }

  goBack() {
    if (this.previousURL) this.location.back();
    else this.router.navigate(['record-list']);
  }

  checkLimits(usage: Usage, subscription: Subscription, productName: 'records' | 'members' | 'storage'): LimitMessage | null {
    const product = subscription.products[productName];
    const res: LimitMessage = {
      text: '',
      usage: (productName === 'records') ? usage.products[productName].period : usage.products[productName].total, // Si son expedientes se usan los de este mes, si son invitaciones o almacenamiento se usa el acumulado total
      included: product.included,
      progress: 0,
      step: 'regular',
      i18n: {
        product: this.translate.instant('product.' + productName),
        products: this.translate.instant('products.' + productName),
      },
    };

    if (res.included === null) return null;

    const value = Number(res.usage);
    const included = Number(res.included);
    if (isNaN(value) || isNaN(included)) {
      console.error('Los valores recibidos no son convertibles a numeros, esto no deberia suceder');
      return null;
    }
    res.progress = Number((value * 100 / included).toFixed(2));
    // Si el limite es 0 o hemos superado el limite blando lo corregimos por 100%
    if (included === 0 || res.progress > 100) res.progress = 100;

    // Has llegado al limite duro
    if (product.limit !== null && Number(res.usage) >= Number(product.limit)) {
      res.text = this.translate.instant('limits.in-limit', { products: res.i18n.products });
      res.step = 'in-limit';
      return res;
    }

    // Has pasado el limite blando pero aun puedes seguir pagando
    if (value >= included) {
      res.text = this.translate.instant('limits.over-included', { product: res.i18n.product, products: res.i18n.products, extraPrice: product.extraPrice });
      res.step = 'over-included';
      return res;
    }

    // No has llegado a el limite blando pero estas cerca
    if (res.progress > 80) {
      res.text = this.translate.instant('limits.warning', { product: res.i18n.product, products: res.i18n.products, extraPrice: product.extraPrice });
      res.step = 'warning';
      return res;
    }
    return res;
  }

  sortItems(sortState: Sort) {
    const query: CustomQuery = { filter: {}, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
    if (sortState.direction === 'asc') query.sort = [sortState.active];
    else if (sortState.direction === 'desc') query.sort = ['-' + sortState.active];
    else query.sort = undefined;
    this.router.navigate([], { replaceUrl: true, queryParams: { sort: query.sort }, queryParamsHandling: 'merge' });
    return query;
  }

}
