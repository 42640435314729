import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GenericModalData } from '../../models/main';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {

  constructor(private dialogRef: MatDialogRef<GenericModalComponent>, @Inject(MAT_DIALOG_DATA) public data: GenericModalData) { }

  closeModal(value: any) {
    this.dialogRef.close(value);
  }

  // EJEMPLO DE USO
  // openGenericModal() {
  //   const data: GenericModalData = this.helper.translateModal({
  //     title: 'TITULO',
  //     content: 'Lorem ipsum dolor sit amet. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia est laborum',
  //     buttons: [{ label: 'VOLVER', value: false, type: 'link' }, { label: 'BUTTON', value: false, type: 'button' }],
  //   }, { parametro: 'valor' });
  //   return this.dialog.open(GenericModalComponent, { data });
  // }
}
