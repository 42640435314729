import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-info-hover[sentence][position]',
  templateUrl: './info-hover.component.html',
  styleUrls: ['./info-hover.component.scss'],
})
export class InfoHoverComponent {
  @Input() sentence: string = '';
  @Input() position: TooltipPosition = 'above';
}
