import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input[control]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() type: 'text' | 'password' | 'textarea' | 'number' | 'time' = 'text';
  @Input() label?: string | undefined;
  @Input() placeholder?: string | undefined;
  @Input() decimals: number = 0;
  @Input() isNegativeAllowed: boolean = false;
  @Input() hint?: string;

  @Input() set control(value: AbstractControl | null) {
    // Esto nos ayudara a debuggear cuando no le damos el form correcto a un input nos chillara diciendo el nombre del input.
    if (!value) setTimeout(() => console.error('Falta el controlador de label: ' + this.label), 500);
    this._control = value as FormControl;
  }

  hide: boolean = false;
  _control!: FormControl;
}
