import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom, noop, Subscription } from 'rxjs';

import { GenericModalComponent } from '../../../../../../../common/components/generic-modal/generic-modal.component';
import { InvitedMembers } from '../../../../../../../common/models/administration';
import { DEFAULT, MODAL_WIDTH } from '../../../../../../../common/models/constants';
import { GenericModalData, INVITATION_STATUS, LimitMessage, NOTIFICATION_MANAGER_DAYS, OrganizationExtended, User } from '../../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../../../common/shared/custom-validators';
import { FormBase } from '../../../../../../../common/shared/form-base';
import { CacheService } from '../../../../core/services/cache.service';
import { HelperService } from '../../../../core/services/helper.service';

@Component({
  selector: 'app-organization-member',
  templateUrl: './organization-member.component.html',
  styleUrls: ['./organization-member.component.scss'],
})
export class OrganizationMemberComponent extends FormBase implements OnInit {
  INVITATION_STATUS = INVITATION_STATUS;
  NOTIFICATION_MANAGER_DAYS: number[] = Object.values(NOTIFICATION_MANAGER_DAYS).map(el => Number(el));
  hours: string[] = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  organization!: OrganizationExtended;
  inviteMemberToOrganization!: FormGroup;
  managerInfo!: FormGroup;
  isFormVisible: boolean = false;
  dataSource: MatTableDataSource<InvitedMembers> = new MatTableDataSource<InvitedMembers>([]);
  icons: any = { [INVITATION_STATUS.accepted]: 'circle-check', [INVITATION_STATUS.pending]: 'clock', [INVITATION_STATUS.rejected]: 'info' };
  displayedColumns: string[] = ['name', 'email', 'invitedAt', 'registeredAt', 'isAdmin', 'isManager', 'apikey', 'inviteStatus'];
  query: CustomQuery = { pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  totalElements = 0;
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  user!: User;
  timezone!: string;
  limit: LimitMessage | null = null;
  apiKeyControl = this.fb.control('365', [Validators.required]);
  subscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('bottom') bottom!: ElementRef;
  @ViewChild('apiKeyTemplate') apiKeyTemplate!: TemplateRef<any>;


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: NonNullableFormBuilder,
    private api: ApiService,
    private cache: CacheService,
    private helper: HelperService,
    private dialog: MatDialog,
  ) {
    super();
  }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.organization = this.user.organization!;
    this.api.getLastUsage(this.organization.id).subscribe(usage => {
      this.limit = this.helper.checkLimits(usage, this.user.organization!.subscription, 'members');
    });
    this.getMemberInvitations(this.query);
    this.checkTimezone();
    this.inviteMemberToOrganization = this.createInviteMemberForm();
    this.managerInfo = this.createManagerInfoForm(this.organization);
  }

  checkTimezone() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.organization.settings && this.organization.settings.inactiveRecordNotificationTimezone === 'UTC') this.organization.settings.inactiveRecordNotificationTimezone = this.timezone;
  }

  createInviteMemberForm() {
    return this.fb.group({
      welcomeMessage: ['', []],
      users: this.fb.array([]),
    });
  }

  getMemberInvitations(query: CustomQuery) {
    this.subscription = this.api.getMembersFormOrganization(this.organization.id, query).subscribe(res => {
      // Se quiere que los usuarios con la invitacion cancelada no tengan marcado ni admin ni manager p̶o̶r̶ ̶m̶o̶t̶i̶v̶o̶s̶ ̶q̶u̶e̶ ̶n̶i̶ ̶s̶e̶ ̶n̶i̶ ̶q̶u̶i̶e̶r̶o̶ ̶p̶r̶e̶g̶u̶n̶t̶a̶r̶ ̶a̶ ̶e̶s̶t̶a̶s̶ ̶a̶l̶t̶u̶r̶a̶s̶
      res.data.forEach(user => {
        if (user.inviteStatus === INVITATION_STATUS.rejected) {
          user.isAdmin = false;
          user.isManager = false;
        }
      });
      this.dataSource.data = res.data;
      this.totalElements = res.total;
    });
  }

  createApiKey(user: User) {

    const data: GenericModalData = this.helper.translateModal({
      title: 'profile.apikeyModal.expirationTitle',
      content: 'profile.apikeyModal.expirationContent',
      template: this.apiKeyTemplate,
      buttons: [{ label: 'buttons.back', value: false, type: 'link' }, { label: 'profile.apikeyModal.expirationButton', value: true, type: 'button' }],
    });

    this.dialog.open(GenericModalComponent, { ...MODAL_WIDTH, data, disableClose: true }).afterClosed().subscribe((res) => {
      if (!res || !user) return;

      this.api.createApiKey(user.id, { days: this.apiKeyControl.value }).subscribe(res => {
        const data: GenericModalData = this.helper.translateModal({
          title: 'profile.apikeyModal.generationTitle',
          content: 'profile.apikeyModal.generationContent',
          buttons: [{ label: 'buttons.accept', value: true, type: 'button' }],
        }, { apikeyCode: res.value });
        this.dialog.open(GenericModalComponent, { ...MODAL_WIDTH, data, disableClose: true });
        user.apiKey!.expiresAt = res.expiresAt;
      });
    });
  }

  deleteApiKey(user: User) {
    this.api.deleteApiKey(user.id).subscribe(() => {
      this.api.log('toast.deleted');
      delete user.apiKey!.expiresAt;
    });
  }

  createUserInvitation(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, CustomValidators.validateEmail]],
      name: [null, [Validators.required]],
      locale: [DEFAULT.LOCALE, [Validators.required]],
      isAdmin: [false, [Validators.required]],
      isManager: [false, [Validators.required]],
    });
  }

  createManagerInfoForm(organization: OrganizationExtended): FormGroup {
    return this.fb.group({
      addCreatorAsManager: [organization.settings.addCreatorAsManager, [Validators.required]],
      sendInactiveRecordNotifications: [organization.settings.sendInactiveRecordNotifications, [Validators.required]],
      inactiveRecordNotificationWeekday: [organization.settings.inactiveRecordNotificationWeekday || 1, [Validators.required]],
      inactiveRecordNotificationTime: [organization.settings.inactiveRecordNotificationTime || this.hours[0], [Validators.required]],
      inactiveRecordNotificationTimezone: [organization.settings.inactiveRecordNotificationTimezone === 'UTC' ? this.timezone : organization.settings.inactiveRecordNotificationTimezone, [Validators.required]],
    });
  }

  saveManagerInfo() {

    const data = {
      settings: {
        ...this.managerInfo.getRawValue(),
        inactiveRecordNotificationWeekday: parseInt(this.managerInfo.get('inactiveRecordNotificationWeekday')?.value),
        inactiveRecordNotificationTime: this.managerInfo.get('inactiveRecordNotificationTime')?.value,
        inactiveRecordNotificationTimezone: this.managerInfo.get('inactiveRecordNotificationTimezone')?.value,
      },
    };

    this.api.editOrganizationData(data, this.organization.id).subscribe((res) => {
      this.api.log('toast.saved');
      this.organization = res;
      this.user.organization = res;
      this.cache.setUser(this.user);
    });

  }

  resendInvitation(id: string) {
    this.api.resendInvitation(id).subscribe(() => this.api.log('toast.resendOk', true));
  }

  cancelInvitation(id: string) {
    this.api.cancelInvitation(id).subscribe(() => this.getMemberInvitations(this.query));
  }

  calcFormVisibility() {
    this.isFormVisible = !!this.inviteMemberToOrganization.getRawValue().users.length;
  }

  async sendInvitations() {

    if (this.inviteMemberToOrganization.invalid) {
      this.inviteMemberToOrganization.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    this.inviteMemberToOrganization.markAllAsTouched();
    const { welcomeMessage, users } = this.inviteMemberToOrganization.getRawValue();

    try {
      for (let i = users.length - 1; i >= 0; i--) {
        const user = users[i];
        const memberInvitation = { organizationId: this.organization.id, user, welcomeMessage };
        memberInvitation.user.email = memberInvitation.user.email.trim();
        await firstValueFrom(this.api.inviteMemberToOrganization(memberInvitation));
        // aqui borramos del apartado de invitaciones todos los usuarios cuyas llamadas han dado 200
        this.deleteFormElement(this.inviteMemberToOrganization.get('users'), i);
      }
    } catch (e: any) {
      if (e.code === 'OrganizationProductLimit') this.api.logWarn('toast.OrganizationProductLimit', true, { products: this.limit?.i18n.products });
    }
    this.calcFormVisibility();
    this.getMemberInvitations(this.query);

    // Actualizo el consumo de invitaciones
    this.api.getLastUsage(this.organization.id).subscribe(usage => {
      this.limit = this.helper.checkLimits(usage, this.user.organization!.subscription, 'members');
    });
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getMemberInvitations(this.query);
  }

  showForm() {
    // añado un user invitation
    this.addFormElement(this.inviteMemberToOrganization.get('users'), this.builder(this.createUserInvitation));
    this.calcFormVisibility();
    this.changeDetectorRef.detectChanges();
    HelperService.scrollTo(this.bottom);
  }

  setUserAsAdminOrManager(event: MatCheckboxChange, element: InvitedMembers, column: string) {
    const obs = element.user ? this.api.updateManagerOrAdminStatus(this.organization.id, element.user.id, column, event.checked) : this.api.updateInviteManagerOrAdminStatus(element.invite.id, column, event.checked);

    obs.subscribe({ next: noop, error: () => event.source.toggle() });
  }

  calculateDisabled(element: InvitedMembers, column: 'isManager' | 'isAdmin'): boolean {
    if (element.inviteStatus === INVITATION_STATUS.rejected) return true;

    if (column === 'isAdmin') return element.user?.id === this.user.id;
    else return false;
  }
}
