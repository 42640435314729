<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div mat-dialog-title class="overline-o1">
    {{'chats.findRecordTitle' | translate}}
  </div>
  <hr class="divider">
  <div class="py-5 mx-8">
    <mat-dialog-content>
      <mat-form-field class="w-1/2">
        <input type="text" [placeholder]="'chats.selectRecord' | translate" matInput [matAutocomplete]="auto" #input  data-test="findRecordSelect">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='checkRecordParticipants($event.option.value)' class="d-flex flex-column radio-buttons">
          <mat-option *ngFor="let record of records" [value]="record.id">
            <span class="mr-1">{{Helper.getShortId(record.id)}}</span>
            <span *ngIf="record.alias">| {{record.alias}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <p *ngIf="!isRecordMember" class="text-danger caption-c2 mt-1 w-full"> {{'chats.notARecordMember' | translate}}</p>
    </mat-dialog-content>
  </div>
  <hr class="divider mr-8">

  <mat-dialog-actions>
    <div class="flex w-full justify-end">
      <button *ngIf="isRecordMember" (click)="close()" [disabled]="!selectedRecordId" routerLink="{{'/record-detail/' + selectedRecordId + '/messages/new' }}" [type]="'submit'" mat-stroked-button color="primary" class="mat-stroked-button" data-test="newChatReady">
        {{'chats.ready' | translate}}
      </button>
      <app-button-secondary *ngIf="!isRecordMember" [disabled]="!selectedRecordId" (click)="close()" routerLink="{{'/record-detail/' + selectedRecordId + '/canvas'}}" [type]="'submit'">
        {{'chats.addYourself' | translate}}
      </app-button-secondary>
    </div>
  </mat-dialog-actions>
</section>
