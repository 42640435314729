import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stepper[step][items]',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() step = 0;
  @Input() items: string[] = [];

  constructor() {
  }
}
