import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Template } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { EventService } from '../../../core/services/event.service';

@Component({
  selector: 'app-template-selection-modal',
  templateUrl: './template-selection-modal.component.html',
  styleUrls: ['./template-selection-modal.component.scss'],
})
export class TemplateSelectionModalComponent implements OnInit {

  basicTemplates: Template[] = [];
  customizedTemplates: Template[] = [];

  constructor(private api: ApiService, private router: Router, private dialog: MatDialog, public eventService: EventService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.api.getRecordTemplates().subscribe(templates => {
      for (const template of templates) {
        template.organization ? this.customizedTemplates.push(template) : this.basicTemplates.push(template);
      }
    });
  }

  createRecordWithTemplate(templateId: string) {
    this.eventService.loadingOn(this.api.createRecordWithTemplate(templateId)).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.router.navigate(['record-detail/' + res.data.id]);
      },
      error: () => {
        const product = 'records'; // Para evitar que lo coja el i18n del front (esta ya metido en el de common)
        this.api.logWarn('toast.OrganizationProductLimit', true, { products: this.translate.instant('products.' + product) });
      },
    });
  }

  deleteTemplate(templateId: string) {
    this.api.deleteTemplate(templateId).subscribe(() => {
      this.api.log('templates.deleteSuccess');
      this.customizedTemplates = this.customizedTemplates.filter(template => template.id !== templateId);
    });
  }

}
