<div class="form-container__form-field flex flex-col">

  <mat-label class="label mb-1 form-container__form-field__label" [ngClass]="{'text-danger mat-form-field-invalid' : _control.invalid && _control.touched, 'text-disabled' : _control.disabled}">{{ label || '' }}</mat-label>

  <mat-form-field *ngIf="type === 'text'" class="form-container__form-field__input">
    <input autocomplete="off" matInput [formControl]="_control" placeholder="{{placeholder}}">
  </mat-form-field>

  <mat-form-field *ngIf="type === 'password'">
    <input [formControl]="_control" [type]="!hide ? 'password' : 'text'" placeholder="*******" autocomplete="password" matInput>
    <mat-icon [svgIcon]="hide ? 'eye-off' : 'eye'" (click)="hide = !hide" matSuffix class="mr-2 cursor-pointer"></mat-icon>
  </mat-form-field>

  <mat-form-field *ngIf="type === 'number'">
    <input autocomplete="off" matInput numeric [decimals]="decimals" [isNegativeAllowed]="isNegativeAllowed" [formControl]="_control" placeholder="{{placeholder || (decimals ? '0.00' : '0')}}">
    <span matSuffix [ngClass]="{'text-disabled' : _control.disabled}"><ng-content></ng-content></span>
  </mat-form-field>

  <mat-form-field *ngIf="type === 'time'">
    <input autocomplete="off" matInput type="time" [formControl]="_control" placeholder="{{placeholder}}">
  </mat-form-field>

  <mat-form-field *ngIf="type === 'textarea'">
    <textarea matInput placeholder="{{placeholder}}" cdkTextareaAutosize cdkAutosizeMinRows="4" [formControl]="_control"></textarea>
  </mat-form-field>

  <app-control-error [control]="_control"></app-control-error>

  <small *ngIf="hint" [innerHTML]="hint | newlineToBr" class="mt-1 caption-c2 secondary-text mb-3"></small>
</div>
