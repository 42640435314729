import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-with-unit[control][type]',
  templateUrl: './input-with-unit.component.html',
  styleUrls: ['./input-with-unit.component.scss'],
})
export class InputWithUnitComponent {
  @Input() type!: 'currency' | 'weight' | 'height' | 'width' | 'depth' | 'volume' | 'temperature' | 'distance';
  @Input() placeholder: string = '0.00';
  @Input() label: string = '';
  @Input() suffixControl: FormGroup | undefined;

  @Input() set control(value: AbstractControl | null) {
    this._form = value as FormGroup;
  }

  _form!: FormGroup;
}
