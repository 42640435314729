import { Component, Input } from '@angular/core';

import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-chip[data][color]',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() iconRight?: string;
  @Input() iconLeft?: string;
  @Input() copyable? = false;
  @Input() color!: 'indigo' | 'primary' | 'warn' | 'grey' | 'red' | 'yellow' | 'green' | 'error' | 'grey-fill' | 'white';
  @Input() size: 'xs' | 'small' | 'medium' = 'medium';
  @Input() data!: string;
  @Input() additionalClasses!: string;
  @Input() truncate?: boolean;

  constructor(private api: ApiService) {
  }

  copyText() {
    navigator.clipboard.writeText(this.data).then(() => {
      this.api.log('chip.copied');
    });
  }
}
