import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
})
export class Error404Component implements OnInit {
  title: string = 'notFound';
  message: string = 'notExist';
  return: boolean = true;
  constructor(private ar: ActivatedRoute) {}

  ngOnInit(): void {
    this.ar.params.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.status === 'cancelled') {
        this.title = 'invitationCancelled';
        this.message = 'newInvitation';
        this.return = false;
      }
    });
  }
}
