<div *ngIf="form" class="mt-4">
  <div class="flex space-x-4">
    <app-input [type]="'text'" [control]="form.get('name')" [label]="('organizationData.name' | translate) + (conf.requiredFields?.includes('name') ? '*' : '')" [placeholder]="'organizationData.namePlaceholder' | translate" class="w-1/2" data-test="orgName"></app-input>
    <app-input [type]="'text'" [control]="form.get('legalId')" [label]="('organizationData.legalId' | translate) + (conf.requiredFields?.includes('legalId')? '*' : '')" [placeholder]="'organizationData.legalIdPlaceholder' | translate" class="w-1/2" data-test="orgLegalId"></app-input>
  </div>

  <div class="flex items-center justify-between">
    <app-input [control]="form.get('alias')" (change)="aliasChangeEvent.emit()" [label]="'organizationData.alias' | translate" [placeholder]="'organizationData.aliasPlaceholder' | translate" [ngClass]="showFavoritesButton ? 'w-3/4' : 'w-full'" class="mr-2" data-test="orgAlias"></app-input>

    <div *ngIf="showFavoritesButton && !isGuest" class="w-1/4 flex justify-end">
      <app-button-link *ngIf="!form.get('favorite')?.value" (click)="form.get('favorite')?.setValue(true)" text="{{'organizationData.saveFavorite' | translate}}" [disabled]="form.disabled" [style]="'primary'" iconLeft="heart"></app-button-link>
      <app-button-link *ngIf="form.get('favorite')?.value" (click)="form.get('favorite')?.setValue(false)" text="{{'organizationData.savedFavorite' | translate}}" [style]="'primary'" [disabled]="form.disabled" iconLeft="heart-filled"></app-button-link>
    </div>
  </div>
  <div class="flex space-x-4">
    <app-input [type]="'text'" [control]="form.get('email')" [label]="('organizationData.email' | translate) + (conf.requiredFields?.includes('email')? '*' : '')" [placeholder]="'organizationData.emailPlaceholder' | translate" class="w-1/2" data-test="orgEmail"></app-input>
    <app-input [type]="'text'" [control]="form.get('phone')" [placeholder]="'organizationData.phonePlaceholder' | translate" [label]="('organizationData.phone' | translate) + (conf.requiredFields?.includes('phone')? '*' : '')" class="w-1/2" data-test="orgPhone"></app-input>
  </div>

  <app-address-data [form]="form.get('address')"></app-address-data>

  <app-custom-fields [conf]="{ form: form, onSubmit: conf.onSubmit, data: conf.data.customFields, permissions: conf.permissions }" [type]="'organization'"></app-custom-fields>

  <hr class="divider my-8 -ml-8">

  <div>
    <div class="flex space-x-4">
      <app-input [type]="'text'" [control]="form.get('contactName')" [placeholder]="'organizationData.contactNamePlaceholder' | translate" [label]="'organizationData.contactName' | translate" class="w-1/2" data-test="contactName"></app-input>
      <app-input [type]="'text'" [control]="form.get('contactEmail')" [placeholder]="'organizationData.contactEmailPlaceholder' | translate" [label]="'organizationData.contactEmail' | translate" class="w-1/2" data-test="contactEmail"></app-input>
    </div>
    <div class="flex pr-2">
      <app-input [type]="'text'" [control]="form.get('contactPhone')" [placeholder]="'organizationData.phonePlaceholder' | translate" [label]="'organizationData.phone' | translate" class="w-1/2" data-test="contactPhone"></app-input>
    </div>
  </div>

</div>
