import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-icon-simple-card[title][icon]',
  templateUrl: './icon-simple-card.component.html',
  styleUrls: ['./icon-simple-card.component.scss'],
})
export class IconSimpleCardComponent {

  @Input() icon!: string;
  @Input() title!: string;
  @Input() description?: string;
  @Input() style: 'yellow' | 'primary' = 'yellow';


  constructor() {
  }
}
