<div class="overflow-y-auto h-full">
  <section *ngIf="user && chat" class="m-3 shadow-modal" data-test="chat">
    <div *ngIf="messages.length && chat.id" class="m-4">
      <div class="flex items-center justify-between">
        <h1 class="mt-4" data-test="chatTitle">{{chat.title}}</h1>
        <p (click)="(isChatCreator || !chat.id) && openCollaboratorsList()" class="link" [ngClass]="(isChatCreator || !chat.id) ? 'cursor-pointer' : 'block-cursor'">
          {{chat.participants.length}}
          <span *ngIf="chat.participants.length > 1">{{'chats.notifiedPeople' | translate}}</span>
          <span *ngIf="chat.participants.length === 1">{{'chats.notifiedPerson' | translate}}</span>
        </p>
      </div>

      <div *ngFor="let message of messages; let i = index">
        <div *ngIf="chat.read === i && chat.unread !== 0" class="flex unread">
          <hr class="w-full">
          <span class="caption-c1 ml-4">{{'chats.new' | translate}}</span>
        </div>

        <div class="mt-4 flex justify-between">
          <div class="flex items-center">
            <app-avatar-circle [type]="'initials'" [data]="message.senderUser.name !== '' ? message.senderUser.name : message.senderUser.email" class="mr-4"></app-avatar-circle>
            <span class="overline-o2 secondary-text" data-test="chatUser">{{ 'chats.of' | translate}} {{message.senderUser.name !== '' ? message.senderUser.name : message.senderUser.email}} - {{(message.sentAt | date: 'short')}}</span>
          </div>
          <div *ngIf="isParticipant">
          <span (click)="markAsUnread(chat.id, i)" class="link flex items-center">
            <mat-icon [svgIcon]="'messages'" color="primary" class="mx-2"></mat-icon>
            {{'chats.unread' | translate}}
          </span>
          </div>
        </div>
        <p class="body-b1" class="break-words whitespace-pre-wrap" [ngClass]="i === 0 ? 'ml-0 mb-4' : 'ml-12'" data-test="message">{{message.content}}</p>
      </div>
    </div>

    <form class="p-6">
      <app-input *ngIf="!chat.id" [type]="'text'" [control]="newMessageForm.get('title')" [label]="'chats.newMessageTitle' | translate" [placeholder]="'chats.newMessagePlaceholder' | translate" class="w-full" data-test="addTitleChat"></app-input>

      <div class="flex">
        <app-avatar-circle *ngIf="isParticipant && chat.id" [type]="'initials'" [data]="user.name || user.email" class="mr-3"></app-avatar-circle>
        <app-input *ngIf="isParticipant || messages.length === 0" [type]="'textarea'" [control]="newMessageForm.get('message')" [placeholder]="'chats.newMessageTextareaPlaceholder' | translate" class="w-full" data-test="addMessageChat"></app-input>
      </div>
      <app-button-primary *ngIf="!isParticipant && chat.id" (click)="selfInvite()" class="mb-6 self-start">{{'chats.autoinvite' | translate}}</app-button-primary>

      <hr *ngIf="isParticipant || chat.id" class="divider my-4 w-1/3 ml-0">

      <app-button-link *ngIf="isParticipant || !chat.id" (click)="openCollaboratorsList()" text="{{(chat.participants.length < 1 ? 'chats.noNotifications' : 'chats.participantList') | translate }}" [style]="'primary'" iconLeft="edit-2" data-test="inviteParticipant"></app-button-link>

      <div class="flex mb-4">
        <app-avatar-circle *ngFor="let participant of chat?.participants" [type]="'initials'" [data]="participant.name !== '' ? participant.name : participant.email" [disabled]="messages.length !== 0 && !isParticipant" class="mr-2"></app-avatar-circle>
      </div>

      <div (click)="muteChat()" *ngIf="isParticipant || !chat" class="flex items-center self-start">
        <p class="body-b2">{{(chat.muted ? 'chats.notificationsOff' : 'chats.notificationsOn') | translate}}</p>
        <mat-icon [svgIcon]="chat.muted ? 'bell' : 'bell-off'" color="primary" class="mx-2"></mat-icon>
        <a class="link">{{(chat.muted ? 'chats.activate' : 'chats.silence') | translate}}</a>
      </div>

      <hr class="divider mb-4 w-1/3 ml-0">

      <div class="inline-flex flex-col pb-2 mb-4">
        <app-chip *ngIf="isParticipant && !isGuest" [color]="'warn'" [iconLeft]="'info'" [data]="'chats.everybodyCanReadThis' | translate" [additionalClasses]="'label'" class="my-5"></app-chip>
        <app-button-primary *ngIf="!chat.id" (click)="sendNewMessage()" class="self-start" data-test="saveChat">{{'buttons.save' | translate}}</app-button-primary>
        <app-button-primary *ngIf="chat.id && isParticipant" (click)="updateChat()" class="self-start" data-test="updateChat">{{'buttons.save' | translate}}</app-button-primary>
      </div>
    </form>
  </section>
</div>
