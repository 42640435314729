import { Component, Input, OnInit } from '@angular/core';

import { HelperService } from '../../../front/src/app/core/services/helper.service';
import { Organization, User } from '../../models/main';

@Component({
  selector: 'app-image-avatar',
  templateUrl: './image-avatar.component.html',
  styleUrls: ['./image-avatar.component.scss'],
})
export class ImageAvatarComponent implements OnInit {
  Helper = HelperService;
  text: string = '';
  logo?: string;
  @Input() disabled!: boolean;
  @Input() editMode = false;
  @Input() user?: User | null;
  @Input() size?: 'xs' | 's' | 'm' | 'l' = 'xs';
  @Input() organization?: Organization;

  ngOnInit() {
    if (this.user) {
      this.text = (this.user.organization?.alias || this.user.organization?.name || this.user.email).split(' ').slice(0, 2).join(' ');
      this.logo = this.user.organization?.logo;
    }
    if (this.organization) {
      this.text = (this.organization?.alias || this.organization?.name || this.organization?.email).split(' ').slice(0, 2).join(' ');
      this.logo = this.organization?.logo;
    }
  }
}
