import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RecordService {

  private forms: { formResource?: FormGroup, formGoods?: FormGroup } = {};
  private subs: Subscription[] = [];

  constructor() {
  }

  // Sincroniza tipo de transporte y notas de las pestañas recurso y mercancia
  syncTransportForms(formResource?: FormGroup, formGoods?: FormGroup) {
    if (formResource) this.forms.formResource = formResource;
    if (formGoods) this.forms.formGoods = formGoods;
    if (this.forms.formResource && this.forms.formGoods) {
      const subs = this.forms.formResource!.get('type')!.valueChanges.subscribe((v: any) => {
        if (this.forms.formGoods!.get('type')!.value !== v) this.forms.formGoods!.get('type')!.setValue(v, { emitEvent: true });
      });
      const subs2 = this.forms.formGoods!.get('type')!.valueChanges.subscribe((v: any) => {
        if (this.forms.formResource!.get('type')!.value !== v) this.forms.formResource!.get('type')!.setValue(v, { emitEvent: true });
      });
      this.subs = [subs, subs2];
    }
  }

  desyncForms() {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
    this.forms = {};
  }
}
