const data: { value: string, text: string, disabled: boolean }[] = [
  { value: 'CFR', text: 'Cost and Freight', disabled: false },
  { value: 'CIF', text: 'Cost, Insurance & Freight', disabled: false },
  { value: 'CIP', text: 'Carriage and Insurance Paid to', disabled: false },
  { value: 'CPT', text: 'Carriage Paid To', disabled: false },
  { value: 'DAP', text: 'Delivered At Place', disabled: false },
  { value: 'DDP', text: 'Delivered Duty Paid', disabled: false },
  { value: 'EXW', text: 'Ex Works', disabled: false },
  { value: 'FAS', text: 'Free Alongside Ship', disabled: false },
  { value: 'FCA', text: 'Free Carrier', disabled: false },
  { value: 'FOB', text: 'Free on Board', disabled: false },
  { value: 'DPU', text: 'Delivered at Terminal', disabled: false },
  { value: 'DAF', text: 'Delivered at Frontier', disabled: true },
  { value: 'DAT', text: 'Delivered At Terminal', disabled: true },
  { value: 'DDU', text: 'Delivered Duty Unpaid', disabled: true },
  { value: 'DEQ', text: 'Delivered Ex Quay', disabled: true },
  { value: 'DES', text: 'Delivered Ex Ship', disabled: true },
  { value: 'FOA', text: 'FOB Airport', disabled: true },
  { value: 'FRD', text: 'Formal request for delivery', disabled: true },
  { value: 'FRF', text: 'Delivered at Frontier', disabled: true },
  { value: 'FRT', text: 'Delivered at Place Unloaded', disabled: true },
];
export default data;
