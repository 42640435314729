<section class="my-6 w-100">
  <h3 class="overline-o2 secondary-text mb-5 ml-6" data-test="organizationInvitationsTitle">{{'profile.organization.invitations' | translate}}</h3>
  <h2 class="title-t1 mb-5 ml-6">{{'profile.organization.members' | translate}}</h2>

  <p class="body-b2 mb-5 ml-6">{{'profile.organization.add' | translate}}</p>

  <!------------------->

  <app-table-no-data [dataSource]="dataSource" [subscription]="subscription" [message]="('profile.organization.noData' | translate)" class="mx-6">
    <div class="overflow-auto whitespace-nowrap shadow-standard border-tb mx-6">
      <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="w-full">

        <!-- Name Column -->
        <ng-container matColumnDef="name" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef>{{'profile.user.name' | translate}}</th>
          <td mat-cell *matCellDef="let element" data-test="tableMemberName"> {{element.name}} </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{'profile.user.email' | translate}}</th>
          <td mat-cell *matCellDef="let element" data-test="tableMemberEmail"> {{element.email}} </td>
        </ng-container>

        <!-- sentAt Column -->
        <ng-container matColumnDef="invitedAt">
          <th mat-header-cell *matHeaderCellDef>{{'profile.user.invited' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.invitedAt  | date: 'short' }} </td>
        </ng-container>

        <!-- registeredAt Column -->
        <ng-container matColumnDef="registeredAt">
          <th mat-header-cell *matHeaderCellDef>{{'profile.user.registered' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.registeredAt ? (element.registeredAt | date: 'short') : '-'}} </td>
        </ng-container>

        <!-- isAdmin Column -->
        <ng-container matColumnDef="isAdmin">
          <th mat-header-cell *matHeaderCellDef class="center">{{'profile.user.admin' | translate}}</th>
          <td mat-cell *matCellDef="let element" data-test="tableMemberIsAdmin">
            <div class="center">
              <mat-checkbox [checked]="element.isAdmin" (change)="setUserAsAdminOrManager($event, element, 'isAdmin')" [disabled]="calculateDisabled(element, 'isAdmin')"></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <!-- isManager Column -->
        <ng-container matColumnDef="isManager">
          <th mat-header-cell *matHeaderCellDef class="center">{{'profile.user.manager' | translate}}</th>
          <td mat-cell *matCellDef="let element" data-test="tableMemberIsManager">
            <div class="center">
              <mat-checkbox [checked]="element.isManager" (change)="setUserAsAdminOrManager($event, element,'isManager')" [disabled]="calculateDisabled(element, 'isManager')"></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <!-- API KEY Column -->
        <ng-container matColumnDef="apikey">
          <th mat-header-cell *matHeaderCellDef class="center">{{'profile.user.apikey' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="center">
              <app-button-link *ngIf="element.user?.apiKey?.expiresAt" (click)="deleteApiKey(element.user)" text="{{'profile.user.apikeyExpiresAt' | translate}} {{element.user.apiKey.expiresAt | date : 'shortDate'}}" [style]="'primary'" iconRight="close-circle" class="whitespace-nowrap"></app-button-link>
              <app-button-link *ngIf="element.user && !element.user.apiKey?.expiresAt" (click)="createApiKey(element.user)" text="{{'profile.user.apikeyGenerate' | translate }}" [style]="'primary'" class="whitespace-nowrap"></app-button-link>
            </div>
          </td>
        </ng-container>

        <!-- inviteStatus Column -->
        <ng-container matColumnDef="inviteStatus">
          <th mat-header-cell *matHeaderCellDef class="w-48"></th>
          <td mat-cell *matCellDef="let element" class="h-12" data-test="tableMemberStatus">
            <div class="flex items-center justify-start link">
              <div [matTooltip]="'profile.invite.tooltip' | translate" [matTooltipDisabled]="element.inviteStatus !== INVITATION_STATUS.rejected" class="cursor-default">
                <mat-icon [class]="element.inviteStatus" [svgIcon]="icons[element.inviteStatus]"></mat-icon>
                <span class="ml-2" [class]="element.inviteStatus">{{('profile.invite.' + element.inviteStatus) | translate}}</span>
                <app-button-link *ngIf="element.inviteStatus === INVITATION_STATUS.pending" iconLeft="more-vertical" [matMenuTriggerFor]="menu" [style]="'primary'"></app-button-link>
                <mat-menu #menu="matMenu" class="p-2">
                  <app-button-link iconLeft="redo" (click)="resendInvitation(element.invite.id)" text="{{'profile.resendInvite' | translate }}" [style]="'primary'" optionalClasses="p-2"></app-button-link>
                  <app-button-link iconLeft="trash" (click)="cancelInvitation(element.invite.id)" text="{{'profile.invite.cancel' | translate }}" [style]="'warn'" optionalClasses="p-2"></app-button-link>
                </mat-menu>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


    </div>
    <div [ngClass]="limit ? 'justify-between' : 'justify-end'" class="flex shadow-dark border-paginator mx-6">
      <div *ngIf="limit" class="flex items-center mx-6">
        <app-usage-progress-bar [type]="'members'" [limit]="limit"></app-usage-progress-bar>
      </div>
      <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource.data.length}"></mat-paginator>
    </div>
  </app-table-no-data>

  <div class="ml-8 w-11/12 flex justify-between items-center py-4" [ngClass]="{'justify-end' : isFormVisible, 'justify-between' : !isFormVisible }">
    <!--    Con la clase text-disabled el matTooltip no recibe el evento hover-->
    <app-button-link *ngIf="!isFormVisible" iconLeft="plus-circle" (click)="showForm()" text="{{'profile.organization.addMembers' | translate }}" [disabled]="limit?.step === 'in-limit'" [style]="'primary'" [matTooltip]="limit?.text ||''" data-test="addMember"></app-button-link>
  </div>

  <!--INVITACIONES-->
  <form *ngIf="inviteMemberToOrganization && isFormVisible" [formGroup]="inviteMemberToOrganization">

    <h3 *ngIf="(inviteMemberToOrganization.get('users') | cast).length !== 0" class="ml-8">
      {{'profile.invite.title' | translate}}
    </h3>

    <div class="ml-8 mb-8 w-6/12">
      <app-input *ngIf="(inviteMemberToOrganization.get('users') | cast).length !== 0" [type]="'textarea'" [control]="inviteMemberToOrganization.get('welcomeMessage')" [placeholder]="'profile.user.welcomeMessage' | translate" data-test="welcomeMessage"></app-input>
    </div>

    <div *ngFor="let userInvitation of $any(inviteMemberToOrganization.get('users')).controls | cast; let i = index;" class="flex flex-col items-start invite-form mb-4">

      <div class="w-9/12 flex flex-row justify-between ml-8 items-center">
        <app-input [type]="'text'" [control]="userInvitation.get('name')" [label]="'profile.user.name' | translate" [placeholder]="'profile.user.name' | translate" class="w-1/4 mr-4" data-test="memberName"></app-input>
        <app-input [type]="'text'" [control]="userInvitation.get('email')" [label]="'profile.user.email' | translate" [placeholder]="'profile.user.email' | translate" class="w-1/4 mr-4" data-test="memberEmail"></app-input>
        <app-select [control]="userInvitation.get('locale')" [type]="'locale'" [label]="'profile.user.lang' | translate" class="w-32" data-test="memberLang"></app-select>

        <mat-checkbox [formControl]="userInvitation.get('isAdmin') | cast" data-test="memberIsAdmin">
          <mat-label class="mr-1 body-b2">{{'profile.user.admin' | translate}}</mat-label>
        </mat-checkbox>

        <mat-checkbox [formControl]="userInvitation.get('isManager') | cast" data-test="memberIsManager">
          <mat-label class="mr-1 body-b2">{{'profile.user.manager' | translate}}</mat-label>
        </mat-checkbox>
      </div>

      <app-button-link iconLeft="trash" (click)="deleteFormElement(inviteMemberToOrganization.get('users'), i); calcFormVisibility()" text="{{'profile.invite.deleteInvite' | translate }}" [style]="'warn'" data-test="deleteInvitation" class="ml-8"></app-button-link>
    </div>

    <app-button-link iconLeft="plus-circle" (click)="addFormElement(inviteMemberToOrganization.get('users'), builder(createUserInvitation))" text="{{'profile.invite.newInvite' | translate }}" [style]="'primary'" data-test="addAnotherInvitation" optionalClasses="ml-8 mb-4"></app-button-link>

    <app-button-secondary (click)="sendInvitations()" class="pb-16 ml-8" data-test="inviteButton">{{'buttons.invite' | translate}}</app-button-secondary>

  </form>
  <!-- FIN INVITACIONES-->

  <hr class="divider ml-0 w-1/2">

  <!--MANAGER INFO-->
  <div>
    <h3 class="ml-8" data-test="organizationManagersTitle">{{'profile.invite.managers' | translate}}</h3>
    <form [formGroup]="managerInfo" *ngIf="managerInfo" class="flex flex-col items-start ml-8">
      <mat-checkbox [formControl]="managerInfo.get('addCreatorAsManager') | cast" class="mb-4" data-test=addCreatorAsManager>
        <mat-label class="mr-1 body-b2">{{'profile.user.addAsManagerToRecord' | translate}}</mat-label>
      </mat-checkbox>
      <mat-checkbox [formControl]="managerInfo.get('sendInactiveRecordNotifications') | cast" class="flex items-center mb-4" data-test=sendInactiveRecordNotifications>
        <mat-label class="mr-1 body-b2">{{'profile.user.notifyUser' | translate}}</mat-label>
        <mat-icon svgIcon="info" class="ml-1" [matTooltip]="'profile.user.inactiveRecordTooltip' | translate" position="'right'" color="disabled"></mat-icon>
      </mat-checkbox>

      <div class="flex items-baseline">
        <span class="mr-2 body-b2">{{'profile.user.notificationDate' | translate }}</span>
        <mat-form-field class="caption-c1 mr-2">
          <mat-select [formControl]="managerInfo.get('inactiveRecordNotificationWeekday') | cast" data-test="managersWeekdayInput">
            <mat-option *ngFor="let day of NOTIFICATION_MANAGER_DAYS" [value]="day">
              {{('profile.user.notifications.days.' + day) | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span class="mr-2">{{'profile.user.at' | translate }}</span>
        <mat-form-field class="caption-c1 mr-2">
          <mat-select [formControl]="managerInfo.get('inactiveRecordNotificationTime') | cast" data-test="managersHourInput">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{ hour }}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-autocomplete-select [control]="managerInfo.get('inactiveRecordNotificationTimezone')" [type]="'timezone'" [placeholder]="'profile.user.timezonePlaceholder' | translate" [label]="null" data-test="managersTimezoneInput"></app-autocomplete-select>
      </div>
      <app-button-secondary (click)="saveManagerInfo()" [type]="'submit'" class="my-8" data-test=saveManagerInfo>{{'buttons.save' | translate}}</app-button-secondary>
    </form>
  </div>
</section>
<div #bottom></div>
<ng-template #apiKeyTemplate>
  <app-input [type]="'number'" [decimals]="0" [control]="apiKeyControl" [label]="'profile.apikeyModal.expirationDays' | translate" class="px-6"></app-input>
</ng-template>
