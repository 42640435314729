<div class="form-container__form-field flex flex-col w-full">
  <mat-form-field>
    <mat-select placeholder="{{placeholder}}" multiple [formControl]="_control">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
      <span *ngFor="let item of availableOptions" cdkDrag class="flex items-center justify-start px-4">
        <mat-icon cdkDragHandle [svgIcon]="'menu'" class="icon cursor-pointer"></mat-icon>
        <mat-option [value]="item">{{(i18nPath + item) | translate }}</mat-option>
      </span>
      </div>
    </mat-select>
  </mat-form-field>
</div>
