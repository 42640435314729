import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noop } from 'rxjs';

import { GenericModalComponent } from '../../../../../common/components/generic-modal/generic-modal.component';
import { DEFAULT, MODAL_WIDTH, TERMS_AND_CONDITIONS_HASH } from '../../../../../common/models/constants';
import { GenericModalData, NotificationCard, User } from '../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../common/services/api.service';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { CacheService } from '../../core/services/cache.service';
import { EnvService } from '../../core/services/env.service';
import { EventService } from '../../core/services/event.service';
import { HelperService } from '../../core/services/helper.service';

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  production = this.env.production;
  theme = this.cache.theme;
  organizationName = '';
  user!: User;
  isGuest: boolean = false;
  showNotifications: boolean = false;
  notifications: NotificationCard[] = [];
  newNotification: boolean = false;
  start: number = 0;
  query: CustomQuery = { filter: { channel: 'bell' }, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };

  constructor(public env: EnvService, private router: Router, private eventService: EventService, private dialog: MatDialog, private cache: CacheService, private api: ApiService, private helper: HelperService) {
  }

  ngOnInit() {
    this.cache.getUser().subscribe((user) => {
      this.isGuest = !user.organization;
      this.user = user;
      this.organizationName = user.organization?.alias || user.organization?.name || 'Usyncro';
      if (!this.user.legalTerms.length) this.openTermAndConditionsModal();
      const userFullyRegistered = !!this.user.emailValidatedAt;
      if (userFullyRegistered || this.isGuest) this.installServiceWorkerAndSendSubscription().then(noop);
      this.eventService.socketSubscribe('users', this.user.id);
    });
    this.eventService.notifications.pipe(untilDestroyed(this)).subscribe((notification: any) => {
      this.api.getNotificationDetail(notification.id).subscribe(notification => {
        this.notifications = [notification, ...this.notifications];
        this.newNotification = true;
      });
    });
    this.getNotifications();
  }

  getNotifications() {
    this.api.getNotifications(this.query).subscribe(res => {
      this.notifications = res;
      this.newNotification = res.some(notification => !notification.isRead);
    });
  }

  getNextNotifications() {
    this.query.pagination!.offset += 10;
    this.api.getNotifications(this.query).subscribe(res => {
      this.notifications = [...this.notifications, ...res];
      this.newNotification = this.notifications.some(notification => !notification.isRead);
    });
  }

  ngOnDestroy(): void {
    this.eventService.socketUnsubscribe('users', this.user.id);
  }

  changeThemeMode() {
    this.theme = this.theme === 'light' ? 'dark' : 'light';
    this.eventService.theme.next(this.theme);
  }

  exit(link: string) {
    MainEventService.login.next();
    this.router.navigate([link]);
  }

  // modal de términos y condiciones, se debe lanzar cuando se actualicen
  openTermAndConditionsModal() {
    const data: GenericModalData = this.helper.translateModal({
      title: 'termsConditions.title',
      content: 'termsConditions.message',
      buttons: [{ label: 'termsConditions.seeTermsAndConditions', value: false, type: 'link', icon: 'eye' }, { label: 'buttons.accept', value: true, type: 'button' }],
    });
    this.dialog.open(GenericModalComponent, { ...MODAL_WIDTH, data, disableClose: true }).afterClosed().subscribe(res => {
      if (res) {
        this.api.acceptTermAndConditions(TERMS_AND_CONDITIONS_HASH).subscribe(noop);
      } else {
        window.open('assets/documents/' + this.cache.lang + '/terms-and-conditions.pdf', '_blank');
        this.openTermAndConditionsModal(); // No es la solucion mas bonita pero permite tener la modal levantada hasta q el usuario acepte
      }
    });
  }

  async installServiceWorkerAndSendSubscription() {
    if (!('serviceWorker' in navigator)) return;
    let reg = await navigator.serviceWorker.getRegistration('assets/sw.js');
    if (!reg) reg = await navigator.serviceWorker.register('assets/sw.js');
    if (!this.env.production) await reg.update(); // update de SW

    const notificationPermission = await Notification.requestPermission();
    if (notificationPermission !== 'granted') return;

    let subscription = await reg.pushManager.getSubscription();
    if (!subscription) subscription = await reg.pushManager.subscribe({ applicationServerKey: this.env.webPushVAPIDPublicKey, userVisibleOnly: true });
    this.user.notifications.pushSubscription = subscription.toJSON();
    this.api.updateNotificationsConfiguration(this.user.id, this.user.notifications).subscribe(noop);
  }
}
