import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ['./button-secondary.component.scss'],
})
export class ButtonSecondaryComponent {

  @Input() disabled = false;
  @Input() type: 'button' | 'submit' = 'button';
}
