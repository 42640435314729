import { DecimalPipe } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LimitMessage } from '../../models/main';
import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-usage-progress-bar[type][limit]',
  templateUrl: './usage-progress-bar.component.html',
  styleUrls: ['./usage-progress-bar.component.scss'],
})
export class UsageProgressBarComponent {
  @Input() type!: 'storage' | 'members' | 'records';
  @Input() set limit(limit: LimitMessage) {
    this._limit = limit;
    this.update();
  }

  _limit!: LimitMessage;
  color!: string;
  text1 = '';
  text2 = '';

  constructor(private api: ApiService, private translate: TranslateService, private decimal: DecimalPipe, @Inject(LOCALE_ID) private locale: string) { }

  update() {
    const unit = this.type === 'storage' ? 'GB' : '';
    const type = this.translate.instant('products.' + this.type);
    this.text1 = this.translate.instant('usageProgressBar.text1', { progress: this._limit.progress, type });
    this.text2 = this.translate.instant('usageProgressBar.text2', { value: this.decimal.transform(this._limit.usage, '1.0-2', 'en'), included: this._limit.included, type, unit });

    if (this._limit.progress === 100) this.color = 'var(--error-500)';
    else if (this._limit.progress > 80) this.color = 'var(--warn-500)';
    else this.color = 'var(--primary-400)';
  }
}
