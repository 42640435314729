import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ConfigForm, Organization } from '../../models/main';
import { OrganizationDataFormComponent } from '../organization-data-form/organization-data-form.component';


@Component({
  selector: 'app-custom-actor',
  templateUrl: './custom-actor.component.html',
  styleUrls: ['./custom-actor.component.scss'],
})
export class CustomActorComponent implements OnInit, OnDestroy {

  @Input() conf!: ConfigForm<Organization>;
  @Output() aliasChangeEvent = new EventEmitter();
  form!: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.conf.form;
    OrganizationDataFormComponent.addControls(this.form, this.conf.data, this.fb, this.conf.permissions!);
  }

  ngOnDestroy(): void {
    OrganizationDataFormComponent.removeControls(this.form);
  }

}
