import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-cropper-image-modal',
  templateUrl: './cropper-image-modal.component.html',
  styleUrls: ['./cropper-image-modal.component.scss'],
})
export class CropperImageModalComponent {
  imageChangedEvent: any = '';
  croppedImage: Blob | null | undefined;
  file!: any;
  invalidForm: boolean = false;

  constructor(private dialogRef: MatDialogRef<CropperImageModalComponent>, private api: ApiService) {
  }

  closeModal(value: any) {
    this.dialogRef.close(value);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  fileBrowseHandler(event: any) {
    this.file = event.target.files[0];
    this.invalidForm = !this.file || !this.file.type.includes('image');
    if (this.invalidForm) this.api.logError('formErrors.invalidArchive');
    this.imageChangedEvent = event;
  }
}
