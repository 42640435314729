import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CacheService } from '../../front/src/app/core/services/cache.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cache: CacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: any = { 'cache-control': 'no-cache', 'pragma': 'no-cache' };
    const token: string = this.cache.getToken() || '';
    if (token) headers.Authorization = 'Bearer ' + token;

    const socketId: string = this.cache.socketId || '';
    if (token) headers['socketio-socket-id'] = socketId;

    return next.handle(req.clone({ setHeaders: headers }));
  }
}
