<section class="relative" data-test="favoriteModal">
  <div (click)="close()" class="dialog-container__close dialog-container__close--l cursor-pointer absolute">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div mat-dialog-title class="overflow-hidden truncate">
    <span *ngIf="!data.favoriteId">
      {{'favorites.title' | translate}}
    </span>
    <span *ngIf="data.favoriteId && organization">
      {{organization.alias ? organization.alias : organization.name}}
    </span>
  </div>

  <hr class="divider">
  <form *ngIf="form && organization" [formGroup]="form" (ngSubmit)="saveFavorite()">
    <mat-dialog-content>
      <div class="px-8 pt-4">
        <app-organization-data-form *ngIf="resourceSubtype !== ACTORS.Custom" [conf]="{ form: form, onSubmit: onSubmit, data: organization, permissions: permission }" data-test="favoriteForm"></app-organization-data-form>
        <app-custom-actor *ngIf="resourceSubtype === ACTORS.Custom" [conf]="{ form: form, onSubmit: onSubmit, data: organization, permissions: permission }"></app-custom-actor>
      </div>
    </mat-dialog-content>
    <hr class="divider">
    <mat-dialog-actions class="flex w-full justify-end space-x-4">
      <span *ngIf="data.favoriteId" class="link text-danger pt-2" (click)="deleteFavorite(data.favoriteId)">{{'favorites.delete' | translate}}</span>
      <app-button-secondary [type]="'submit'">{{'buttons.save' | translate}}</app-button-secondary>
    </mat-dialog-actions>
  </form>
</section>

