import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';

import { INFO_EMAIL, INFO_PHONE } from '../../../../../../common/models/constants';
import { ApiService } from '../../../../../../common/services/api.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email-modal.component.html',
  styleUrls: ['./validate-email-modal.component.scss'],
})
export class ValidateEmailModalComponent implements OnInit {
  seconds = 60; // Mismo valor que en el backend
  source = timer(1000, 1000);
  contactEmail: string = INFO_EMAIL;
  contactPhone: string = INFO_PHONE;

  constructor(private api: ApiService, @Inject(MAT_DIALOG_DATA) public data: { code: string, cb: () => void }) { }

  ngOnInit() {
    this.setTimer();
  }

  setTimer() {
    this.seconds = 60; // Mismo valor que en el backend
    const subscribe: Subscription = this.source.subscribe(() => {
      this.seconds--;
      if (this.seconds === 0) subscribe.unsubscribe();
    });
  }

  sendEmailValidation() {
    this.api.sendEmailValidation().subscribe(() => {
      this.setTimer();
    });
  }
}
