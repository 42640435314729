<section class="flex flex-col items-center justify-center p-6 relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>

  <app-image-avatar [organization]="organization" [size]="'m'" class="mr-4"></app-image-avatar>
  <app-chip [iconLeft]="'copy'" [color]="'primary'" [data]="organization.id" [additionalClasses]="'label'" [copyable]="true" class="my-5"></app-chip>

  <mat-expansion-panel hideToggle [expanded]="false" #expansion class="bg-transparent w-4/5">
    <mat-expansion-panel-header>
      <mat-panel-title class="flex w-full capitalize items-center justify-center m-0">
        <app-button-link text="{{(expansion.expanded ? ('digitalIdentity.close' | translate) : ('digitalIdentity.open' | translate)) + ' ' + ('digitalIdentity.expansion' | translate)}}" [style]="'primary'" iconRight="{{expansion.expanded ? 'chevron-up' : 'chevron-down'}}" class="capitalize"></app-button-link>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="organization.blockchain">
      <div *ngFor="let item of organization.blockchain | keyvalue" class="body-b2 my-2 flex items-center justify-between border-bottom w-full">
        <div class="w-1/3">
          <p class="label grey-700">{{ 'digitalIdentity.transaction' | translate }}</p>
          <p class="label primary-900">{{item.value.network}}</p>
        </div>
        <div class="w-2/3">
          <p class="label grey-700">{{ 'digitalIdentity.hash' | translate }}</p>
          <a [href]="item.value.transactionLink" [matTooltip]="item.value.transactionHash" target="_blank" class="my-3 block link truncate">{{item.value.transactionHash}}</a>
        </div>
      </div>
    </div>
    <div *ngIf="!organization.blockchain" class="body-b2">{{'digitalIdentity.noNetworks' | translate}}</div>
  </mat-expansion-panel>
</section>
