import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT, MODAL_WIDTH } from '../../../../../../common/models/constants';
import { EventTemplate, User } from '../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../common/services/api.service';
import { CacheService } from '../../../core/services/cache.service';
import { NewEventTypeComponent } from './new-event-type/new-event-type.component';

@UntilDestroy()
@Component({
  selector: 'app-organization-events',
  templateUrl: './organization-events.component.html',
  styleUrls: ['./organization-events.component.scss'],
})
export class OrganizationEventsComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<EventTemplate> = new MatTableDataSource<EventTemplate>([]);
  totalElements = 0;
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  displayedColumns = ['event', 'system', 'id', 'class', 'notification', 'active', 'action'];
  user!: User;
  query: CustomQuery = { filter: { search: '' }, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  subscription!: Subscription;

  @ViewChild('input') input!: ElementRef;

  constructor(private dialog: MatDialog, private api: ApiService, private cache: CacheService) {
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(250)).subscribe(() => {
      this.query.filter!.search = this.input.nativeElement.value;
      this.getEventList(this.query);
    });
  }

  ngOnInit(): void {
    this.cache.getUser().subscribe(user => {
      this.user = user;
      this.getEventList(this.query);
    });
  }

  getEventList(query: CustomQuery) {
    this.subscription = this.api.getEventTemplateList(this.user.organization!.id, query).subscribe(res => {
      this.dataSource.data = res.data;
      this.totalElements = res.total;
    });
  }

  openModal(eventId?: string) {
    this.dialog.open(NewEventTypeComponent, { ...MODAL_WIDTH, disableClose: true, data: { user: this.user, eventId: eventId } }).afterClosed().pipe(untilDestroyed(this)).subscribe(newEventTemplate => {
      if (newEventTemplate) this.getEventList(this.query);
    });
  }

  deleteEventType(eventId: string) {
    this.api.deleteEventTemplate(this.user.organization!.id, eventId).subscribe(() => {
      this.api.log('toast.deleted');
      this.getEventList(this.query);
    });
  }

  editEvents(eventId: string, field: 'isActive' | 'areNotifActive', event: { checked: boolean }) {
    const data = { [field]: event.checked };
    this.api.updateEventTemplate(this.user.organization!.id, eventId, data).subscribe(() => {
      this.api.log('toast.updated');
      this.getEventList(this.query);
    });
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getEventList(this.query);
  }
}
