<div class="h-full border-bottom" [ngClass]="{'img-background': rlRef.isActive}">
  <nav mat-tab-nav-bar class="mat-tab-link-container" [tabPanel]="tabPanel">
    <a mat-tab-link routerLinkActive="active-link" *ngIf="!isGuest && isAdmin" routerLink="organization" class="overline-o1">{{'profile.sections.organization' | translate}}</a>
    <a mat-tab-link routerLinkActive="active-link" *ngIf="!isGuest && isAdmin" routerLink="applications" class="overline-o1">{{'profile.sections.applications' | translate}}</a>
    <a mat-tab-link routerLinkActive="active-link" *ngIf="!isGuest && isAdmin" routerLink="organization-usage" class="overline-o1">{{'profile.sections.usage' | translate}}</a>
    <a mat-tab-link routerLinkActive="active-link" #rlRef="routerLinkActive" routerLink="user-data" class="overline-o1">{{'profile.sections.user-data' | translate}}</a>
    <a mat-tab-link routerLinkActive="active-link" *ngIf="!isGuest" routerLink="permissions" class="overline-o1">{{'profile.sections.permissions' | translate}}</a>

    <a mat-tab-link routerLinkActive="active-link" *ngIf="!isGuest" routerLink="notifications-configuration" class="overline-o1">{{'profile.sections.configuration' | translate}}</a>
    <a mat-tab-link routerLinkActive="active-link" *ngIf="!isGuest && isAdmin" routerLink="events" class="overline-o1">{{'profile.sections.events' | translate}}</a>
  </nav>
  <div class="h-content-profile">
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
