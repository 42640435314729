import { Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import { Permissions } from '../../../../../common/models/administration';
import { Actor, ActorReduced, Place, RecordDetails, RecordInfo, Theme, User } from '../../../../../common/models/main';
import { MainCacheService } from '../../../../../common/shared/main-chache.service';
import { MainEventService } from '../../../../../common/shared/main-event.service';

const CACHE_DURATION = 5000;

@Injectable({ providedIn: 'root' })
export class CacheService extends MainCacheService {
  private permissions: Record<string, Promise<Permissions>> = {};
  private records: Record<string, { record?: Promise<RecordInfo>, details?: Promise<RecordDetails>, actors?: Promise<ActorReduced[]>, actor?: Promise<Actor>, place?: Promise<Place> }> = {};

  constructor(protected translate: TranslateService, protected injector: Injector, protected dateAdapter: DateAdapter<Date>) {
    super(translate, injector, dateAdapter);
  }

  // Pide usuario y emite evento. Deja de usarlo con .toPromise() no funciona. Usa getUserPromise.
  getUser(): ReplaySubject<User> {
    if (this._$user) return this._$user;
    if (!this.getToken()) throw new Error('No hay token para poder obtener obtener el usuario');

    this._$user = new ReplaySubject<User>(1);
    this.api.getUser().subscribe(({ user }) => {
      this._$user?.next(user);
      this.locale = user.locale;
      MainEventService.login.emit({ user, organization: user.organization, token: this.getToken() as string });
    });

    return this._$user;
  }

  getUserPromise(): Promise<User> {
    return firstValueFrom(this.getUser().asObservable());
  }

  get theme(): Theme {
    return localStorage.getItem('theme') as Theme || 'light';
  }

  set theme(theme: Theme) {
    localStorage.setItem('theme', theme);
  }

  getMyPermissions(recordId: string): Promise<Permissions> {
    if (!this.permissions[recordId]) {
      this.permissions[recordId] = firstValueFrom(this.api.getMyPermissions(recordId));
      setTimeout(() => delete this.permissions[recordId], CACHE_DURATION);
    }
    return this.permissions[recordId];
  }

  clearMyPermissions(recordId: string) {
    delete this.permissions[recordId];
    // Provisional hasta que refactoricemos permissions para que no salga de la matriz
  }

  getRecord(recordId: string) {
    if (!this.records[recordId]) this.records[recordId] = {};
    if (!this.records[recordId].record) {
      this.records[recordId].record = firstValueFrom(this.api.getRecord(recordId));
      setTimeout(() => delete this.records[recordId], CACHE_DURATION);
    }
    return this.records[recordId].record!;
  }

  getRecordDetails(recordId: string) {
    if (!this.records[recordId]) this.records[recordId] = {};
    if (!this.records[recordId].details) {
      this.records[recordId].details = firstValueFrom(this.api.getRecordDetails(recordId));
      setTimeout(() => delete this.records[recordId], CACHE_DURATION);
    }
    return this.records[recordId].details!;
  }

  getRecordActors(recordId: string) {
    if (!this.records[recordId]) this.records[recordId] = {};
    if (!this.records[recordId].actors) {
      this.records[recordId].actors = firstValueFrom(this.api.getRecordActors(recordId));
      setTimeout(() => delete this.records[recordId], CACHE_DURATION);
    }
    return this.records[recordId].actors!;
  }

  getActor(recordId: string, resourceId: string) {
    if (!this.records[recordId]) this.records[recordId] = {};
    if (!this.records[recordId].actor) {
      this.records[recordId].actor = firstValueFrom(this.api.getActor(recordId, resourceId));
      setTimeout(() => delete this.records[recordId], CACHE_DURATION);
    }
    return this.records[recordId].actor!;
  }

  getPlace(recordId: string, resourceId: string) {
    if (!this.records[recordId]) this.records[recordId] = {};
    if (!this.records[recordId].place) {
      this.records[recordId].place = firstValueFrom(this.api.getPlace(recordId, resourceId));
      setTimeout(() => delete this.records[recordId], CACHE_DURATION);
    }
    return this.records[recordId].place!;
  }

  clearRecords(recordId: string) {
    this.records[recordId] = {};
  }

}
