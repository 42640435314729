<section class="px-8">
  <div class="title-t1 my-0 pt-6 primary-text">{{'events.title' | translate}}</div>

  <div class="pb-10">
    <div class="flex items-center justify-between my-4 space-x-5">
      <mat-form-field appearance="fill" class="w-1/2">
        <input matInput [placeholder]="'events.profile.searchPlaceholder' | translate" #input>
        <mat-icon svgIcon="search" class="p-4" matSuffix></mat-icon>
      </mat-form-field>
      <app-button-secondary (click)="openModal()" data-test="createEventTypeButton">
        {{'events.profile.newEventType' | translate}}
      </app-button-secondary>
    </div>

    <app-table-no-data [dataSource]="dataSource" [subscription]="subscription" [message]="('events.profile.noData' | translate)">

      <div class="shadow-dark overflow-auto whitespace-nowrap border-tb">
        <table mat-table [dataSource]="dataSource" class="w-full" data-test="events-table">
          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>{{'events.event' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <span (click)="openModal(element.id)" class="link">{{element.name}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="system">
            <th mat-header-cell *matHeaderCellDef class="center">{{'events.system' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-center w-full">
                <span>{{element.system}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="center">{{'events.externalId' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-center w-full">
                <span>{{element.externalId}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="class">
            <th mat-header-cell *matHeaderCellDef class="center">{{'events.class' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-center w-full space-x-2">
                <mat-icon [svgIcon]="'eventClass-' + element.class"></mat-icon>
                <span>{{'select.eventClass.' + element.class | translate}}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="notification">
            <th mat-header-cell *matHeaderCellDef class="center">{{'events.profile.notify' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-center w-full">
                <mat-checkbox (change)="editEvents(element.id, 'areNotifActive', $event)" [checked]="element.areNotifActive"></mat-checkbox>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef class="center">
              <div class="flex justify-center">
                {{'events.profile.active' | translate}}
                <mat-icon svgIcon="info" color="disabled" class="cursor-pointer" [matTooltip]="'events.profile.tooltip' | translate"></mat-icon>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-center w-full">
                <mat-slide-toggle (change)="editEvents(element.id, 'isActive', $event)" [checked]="element.isActive" [disabled]="element.isUsedInIntegrations"></mat-slide-toggle>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="center"></th>
            <td mat-cell *matCellDef="let element">
              <app-button-link *ngIf="!element.isUsedInIntegrations" iconLeft="trash" (click)="deleteEventType(element.id)" text="{{'events.profile.delete' | translate }}" [style]="'warn'"></app-button-link>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
        </table>
      </div>

      <div [ngClass]="{'border-paginator--empty': !dataSource.data.length}" class="border-paginator overflow-auto whitespace-nowrap">
        <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource.data.length}" class="mr-3"></mat-paginator>
      </div>
    </app-table-no-data>
  </div>

</section>
