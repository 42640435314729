import { Injectable } from '@angular/core';

import { MainEventService } from '../../../../../common/shared/main-event.service';
import { EnvService } from './env.service';


@Injectable({
  providedIn: 'root',
})
export class EventService extends MainEventService {

  constructor(protected env: EnvService) {
    super(env);
  }

  connect(token: string) {
    super.connect(token);
    // Aqui se añadiran los eventos que se quieran escuchar. Usa como referencia el EventService de Front
  }

  socketSubscribe(type: 'Definir los tipos que se deseen', id: string) {
    super.socketSubscribe(type, id);
  }

  socketUnsubscribe(type: 'Definir los tipos que se deseen', id: string) {
    super.socketUnsubscribe(type, id);
  }
}
