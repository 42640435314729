import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EventMetadata } from '../../../../../../../../common/models/main';

@Component({
  selector: 'app-event-metadata-modal',
  templateUrl: './event-metadata-modal.component.html',
  styleUrls: ['./event-metadata-modal.component.scss'],
})
export class EventMetadataModalComponent implements OnInit {

  container!: string;
  data!: Record<string, string>;
  constructor(
    private dialogRef: MatDialogRef<EventMetadataModalComponent>,
    @Inject(MAT_DIALOG_DATA) public metadata: EventMetadata,
  ) { }

  ngOnInit(): void {
    if (this.metadata.smartPortMxEvent) {
      this.data = this.metadata.smartPortMxEvent.containerDetails.Data[0];
      this.container = this.data.Container;
    }
    if (this.metadata.soiaEvent) {
      this.data = this.metadata.soiaEvent;
    }
  }

  close() {
    this.dialogRef.close();
  }

}
