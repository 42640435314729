<div class="mx-6 w-1/2">
  <div *ngIf="organization">
    <div class="flex items-center justify-start">
      <h3 class="overline-o2 secondary-text mb-0 inline-block mt-6">{{'organizationUsage.title' | translate }}</h3>
      <span *ngIf="!editForm" (click)="editForm = !editForm" class="flex action items-center ml-4 mt-5" data-test="editBillingDataButton">
        <mat-icon svgIcon="edit-2" color="primary" class="pr-1"></mat-icon>
        <span class="link edit">{{'organizationData.billing.edit' | translate}}</span>
      </span>
    </div>
  </div>
</div>

<app-billing-data *ngIf="organization" [organization]="organization" [editForm]="editForm"></app-billing-data>

<div *ngIf="editForm" class="mx-6 w-1/2">
  <div class="flex mt-4">
    <app-input [type]="'text'" [control]="form.controls.name" [placeholder]="'organizationData.billing.namePlaceholder' | translate" [label]="'organizationData.billing.name' | translate" required class="w-1/2 mr-4" data-test="billingName"></app-input>
    <app-input [type]="'text'" [control]="form.controls.email" [placeholder]="'organizationData.billing.emailPlaceholder' | translate" [label]="'organizationData.billing.email' | translate" class="w-1/2" data-test="billingEmail"></app-input>
  </div>
  <app-address-data [form]="form.controls.address"></app-address-data>
  <app-input [type]="'text'" [control]="form.controls.taxId" [placeholder]="'organizationData.billing.taxIdPlaceholder' | translate" [label]="'organizationData.billing.taxId' | translate" class="w-1/2 mb-2" data-test="billingLegalId"></app-input>
  <div class="mt-2">
    <app-button-secondary (click)="saveBillingData()" [type]="'submit'" class="mt-2" data-test="saveBillingDataButton">{{'buttons.save' | translate}}</app-button-secondary>
  </div>
</div>

<hr class="divider my-3 ml-0 w-1/3">

<app-usages *ngIf="organization" [organization]="organization"></app-usages>

