import { EventEmitter, Injectable } from '@angular/core';
import isEqual from 'lodash-es/isEqual';

import { Theme } from '../../../../../common/models/main';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { EnvService } from './env.service';


@Injectable({
  providedIn: 'root',
})
export class EventService extends MainEventService {

  public theme: EventEmitter<Theme> = new EventEmitter<Theme>();
  // La idea es empezar a mandar la seccion que origina el evento para poder skipearlo alla donde sea necesario. Ejemplo, el canvas genera y escucha este evento pero no debe reaccionar a los generados por el mismo.
  public changeRecord: EventEmitter<{ recordId?: string, origin: 'socket' | 'canvas' } | undefined> = new EventEmitter<{ recordId?: string, origin: 'socket' | 'canvas' } | undefined>();
  public fileUpload: EventEmitter<any> = new EventEmitter<any>();
  public changeDocuments: EventEmitter<string> = new EventEmitter<string>();
  public changeFavorites: EventEmitter<string> = new EventEmitter<string>();
  public notifications: EventEmitter<string> = new EventEmitter<string>();
  public chatUpdate: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected env: EnvService) {
    super(env);
  }

  connect(token: string) {
    super.connect(token);
    this.socket.on('records.update', (socketId, res) => {
      if (this.socket.id !== socketId && !isEqual(res.subres, ['canvas'])) this.changeRecord.emit({ recordId: res.recordId, origin: 'socket' });
    });
    this.socket.on('record-documents.update', (socketId) => {
      if (this.socket.id !== socketId) this.changeDocuments.emit();
    });
    this.socket.on('chats.update', (socketId) => {
      if (this.socket.id !== socketId) this.chatUpdate.emit();
    });
    this.socket.on('records.blockchain.upload', (socketId) => {
      if (this.socket.id !== socketId) this.changeDocuments.emit();
    });
    this.socket.on('notifications.new', (socketId, res) => {
      if (this.socket.id !== socketId) this.notifications.emit(res);
    });
  }

  socketSubscribe(type: 'records' | 'record-actors' | 'record-places' | 'record-documents' | 'users' | 'chats', id: string) {
    super.socketSubscribe(type, id);
  }

  socketUnsubscribe(type: 'records' | 'record-actors' | 'record-places' | 'record-documents' | 'users' | 'chats', id: string) {
    super.socketUnsubscribe(type, id);
  }
}
