import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-table-no-data[message][dataSource]',
  templateUrl: './table-no-data.component.html',
  styleUrls: ['./table-no-data.component.scss'],
})
export class TableNoDataComponent {
  loading = false;
  @Input() fullWidth? = false;
  @Input() message!: string;
  @Input() dataSource!: MatTableDataSource<any>;

  @Input() set subscription(subscription: Subscription | undefined) {
    if (subscription) {
      this.loading = true;
      subscription.add(() => this.loading = false);
    }
  };

  constructor() {
  }
}
