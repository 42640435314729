<div data-test="imageCropperModal">
  <div mat-dialog-title class="pt-8">{{'logoCropper.title' | translate}}</div>
  <hr class="divider">
  <mat-dialog-content>
    <div class="px-6">
      <div *ngIf="!file || invalidForm" class="p-4">
        <div class="drag h-1/2 flex flex-col justify-center items-center" [ngClass]="{'no-file' : invalidForm}">
          <input type="file" id="fileDropRef" (change)="fileBrowseHandler($event)" class="file-uploader h-full cursor-pointer" data-test="uploadImageInputFile"/>
          <mat-icon svgIcon="upload" color="disabled" [ngClass]="{'text-danger': invalidForm}"></mat-icon>
          <span for="fileDropRef" [ngClass]="{'text-danger': invalidForm}" class="text-center grey-700 cursor-pointer body-b2">{{'logoCropper.drag' | translate}}</span>
        </div>
        <div *ngIf="invalidForm" class="body-b2 my-4 error-500 flex flex-col items-start">
          <span>{{'logoCropper.errorSize' | translate}}</span>
          <span>{{'logoCropper.errorFormat' | translate}}</span>
        </div>

      </div>
      <div class="cropper-height">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1" format="png" [resizeToWidth]="300" (imageCropped)="imageCropped($event)" [roundCropper]="true" [allowMoveImage]="true"></image-cropper>
      </div>
    </div>
  </mat-dialog-content>
  <hr class="divider">

  <mat-dialog-actions>
    <div class="flex w-full items-center justify-between">
      <app-button-link (click)="closeModal(null)" text="{{'buttons.cancel' | translate}}" [style]="'warn'"></app-button-link>
      <app-button-secondary (click)="closeModal(croppedImage)" [disabled]="!croppedImage" data-test="saveImage">{{'buttons.save' | translate}}</app-button-secondary>
    </div>
  </mat-dialog-actions>
</div>
