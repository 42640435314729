import { Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';

import { ASTRONOMICAL_BODY, BillingData, OrganizationExtended, Usage, User } from '../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../../common/shared/custom-validators';
import { CacheService } from '../../../core/services/cache.service';

@Component({
  selector: 'app-organization-usage',
  templateUrl: './organization-usage.component.html',
  styleUrls: ['./organization-usage.component.scss'],
})

export class OrganizationUsageComponent implements OnInit {
  user!: User;
  organization!: OrganizationExtended;
  usages: Usage[] = [];
  totalUsages: number = 1;
  query: CustomQuery = { pagination: { offset: 0, limit: 1 } };
  form = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, CustomValidators.validateEmail]],
    taxId: ['', [Validators.required]],
    address: this.fb.group({
      astronomicalBody: [ASTRONOMICAL_BODY.Earth],
      country: [''],
      subdivision: [''],
      locality: [''],
      street: [''],
      postalCode: [''],
    }),
  });

  editForm: boolean = false;
  constructor(private fb: NonNullableFormBuilder, private api: ApiService, private cache: CacheService) { }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.organization = this.user.organization!;
    this.form.patchValue(this.organization.billing!);
  }

  saveBillingData() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    const data: { billing: BillingData } = {
      billing: this.form.getRawValue(),
    };

    if (data.billing.email) data.billing.email = data.billing.email.trim();

    this.api.editOrganizationData(data, this.organization.id).subscribe((res: OrganizationExtended) => {
      this.api.log('toast.saved');
      this.organization = res;
      this.user.organization = res;
      this.cache.setUser(this.user);
      this.editForm = false;
    });
  }
}
