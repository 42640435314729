import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT, MODAL_WIDTH } from '../../../../../common/models/constants';
import { Favorite, User } from '../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../common/services/api.service';
import { CacheService } from '../../core/services/cache.service';
import { EventService } from '../../core/services/event.service';
import { HelperService } from '../../core/services/helper.service';
import { FavoritesModalComponent } from './favorites-modal/favorites-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit, AfterViewInit {
  Helper = HelperService;
  displayedColumns: string[] = ['name', 'id', 'createdAt', 'addBy'];
  dataSource: MatTableDataSource<Record<string, any>> = new MatTableDataSource<Record<string, any>>([]);
  query: CustomQuery = { filter: {}, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  totalElements = 0;
  isGuest: boolean = true;
  user!: User;
  favorites!: Favorite[];
  subscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('input') input!: ElementRef;

  constructor(private api: ApiService, private dialog: MatDialog, public helper: HelperService, private cache: CacheService, private eventService: EventService) {
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(250)).subscribe(() => {
      this.query.filter!.search = this.input.nativeElement.value;
      this.getFavorites(this.query);
    });
  }

  ngOnInit(): void {
    this.cache.getUserPromise().then(user => {
      this.user = user;
      this.isGuest = !user.organization;
      if (!this.isGuest) {
        this.displayedColumns.push('action');
      }
    });
    this.dataSource.paginator = this.paginator;
    this.eventService.changeFavorites.pipe(untilDestroyed(this)).subscribe(() => this.getFavorites(this.query));
    this.getFavorites(this.query);
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getFavorites(this.query);
  }

  getFavorites(query: CustomQuery) {
    this.subscription = this.api.getAllFavorites(query).subscribe(res => {
      this.favorites = res.data;
      this.dataSource.data = this.favorites;
      this.totalElements = res.total;
    });
  }

  openFavoritesModal(favoriteId?: string) {
    this.dialog.open(FavoritesModalComponent, { ...MODAL_WIDTH, data: { favoriteId: favoriteId }, disableClose: true });
  }

  sortFavorites(sortState: Sort) {
    if (sortState.direction === 'asc') this.query.sort = [sortState.active];
    else if (sortState.direction === 'desc') this.query.sort = ['-' + sortState.active];
    else this.query.sort = [];
    this.getFavorites(this.query);
  }

  deleteFavorite(favoriteId: string) {
    this.api.deleteFavorite(favoriteId).subscribe(() => {
      this.api.log('favorites.toast.deleted');
      this.eventService.changeFavorites.emit();
    });
  }
}
