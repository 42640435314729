import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Usage } from '../../models/main';

@Component({
  selector: 'app-usage-card',
  templateUrl: './usage-card.component.html',
  styleUrls: ['./usage-card.component.scss'],
})
export class UsageCardComponent implements OnInit {
  @Input() type!: string;
  @Input() data!: Usage;
  title!: string;
  icon!: string;
  date!: Date;
  value!: string | undefined;
  unit = '';
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    switch (this.type) {
      case 'recordsMonth':
        this.title = this.translate.instant('usageCard.recordsMonth', { monthYear: this.translate.instant('organizationUsage.months.' + this.data.month) + ' ' + this.data.year });
        this.icon = 'file-text';
        this.date = this.data.periodStartAt;
        this.value = this.data.products.records.period;
        break;
      case 'records':
        this.title = this.translate.instant('usageCard.records');
        this.icon = 'copy';
        this.date = this.data.periodStartAt;
        this.value = this.data.products.records.total;
        break;
      case 'users':
        this.title = this.translate.instant('usageCard.users');
        this.icon = 'users';
        this.date = this.data.periodStartAt;
        this.value = this.data.products.members.total;
        break;
      case 'storage':
        this.title = this.translate.instant('usageCard.storage');
        this.icon = 'save';
        this.date = this.data.periodStartAt;
        this.value = this.data.products.storage.total;
        this.unit = 'GB';
        break;
    }
  }
}
