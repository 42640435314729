<div class="w-full">
  <mat-checkbox
    [checked]="elements.length === form.value.length"
    (click)="selectAll()"
    class="body-b2"
    >{{ "goods.modal.all" | translate }}</mat-checkbox
  >
  <hr class="divider" />
  <div *ngFor="let item of elements;let last = last" class="body-b2">
    <mat-checkbox
      [checked]="isChecked(item.id)"
      (change)="onCheckboxChange($event, item.id)"
    >
      <div class="flex justify-center items-center">
        <mat-icon
          [svgIcon]="icon"
          class="icon-color"
        ></mat-icon>
        <span class="icon-color ml-1">
          {{ !i18nPath ? item.text : (i18nPath + item.text | translate) }}</span
        >
      </div>
    </mat-checkbox>
    <hr *ngIf="!last" class="divider" />
  </div>
</div>
