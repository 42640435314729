<div>
  <div mat-dialog-title class="overline-o1 px-6 pt-8">
    {{data.title}}
  </div>
  <hr class="divider">
  <mat-dialog-content>
    <div class="px-6">
      <p>{{data.content}}</p>
    </div>
    <ng-container *ngTemplateOutlet="data.template || null"></ng-container>
  </mat-dialog-content>
  <hr class="divider">
  <mat-dialog-actions>
    <div [ngClass]="data.buttons.length > 1 ? 'justify-between' : 'justify-end'" class="flex w-full  pr-2 space-x-4" >
      <span *ngFor="let button of data.buttons; let i = index" (click)="closeModal(button.value)">
        <app-button-secondary *ngIf="button.type === 'button'">{{button.label}}</app-button-secondary>
        <div *ngIf="button.type === 'link'" class="link pt-3 flex">
          <div *ngIf="button.icon">
            <mat-icon [svgIcon]="button.icon" class="mr-2 cursor-pointer" color="primary"></mat-icon>
          </div>
          <span >{{button.label}}</span>
        </div>
      </span>
    </div>
  </mat-dialog-actions>
</div>
