import { Location } from '@angular/common';
import { ElementRef, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import isObject from 'lodash-es/isObject';
import mapValues from 'lodash-es/mapValues';
import { filter, pairwise } from 'rxjs/operators';

import { GenericModalData, OnSubmit } from '../models/main';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class MainHelperService {

  previousURL = '';

  protected constructor(
    protected translate: TranslateService,
    protected fb: FormBuilder,
    protected location: Location,
    protected router: Router,
    @Inject(LOCALE_ID) protected locale: string,
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof NavigationEnd), pairwise())
      .subscribe((events: NavigationEnd[]) => {
        this.previousURL = events[0].urlAfterRedirects;
      });
  }

  static isTokenExpired(token: string): boolean {
    return helper.isTokenExpired(token);
  }

  static scrollTo(element: HTMLElement | ElementRef): void {
    (element instanceof ElementRef ? element.nativeElement : element).scrollIntoView({ behavior: 'smooth' });
  }

  translateModal(data: GenericModalData, params: Record<string, string> = {}): GenericModalData {
    for (const button of data.buttons) {
      button.label = this.translate.instant(button.label);
    }
    if (data.content) data.content = this.translate.instant(data.content, params);
    if (data.title) data.title = this.translate.instant(data.title, params);
    return data;
  }

  createDeepForm(data: any): AbstractControl {
    if (Array.isArray(data)) return this.fb.array(data.map(e => this.createDeepForm(e)));
    if (typeof data === 'object' && data !== null) return this.fb.group(mapValues(data, e => this.createDeepForm(e)));
    return this.fb.control(data);
  }

  static createDeepForm(data: any, builder: FormBuilder): AbstractControl {
    if (Array.isArray(data)) return builder.array(data.map(e => this.createDeepForm(e, builder)));
    if (typeof data === 'object' && data !== null) return builder.group(mapValues(data, e => this.createDeepForm(e, builder)));
    return data;
  }

  createFormArrayFromObject<T>(data: Array<T> | undefined, objectFactory: (data: T | {}) => Object): FormArray {
    if (data && data.length) return this.fb.array(data.map(elem => this.createDeepForm(objectFactory(elem))));
    else return this.fb.array([this.createDeepForm(objectFactory({}))]);
  }

  createFormArrayFromForm<T>(data: Array<T> | undefined, formFactory: (data: T | {}, fb: FormBuilder) => FormGroup, disabledForm: boolean = false): FormArray {
    if (data && data.length) {
      const form = this.fb.array(data.map(elem => formFactory(elem, this.fb)));
      if (disabledForm) form.disable();
      return form;
    } else {
      return this.fb.array([formFactory({}, this.fb)]);
    }
  }

  static getTimeToExpireToken(token: string) {
    if (this.isTokenExpired(token)) return 0;
    const expireTime = helper.getTokenExpirationDate(token)!.getTime();
    const difference = expireTime - new Date().getTime();
    return (difference > 2147483647) ? 2147483647 : difference; // 2147483647 is maxInt32
  }

  static isValidatorsFailing(onSubmit: OnSubmit): boolean {
    onSubmit.handlers.forEach(handler => handler.cb());

    for (const validator of onSubmit.validators) {
      if (!validator.cb()) return true; // si alguna funcion devuelve false se retorna true ya que ha ocurrido un error o condicion no deseada
    }
    return false;
  }

  static getInitials(data: string) {
    if (data.includes('@')) return data[0];
    const separateInitials = data.normalize('NFD').replace(/[\u0300-\u036f]/g, '').match(/\b(\w)/g)?.splice(0, 3) || [];
    return separateInitials.join('');
  }

  static getShortId(id: string) {
    return `#${id.substring(id.length - 5)}`;
  }

  redirectToLogin(): Promise<boolean> {
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.pathname === '/login' && urlParams.has('return')) return Promise.resolve(true); // Si estaba camino del login y ya tiene un return no es necesario otro redirect
    else return this.router.navigate(['/login'], { queryParams: { return: this.location.path() } });
  }

  // Aplica un CB a cada elemento hoja iterando lo que se encuentre (claves de objetos o arrays)
  static mapValuesDeep: Function = (obj: any, cb: Function) => {
    // Si es array, itero
    if (Array.isArray(obj)) return obj.map(innerObj => MainHelperService.mapValuesDeep(innerObj, cb));
    // Si es objeto itero sus claves
    if (isObject(obj)) return mapValues(obj, val => MainHelperService.mapValuesDeep(val, cb));
    // Si es hoja aplico el CB
    return cb(obj);
  };
}
