export abstract class MainEnvService {

  // Para poder validar que las claves reciben valores del fichero JSON se necesita que la clave exista.
  // Se inicializa a undefined para que funcione y se marca "as any" para que se mantenga el tipo original
  name: 'demo' | 'test' | 'dev1' | 'dev2' | 'qa' | 'sandbox' | 'open' = undefined as any;
  production: boolean = undefined as any;
  url: string = undefined as any;
  socketUrl: string = undefined as any;
  socketPath: string = undefined as any;
  captchaKey: string = undefined as any; // Vacio para desactivar
  webPushVAPIDPublicKey: string = undefined as any;

  setEnv(): Promise<void> {
    return fetch('config.json').then(res => res.json()).then(res => {

      // @ts-ignore Reescribe los atributos del objeto con los que recibe desde el fichero
      Object.keys(res).forEach(k => this[k] = res[k]);

      Object.keys(this).forEach(k => {
        // @ts-ignore
        if (this[k] === undefined) throw new Error('Faltan valores en el fichero de configuracion para la clave: ' + k);
      });
    });
  }
}
