<div *ngFor="let customField of (form.controls | cast); let i = index" class="flex flex-col w-full">
  <div class="flex">
    <div class="flex mr-2 w-1/2">
      <div class="flex flex-col mr-2" *ngIf="type !== 'custom'" [ngClass]="customField.get('name') ? 'w-1/2' : 'w-full'">
        <mat-label [ngClass]="{'text-disabled' : form.disabled}" class="label whitespace-nowrap mb-1">{{'organizationData.customField' | translate}}</mat-label>
        <mat-form-field class="caption-c1">
          <mat-select [formControl]="customField.get('type')" (selectionChange)="selectCustomFieldType($event, i)" [placeholder]="'organizationData.select' | translate" data-test="customField">
            <mat-option *ngFor="let option of options | sortTranslation: 'customField.'" [value]="option">
              {{('customField.' + option) | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="customField.get('name')" [ngClass]="customField.get('name') && type === 'custom' ? 'w-full' : 'w-1/2 mr-2'">
        <app-input [type]="'text'" [control]="customField.get('name')" [placeholder]="'customField.name' | translate" [label]="'customField.name' | translate" data-test="customFieldName"></app-input>
      </div>
    </div>
    <div class="flex items-center w-1/2">
      <app-input [type]="'text'" [control]="customField.get('value')" [placeholder]="'customField.value' | translate" [label]="'customField.value' | translate" class="w-1/2 mr-4" data-test="customFieldValue"></app-input>
      <app-button-link (click)="deleteFormElement(form, i);" text="{{'buttons.delete' | translate}}" [disabled]="form.disabled" [style]="'warn'" iconLeft="trash"></app-button-link>
    </div>
  </div>
</div>

<app-button-link (click)="addFormElement(form, builder(customFieldFormFactory))" text="{{'buttons.newField' | translate}}" [disabled]="form.disabled" [style]="'primary'" iconLeft="plus-circle"></app-button-link>


