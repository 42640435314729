<div *ngIf="_form && type === 'currency' " class="flex w-full space-x-2">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.amount" [label]="label" [placeholder]="placeholder" class="w-1/2"></app-input>
  <app-autocomplete-select [control]="_form.controls.currency" [type]="'currency'" class="w-1/2" [label]="' '" data-test="currency"></app-autocomplete-select>
</div>

<div *ngIf="_form && type === 'weight' " class="flex w-full space-x-2">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-1/2"></app-input>
  <app-select [control]="_form.controls.unit" [type]="'weight'" class="w-1/2"></app-select>
</div>

<div *ngIf="_form && type === 'temperature' " class="flex w-full space-x-2">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-1/2"></app-input>
  <app-select [control]="_form.controls.unit" [type]="'temperature'" class="w-1/2"></app-select>
</div>

<div *ngIf="_form && type === 'volume' " class="flex w-full space-x-2">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-1/2"></app-input>
  <app-select [control]="_form.controls.unit" [type]="'volume'" class="w-1/2"></app-select>
</div>

<div *ngIf="_form && type === 'height' " class="flex w-full space-x-2">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-1/2"></app-input>
  <app-select [control]="_form.controls.unit" [type]="'length'" class="w-1/2"></app-select>
</div>

<div *ngIf="_form && type === 'distance' " class="flex w-full space-x-4">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-3/4"></app-input>
  <app-select [control]="_form.controls.unit" [type]="'distance'" class="w-1/4"></app-select>
</div>

<div *ngIf="_form && type === 'width' " class="flex w-full">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-full">{{ (suffixControl?.controls?.unit?.valueChanges | async) || suffixControl?.controls?.unit?.value }}</app-input>
</div>

<div *ngIf="_form && type === 'depth' " class="flex w-full">
  <app-input [type]="'number'" [decimals]="2" [control]="_form.controls.value" [label]="label" [placeholder]="placeholder" class="w-full">{{ (suffixControl?.controls?.unit?.valueChanges | async) || suffixControl?.controls?.unit?.value }}</app-input>
</div>
