<div class="flex flex-col mb-2">
  <mat-label class="label mb-1" [ngClass]="{'text-danger mat-form-field-invalid' : _control.invalid && _control.touched, 'text-disabled' : _control.disabled}">
    {{ label }}
  </mat-label>
  <mat-form-field>
    <input class="datepicker-h" matInput [formControl]="_control" [matDatepicker]="datepicker" placeholder="{{placeholder}}">
    <mat-datepicker-toggle matSuffix [for]="datepicker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" color="primary" class="mr-3" [ngClass]="{'text-danger' : _control.invalid && _control.touched, 'text-disabled' : _control.disabled}"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
  <app-control-error [control]="_control"></app-control-error>
</div>
