import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-nested-n1[title]',
  templateUrl: './nested-n1.component.html',
  styleUrls: ['./nested-n1.component.scss'],
})
export class NestedN1Component {

  @Input() disabled = false;
  @Input() title!: string;
  @Output() delete = new EventEmitter<void>();
  @Input() margin = true;

  constructor() {
  }

}
