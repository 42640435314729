<div class="mx-6 my-2">
  <div *ngIf="editForm" class="title-t1 whitespace-nowrap mt-4">{{'organizationUsage.billing.title' | translate}}</div>
  <div *ngIf="!editForm">
    <div class="title-t1 whitespace-nowrap mt-4">{{isBackOffice ? organization.billing.name + '/' + organization.name : organization.billing.name}}</div>

    <p *ngIf="organization.billing.taxId" class="body-b2 capitalize">{{'organizationData.billing.taxID' | translate}} {{organization.billing.taxId}}</p>

    <div class="flex flex-col justify-start description primary-text body-b2 mr-10 mt-4 w-1/2">
      <p class="block my-0.5">{{organization.billing.email}}</p>
      <p class="block my-0.5">
        <span *ngIf="organization.billing.address?.street" class="comma">{{organization.billing.address?.street}}</span>
        <span *ngIf="organization.billing.address?.locality" class="comma"> {{organization.billing.address?.locality}}</span>
      </p>
      <p class="my-0.5 inline-block">
        <span *ngIf="organization.billing.address?.subdivision || organization.billing.address?.postalCode || organization.billing.address?.country" class="comma trim">
          {{organization.billing.address?.subdivision}}
          {{organization.billing.address?.postalCode}}
          {{organization.billing.address?.country}}</span>
        <span *ngIf="organization.billing.address?.astronomicalBody" class="comma capitalize"> {{('select.astronomicalBody.' + organization.billing.address?.astronomicalBody) | translate}}</span>
      </p>
    </div>
  </div>
</div>
