<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div class="dialog-container__header">
    <div mat-dialog-title class="overline-o1">
      {{'chats.joinTheChat' | translate}}
    </div>
  </div>
  <hr class="divider">
  <div class="py-5 mx-8">
    <mat-dialog-content>
      <div class="flex flex-col justify-center ml-4">
        <mat-radio-group (change)="checkValue($event.value)" class="flex mb-6">
          <mat-radio-button [checked]="true" value="chat" class="mr-4 label">{{'chats.selfInviteToChat' | translate}}</mat-radio-button>
          <mat-radio-button *ngIf="!isInRecord" [disabled]="!hasReadingPermits" value="record" class="label">{{'chats.selfInviteToRecord' | translate}}</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="inviteToRecord" class="w-full flex flex-col">
          <mat-label class="whitespace-nowrap label">
            {{ 'chats.chooseActor' | translate }}
          </mat-label>
          <mat-form-field class="w-1/2 mt-2">
            <mat-select (selectionChange)="joinActor($event.value)" [placeholder]="'chats.select' | translate">
              <mat-option *ngFor="let actor of actors" [value]="actor.id" class="body-b2">
                <span class="capitalize">{{ ('objects.' + actor.subtype) | translate }}</span>
                <span> | </span>
                <span class="label">{{actor.name ? actor.name : Helper.getShortId(actor.id)}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <hr class="divider mr-8">
  <mat-dialog-actions>
    <div class="w-full flex justify-end">
      <app-button-secondary (click)="updateActor()" [type]="'submit'">
        {{'buttons.join' | translate}}
      </app-button-secondary>
    </div>
  </mat-dialog-actions>
</section>
