<form>
  <mat-form-field class="w-full">
    <input type="text" closeOnScroll matInput [formControl]="myControl" [matAutocomplete]="auto" [placeholder]="'autocomplete.placeholder' | translate">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="emitAutocomplete($event)" >
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        <div class="flex flex-col w-full body-b3 text-grey">
          <div class="flex justify-between">
            <div>
              <span>{{option.alias}}</span>
              <span *ngIf="option.alias && option.name"> | </span>
              <span>{{option.name}}</span>
            </div>
            <div>
              <span>ID:{{option.legalId}}</span>
            </div>
          </div>
          <div>
            <span *ngIf="option.address?.street"><mat-icon svgIcon="location-pin"></mat-icon>{{option.address?.street}}</span>
            <span *ngIf="option.address?.postalCode"> {{option.address?.postalCode}}</span>
            <span *ngIf="option.address?.locality"> {{option.address?.locality}}</span>
            <span *ngIf="option.address?.country"> - {{option.address?.country}}</span>
            <span *ngIf="option.address?.street || option.address?.postalCode || option.address?.locality"> | </span>
            <span *ngIf="option.address?.astronomicalBody">{{('select.astronomicalBody.' + option.address?.astronomicalBody) | translate}}</span>
          </div>
          <div class="flex justify-between">
            <div class="flex space-x-4">
              <div *ngIf="option.email">
                <mat-icon svgIcon="messages"></mat-icon><span>{{option.email}}</span>
              </div>
              <div *ngIf="option.phone">
                <mat-icon svgIcon="phone"></mat-icon><span>{{option.phone}}</span>
              </div>
            </div>
            <div class="link entry-type">
              <div *ngIf="option.autocompleteType ==='favorites'">
                <mat-icon svgIcon="heart-filled"></mat-icon><span>{{'favorites.title' | translate}}</span>
              </div>
              <div *ngIf="option.autocompleteType ==='organizations'">
                <mat-icon svgIcon="earth"></mat-icon><span>{{'organizationData.publicProfile' | translate}}</span>
              </div>
              <div *ngIf="option.autocompleteType ==='record-actors'">
                <span>{{Helper.getShortId(option.autocompleteRecord.id)}}</span>
                <span *ngIf="option.autocompleteRecord.alias">{{' | '+ option.autocompleteRecord.alias}}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
