<section class="dialog-container relative" data-test="selectTemplateModal">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div class="dialog-container__header">
    <div mat-dialog-title class="overline-o1">
      {{'templates.title' | translate}}
    </div>
  </div>
  <hr class="divider">
  <div class="py-5 mx-8">
    <mat-dialog-content>
      <div class="flex flex-col space-y-5">
        <div class="overline-o2">{{'templates.basic' | translate}}</div>
        <div class="flex flex-wrap gap-2 p-1" data-test="recordTemplates">
          <p (click)="createRecordWithTemplate(basic.id)" *ngFor="let basic of basicTemplates" class="block template flex items-center text-center justify-center overline-o2 cursor-pointer break-normal">{{ 'templates.default.' + basic.name | translate | truncate: 40}}</p>
        </div>

        <div *ngIf="customizedTemplates.length > 0" class="overline-o2">{{'templates.customized' | translate}}</div>
        <div *ngIf="customizedTemplates.length > 0" class="flex flex-wrap gap-2">
          <div *ngFor="let customized of customizedTemplates">
            <div (click)="createRecordWithTemplate(customized.id)" data-test="customTemplate" class="p-1 template template--custom overline-o2 cursor-pointer flex items-center justify-center overflow-hidden">
              <p class="m-1.5 break-normal">{{customized.name | truncate: 40 }}</p>
            </div>
            <app-button-link (click)="deleteTemplate(customized.id)" [style]="'warn'" text="{{'templates.delete' | translate}}" iconLeft="trash" data-test="deleteCustomTemplate" class="block mt-2"></app-button-link>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</section>
