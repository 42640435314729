import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OrganizationExtended, User, ValidatedEdoc } from '../../../../../../../common/models/main';
import { CacheService } from '../../../../core/services/cache.service';

@Component({
  selector: 'app-digital-identity-modal',
  templateUrl: './digital-identity-modal.component.html',
  styleUrls: ['./digital-identity-modal.component.scss'],
})

export class DigitalIdentityModalComponent implements OnInit {

  user!: User;
  organization!: OrganizationExtended;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ValidatedEdoc, private cache: CacheService,
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.organization = this.data.organization;
    } else {
      this.cache.getUser().subscribe((user) => {
        this.user = user;
        this.organization = user.organization as OrganizationExtended;
      });
    }
  }
}
