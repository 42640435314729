import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { CanDeactivateComponent } from '../../../../../common/models/main';
import { CacheService } from '../services/cache.service';
import { HelperService } from '../services/helper.service';


export const Logged = async () => {
  const cache: CacheService = inject(CacheService);
  const router: Router = inject(Router);
  const helper: HelperService = inject(HelperService);
  const token = cache.getToken();
  // Si no hay token o ha expirado redirijo a login con los parámetros que tenga en la url
  if (!token || HelperService.isTokenExpired(token)) {
    await helper.redirectToLogin();
    return false;
  }
  const user = await cache.getUserPromise();
  if (user.organization && !user.emailValidatedAt) {
    await router.navigate(['/onboarding'], { queryParamsHandling: 'merge' });
    return false;
  }
  return true;
};


export const Registered = async () => {
  const cache: CacheService = inject(CacheService);
  const user = await cache.getUserPromise();
  return !!user.organization;
};

export const OrganizationOwner = async () => {
  const cache: CacheService = inject(CacheService);
  const router: Router = inject(Router);
  const user = await cache.getUserPromise();
  if (!user.isAdmin) {
    await router.navigate(['/']);
    return false;
  }
  return true;
};

export const CanDeactivateGuard = (component: CanDeactivateComponent) => component.canDeactivate ? component.canDeactivate() : true;
