<mat-tab-group *ngIf="matrixData" class="h-full">
  <mat-tab label="{{'profile.resourcePermissions' | translate}}">
    <app-matrix [data]="matrixData" [view]="'resources'" [parent]="this"></app-matrix>
  </mat-tab>

  <mat-tab label="{{'profile.documentsPermissions' | translate}}">
    <app-matrix [data]="matrixData" [view]="'documents'" [parent]="this"></app-matrix>
  </mat-tab>

  <mat-tab label="{{'profile.creationPermissions' | translate}}">
    <app-matrix [data]="matrixData" [view]="'create'" [parent]="this"></app-matrix>
  </mat-tab>
</mat-tab-group>
