<section class="px-4 h-full overflow-y-auto">
  <div *ngIf="!recordId" class="p-4">
    <h2 class="overline-o1 mt-0 mb-1 primary-400 secondary-text">{{'chats.dashboard' | translate}}</h2>
    <p class="title-t1 my-0 primary-text">{{'chats.messages' | translate}}</p>
  </div>

  <div class="mt-5 flex justify-between items-center mb-2 pl-4 pr-6">
    <div *ngIf="!this.isGuest" class="table-header flex justify-between my-2 items-end space-x-5">
      <div class="flex link items-end space-x-5 whitespace-nowrap">
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.organization': undefined}" [class.primary-text]="!query.filter!.organization">{{'chats.filterMe' | translate}}</span>
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.organization': true}" routerLinkActive="primary-text">{{'chats.filterOrg' | translate}}</span>
      </div>
    </div>
    <app-button-secondary [disabled]="records?.length === 0" (click)="openNewMessage()" [type]="'submit'" data-test="newChatButton">
      <span [matTooltip]="records?.length === 0 ? ('chats.tooltip' | translate) : ''" [matTooltipPosition]="'left'"> {{'chats.newChat' | translate}}</span>
    </app-button-secondary>
  </div>

  <app-table-no-data [dataSource]="dataSource" [subscription]="subscription" [message]="('recordList.noChats' | translate)" class="w-full justify-center" data-test="chatsList">
    <div class="shadow-dark p-6 mx-4">
      <ul *ngIf="chats.length > 0" class="w-full py-4">
        <li *ngFor="let chat of dataSource.data" class="chats ml-2 mb-6">
          <div class="flex w-full space-x-4 mb-4">
            <div class="flex flex-col w-5/6 space-y-2">
              <div class="flex w-full justify-between items-baseline">
                <div class="flex items-baseline space-x-2">
                  <app-avatar-circle [type]="'initials'" [data]="chat.lastMessage.senderUser.name || chat.lastMessage.senderUser.email"></app-avatar-circle>
                  <span (click)="checkLinkVisibility(chat.record.id, chat.id)" class="link" data-test="chatTitleList">{{chat.title}}</span>
                </div>
                <div class="overline-o2 grey items-baseline">
                  <span data-test="chatUserList">{{ 'chats.of' | translate}} {{chat.lastMessage.senderUser.name || chat.lastMessage.senderUser.email}}</span>
                  <span class="mx-2">·</span>
                  <span>{{chat.lastMessage.sentAt | date: 'short'}}</span>
                </div>
              </div>
              <div class="w-full text-justify">
                <span class="body-b1 break-words" data-test="lastMessage">{{ chat.lastMessage.content | truncate: 300 }}</span>
              </div>
            </div>
            <div class="flex flex-col w-1/6 space-y-4 mt-1">
              <a (click)="checkLinkVisibility(chat.record.id, chat.id, true)" class="flex">
                <span class="link" data-test="chatRecordIdList">{{Helper.getShortId(chat.record.id)}}</span>
                <div *ngIf="chat.record.alias" class="link">
                  <span class="mx-2">|</span>
                  <span>{{chat.record.alias}}</span>
                </div>
              </a>

              <span *ngIf="chat.unread === 0" class="link grey">0 {{ 'chats.responses' | translate }}</span>
              <div *ngIf="chat.unread !== 0" routerLink="/record-detail/{{chat.record.id}}/messages/{{chat.id}}" class="uppercase">
                <app-chip *ngIf="chat.unread" [data]="chat.unread + ' ' + (chat.unread === 1 ? ('chats.response' | translate) : ('chats.responses' | translate))" [color]="'primary'" [additionalClasses]="'label'" data-test="chatResponses"></app-chip>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': chats.length}" class="mt-6 pb-10"></mat-paginator>
  </app-table-no-data>


</section>


