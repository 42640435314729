import { Component, OnInit } from '@angular/core';

import { User } from '../../../../../common/models/main';
import { CacheService } from '../../core/services/cache.service';


@Component({
  selector: 'app-chat-detail',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})

export class IframeComponent implements OnInit {
  user!: User;
  url!: string;

  constructor(private cache: CacheService) {
  }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.cache.getUser().subscribe((user) => this.user = user);
    if (this.user.organization?.integrations.powerBi.enabled) this.url = this.user.organization?.integrations.powerBi.url;
  }
}
