import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-warn',
  templateUrl: './button-warn.component.html',
  styleUrls: ['./button-warn.component.scss'],
})
export class ButtonWarnComponent {

  @Input() disabled = false;
  @Input() type: 'button' | 'submit' = 'button';
}
