import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[numeric]' })
export class NumericDirective {

  @Input() isNegativeAllowed = false; // Para permitir negativos
  @Input() set decimals(value: string | number) { this._decimals = Number(value); };
  _decimals: number = 0;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Control'].includes(event.key)) return;
    if (['v', 'c', 'x'].includes(event.key) && event.ctrlKey) return;
    if (this.isNegativeAllowed && event.key === '-' && !this.el.nativeElement.value) return;

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !this.check(next)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const paste = this.el.nativeElement.value + event.clipboardData?.getData('text');
    if (paste && !this.check(paste)) {
      event.preventDefault();
    }
  }

  private check(value: string) {
    if (this._decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      const regExpString = `^(-?\\d+(\\.\\d{0,${this._decimals}})?)$`;
      return String(value).match(new RegExp(regExpString));
    }
  }
}


/**
 * Cierra un select con autocompletado.
 * El MAT_AUTOCOMPLETE_SCROLL_STRATEGY no filtra su propio scroll y se cierra cuando el autocompletado tiene su propio scroll. Puta bida tete.
 * Contexto: Si una modal con scroll despliega un autocompletado con scroll y te desplazas sobre la modal el listado del select se queda fixed. Esto lo cierra.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[closeOnScroll]',
})
export class CloseAtScrollDirective {
  constructor(private autoComplete: MatAutocompleteTrigger) {
    window.addEventListener('scroll', this.scrollEvent.bind(this), true);
  }

  scrollEvent(event: any): void {
    // Cuando hay scroll y no es en el listado del autocompletado lo cerramos y quitamos el foco para que se despliegue cuando vuelva a recibir el foco
    if (!event.composedPath().find((e: any) => e.className?.includes('mat-mdc-autocomplete-panel')) && this.autoComplete.panelOpen) {
      this.autoComplete.closePanel(); // La otra alternativa es this.autoComplete.updatePosition(); pero se sale del la modal al estar en otra capa
      // @ts-ignore
      window.document.activeElement.blur();
    }
  };
}
