<section class="p-6 bg-img">
  <div *ngIf="organization" class="w-full">
    <div class="flex">
      <app-image-avatar (click)="editForm && openCropperModal()" [user]="user" [editMode]="editForm" [size]="'l'" class="mr-4" data-test="changeImage"></app-image-avatar>
      <div>
        <h3 class="overline-o2 secondary-text my-0" data-test="organizationDataTitle">{{'organizationData.title' | translate }}</h3>
        <div class="flex justify-items-start items-center">
          <h2 class="title-t1 primary-text mr-2">{{ organization.name }}</h2>
          <app-button-link *ngIf="!editForm" iconLeft="edit-2" (click)="editForm = !editForm" text="{{'organizationData.edit' | translate }}" [style]="'primary'" data-test="editProfileButton"></app-button-link>
        </div>
        <div class="body-b2 uppercase mb-4">{{ organization.legalId }}</div>
        <app-button-link (click)="openDigitalIdentityModal()" text="{{'digitalIdentity.button' | translate}}" [style]="'primary'" iconLeft="eye"></app-button-link>

        <div class="flex justify-start items-center mt-4">
          <div *ngIf="editForm || !form.get('public')?.value" class="flex items-center justify-start mr-4" [ngClass]="!form.get('public')?.value ? 'black-color' : 'grey-color'">
            <mat-icon svgIcon="lock"></mat-icon>
            <span class="label ml-1">{{'organizationData.private' | translate}}</span>
          </div>

          <div class="flex items-center space-x-3">
            <mat-slide-toggle *ngIf="editForm" [formControl]="form.get('public') | cast" color="primary" class="pr-2 link flex items-center" data-test="togglePrivatePublicProfile">
            </mat-slide-toggle>
            <div *ngIf="editForm || form.get('public')?.value" [ngClass]="form.get('public')?.value ? 'black-color' : 'grey-color'" class="flex items-center">
              <mat-icon svgIcon="earth"></mat-icon>
              <span class="label ml-2">{{'organizationData.publicProfile' | translate }}</span>
            </div>
            <mat-icon svgIcon="info" class="ml-2" [matTooltip]="'organizationData.public' | translate" color="primary"></mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center mt-4 w-full">
      <div *ngIf="!editForm" class="flex flex-col justify-start description primary-text body-b2 mr-10 w-1/2">
        <span *ngIf="organization.alias" class="my-2">{{organization.alias}}</span>
        <span *ngIf="organization.email" class="mb-2">{{organization.email}}</span>
        <span *ngIf="organization.phone" class="mb-2">{{organization.phone}}</span>
        <p class="inline-block mt-0 mb-2">
          <span *ngIf="organization.address?.street" class="comma">{{organization.address?.street}}</span>
          <span *ngIf="organization.address?.locality" class="comma"> {{organization.address?.locality}}</span>
        </p>
        <p *ngIf="organization.address?.subdivision || organization.address?.postalCode || organization.address?.country" class="inline-block mt-0 mb-2">
            <span *ngIf="organization.address?.subdivision || organization.address?.postalCode || organization.address?.country" class="comma trim">
              {{organization.address?.subdivision}}
              {{organization.address?.postalCode}}
              {{organization.address?.country}}
            </span>
          <span *ngIf="organization.address?.astronomicalBody && organization.address?.astronomicalBody !== ASTRONOMICAL_BODY.Earth" class="comma capitalize"> {{('select.astronomicalBody.' + organization.address?.astronomicalBody) | translate}}</span>
        </p>

        <div *ngIf="organization.customFields">
          <p *ngFor="let customField of organization.customFields" class="inline-block mt-0 mb-2">
            <span class="uppercase">{{"customField." + customField.type | translate}} </span>
            <span *ngIf="customField.name">{{customField.name}}</span>
            {{customField.value}}</p>
        </div>

        <hr *ngIf="showInfoLabel && showContactLabel" class="divider ml-0 w-1/2 mb-4">

        <span *ngIf="showContactLabel" class="overline-o3 my-2">{{'organizationData.contact.name' | translate}}</span>
        <span *ngIf="organization.contactName" class="mb-2">{{organization.contactName}}</span>
        <span *ngIf="organization.contactPhone" class="mb-2">{{organization.contactPhone}}</span>
        <span *ngIf="organization.contactEmail" class="mb-2">{{organization.contactEmail}}</span>
      </div>

      <div *ngIf="editForm" class="w-1/2 mt-4">
        <form [formGroup]="form" (ngSubmit)="saveOrganizationData()">
          <app-organization-data-form [conf]="{ form: form, onSubmit: onSubmit, data: organization, permissions: permission, requiredFields: ['name', 'legalId'], disabledFields: ['legalId'] }"></app-organization-data-form>
          <app-button-secondary [type]="'submit'" data-test="saveOrgDataChangesButton" class="mb-2">{{'organizationData.saveButton' | translate}}</app-button-secondary>
        </form>
      </div>
    </div>

    <div *ngIf="organization.integrations?.cargox?.available && !organization.integrations?.cargox?.enabled">
      <hr class="divider mb-8 -ml-6 w-1/2">
      <app-button-secondary (click)="redirectCargoX()" matIconPrefix class="my-6">
        <span class="flex items-center justify-center">
           <mat-icon svgIcon="go-to" color="primary" class="mr-1"></mat-icon>
          {{'organizationData.linkCargoX' | translate}}
        </span>
      </app-button-secondary>
      <hr class="divider mt-8 -ml-6 w-1/2">
    </div>

    <div *ngIf="organization.integrations?.cargox?.enabled && !editForm">
      <div class="flex items-center body-b2">
        <mat-icon svgIcon="circle-check" class="mr-1 dark-grey-text"></mat-icon>
        <p class="dark-grey-text">{{'organizationData.organizationLinkedWithCargoX' | translate}}</p>
      </div>
    </div>

  </div>
</section>
<hr class="divider my-3 ml-0 w-1/2">
