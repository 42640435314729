<div [ngClass]="notification.isRead ? 'read' : 'unread'" class="w-96">
  <div *ngIf="data" class="flex flex-col p-4" [ngClass]="{'message': isMessage}" data-test="notification">
    <div class="flex w-full justify-between" [ngClass]="{'pb-2': notification.params.recordId}">
      <span (click)="navigate(data, notification, data.recordUrl)" class="link" data-test="notificationRecordId">{{data.title}}</span>
      <mat-icon [svgIcon]="notification.isRead ? 'eye-off' : 'eye'" [matTooltip]="notification.isRead ? ('notifications.setAsUnread' | translate) : ('notifications.setAsRead' | translate)" (click)="markAsRead(notification)" data-test="toggleReadUnreadButton"></mat-icon>
    </div>
    <div class="flex space-x-2 cursor-pointer" (click)="navigate(data, notification, data.bodyUrl)">
      <app-avatar-circle [type]="avatarType" [data]="data.avatarData" [style]="avatarStyle"></app-avatar-circle>
      <div class="flex-col">
        <div *ngIf="data.messageTitle" class="overline-o2">{{data.messageTitle}}</div>
        <span>{{data.message| truncate: 120}}</span>
        <div class="overline-o4 date">
          <span>{{notification.createdAt | date: 'short'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
