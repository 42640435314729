import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { tz } from 'moment-timezone';
import { PERMACTION } from 'projects/common/models/main';

import { ApiService, CustomQuery } from '../../services/api.service';
import countries from './countries';
import currencies from './currencies';
import incoterms from './incoterms';
import translatableValues from './translatable-values';

type Type = 'country' | 'currency' | 'incoterm' | 'locodes' | 'timezone' | 'boxes' | 'other_containers' | 'air_containers' | 'trailer_types' | 'event-template' | 'documents';
type Option = {
  value: string;
  values?: string[];
  display: string;
  search: string;
  disabled?: boolean;
}
type EventFilter = {
  organizationId: string;
  resourceType: string;
}

@UntilDestroy()
@Component({
  selector: 'app-autocomplete-select[type][control]',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
})
export class AutocompleteSelectComponent implements OnInit {
  @Input() placeholder: string = this.translate.instant('input.select');
  @Input() label: string | null | undefined;
  @Input() type!: Type;
  @Input() multiple = false;
  @Input() allowAnyValue = false; // Permite que se introduzca cualquier valor en el select
  @Input() permissions: Record<string, PERMACTION[]> | undefined;
  @Input() set control(control: AbstractControl | null) {
    this._control = control as FormControl;
  }

  @Input() set param(param: string | undefined | null | EventFilter) {
    this.getValues(param);
  }

  @Input() labelCount: number = 1; // Cuantos elementos se ven en el caso de q sea multiple
  @ViewChild('searchInput') searchInput!: ElementRef;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  _control!: FormControl;
  options: Option[] = [];
  filteredOptions!: Option[];
  selectedValue: any = [];
  selectAllChecked = false;
  displayString = '';
  entity!: Entity;
  query!: CustomQuery;

  constructor(private api: ApiService, private translate: TranslateService) {
  }

  ngOnInit() {
    if (this.type === 'locodes') this.entity = new EntityLocodes();
    else if (this.type === 'currency') this.entity = new EntityCurrency();
    else if (this.type === 'incoterm') this.entity = new EntityIncoterm();
    else if (this.type === 'country') this.entity = new EntityCountry();
    else if (this.type === 'timezone') this.entity = new EntityTimezone();
    else if (this.type === 'event-template') this.entity = new EntityEventTemplate();
    else if (['boxes', 'other_containers', 'air_containers', 'trailer_types', 'documents'].includes(this.type)) this.entity = new EntityTranslatable(this.type, this.translate);

    this.startComponent();
  }

  getValues(param: string | undefined | null | EventFilter) {
    if (this.type === 'locodes') this.loadLocodes(param as string | undefined | null);
    else if (this.type === 'event-template') this.getEventsTemplates(param as EventFilter);
  }

  startComponent() {
    if (this.permissions) {
      this.options = this.entity.dataToArray().map(option => ({ ...option, disabled: !this.permissions?.[option.value]?.includes(PERMACTION.create) }));
    } else {
      this.options = this.entity.dataToArray();
    }
    this.filteredOptions = this.options;
    if (this._control.value) {
      this.selectedValue = this._control.value;
      // this.filterItem(this._control.value);
    }
    this.onDisplayString();
  }

  // Pone el foco en el campo de texto al abrir y setea el valor en el buscador si lo hubiese
  focusOnInput() {
    if (this._control.value) this.searchInput.nativeElement.value = this.options.find(e => e.value === this._control.value)?.display || this._control.value;
    this.searchInput.nativeElement.focus();
  }

  // Limpia el campo de busqueda al cerrar
  clearInput() {
    this.searchInput.nativeElement.value = '';
    this.onDisplayString();
    this.filterItem('');
  }

  toggleSelectAll(val: { checked: any; }) {
    if (val.checked) {
      this.filteredOptions.forEach(option => {
        if (!this.selectedValue.includes(option.value)) {
          this.selectedValue = this.selectedValue.concat([option.value]);
        }
      });
    } else {
      const filteredValues = this.getFilteredOptionsValues();
      this.selectedValue = this.selectedValue.filter((item: any) => !filteredValues.includes(item));
    }
    this.selectionChange.emit(this.selectedValue);
    this._control.setValue(this.selectedValue);
    this.onDisplayString();
  }

  filterItem(value: string) {
    this.filteredOptions = this.options.filter((item) => item.display.toLowerCase().indexOf(value.toLowerCase()) > -1);
    if (!this.multiple) return; // Si no hay multiples opciones no hay nada mas que actualizar
    this.selectAllChecked = true;
    this.filteredOptions.forEach(item => {
      if (!this.selectedValue.includes(item.value)) {
        this.selectAllChecked = false;
      }
    });
    if (!this.filteredOptions.length) {
      this.selectAllChecked = false;
    }
  }

  // Returns plain strings array of filtered values
  getFilteredOptionsValues() {
    const filteredValues: any[] = [];
    this.filteredOptions.forEach(option => {
      filteredValues.push(option.value);
    });
    return filteredValues;
  }

  onDisplayString() {
    this.displayString = '';
    if (this.selectedValue && this.selectedValue.length) {
      let displayOption: any[] = [];
      if (this.multiple) {
        // Multi select display
        for (let i = 0; i < this.labelCount; i++) {
          displayOption[i] = this.options.filter((option) => option.value === this.selectedValue[i])[0];
        }
        if (displayOption.length) {
          for (let i = 0; i < displayOption.length; i++) {
            if (displayOption[i] && displayOption[i].display) {
              this.displayString += displayOption[i].display + ',';
            }
          }
          this.displayString = this.displayString.slice(0, -1);
          if (
            this.selectedValue.length > 1 &&
            this.selectedValue.length > this.labelCount
          ) {
            this.displayString += ` (+${this.selectedValue.length -
            this.labelCount} others)`;
          }
        }
      } else {
        // Single select display
        displayOption = this.options.filter((option) => option.value === this.selectedValue);
        if (displayOption.length) this.displayString = displayOption[0].display;
        else this.displayString = this._control.value;
      }
    }
    return this.displayString;
  }

  onSelectionChange(val: { value: any; }) { // Puede ser un elemento o un array de elementos
    const filteredValues = this.getFilteredOptionsValues();
    let count = 0;
    if (this.multiple) {
      this.selectedValue.filter((item: any) => {
        if (filteredValues.includes(item)) {
          count++;
        }
      });
      this.selectAllChecked = count === this.filteredOptions.length;
    }
    this.selectedValue = val.value;
    this.selectionChange.emit(this.selectedValue);
    this.onDisplayString();
  }

  // Mostramos la opcion custom del usuario solo si no existe en ninguna de las otras
  findInFiltered(data: string, contains = true) {
    return contains ? this.filteredOptions.find(e => e.search.includes(data)) : this.filteredOptions.find(e => e.search === data);
  }

  public trackByFn(index: any, item: { value: any; }) {
    return item.value;
  }

  // Angular toma el espacio y el intro como confirmacion del select y no permite desactivarlo por conf.
  // https://stackoverflow.com/questions/71116038/angular-when-using-mat-select-is-there-a-way-to-not-select-the-focused-item-w
  // https://v5.material.angular.io/components/select/overview
  public witchcraft($event: KeyboardEvent) {
    if ($event.code === 'Space') $event.stopPropagation();
  }

  // el back soporta filtrado por texto (filter.autocomplete) pero de momento lo filtramos nosotros
  private getEventsTemplates(param: EventFilter | null) {
    this.query = { filter: { isActive: true, resourceType: param!.resourceType } };
    if (this._control) this._control.setValue(null, { emitEvent: false });
    if (param?.organizationId) {
      this.api.getEventTemplateList(param.organizationId, this.query).subscribe(res => {
        this.entity.data = res.data;
        this.startComponent();
      });
    } else {
      this.options = [];
    }
  }

  // la llamada a getLocodes se hace asincrona porque se resolverá a futuro
  // dado que el getter se ejecuta antes que el onInit y se inicializa el entity
  // necesitamos que la asignación del entity.data se haga a futuro, después que se haya ejecutado el onInit
  private loadLocodes(param: string | undefined | null) {
    if (this._control) this._control.setValue(null, { emitEvent: false });
    if (param) {
      this.api.getLocodes(param).subscribe(res => {
        this.entity.data = res;
        this.startComponent();
      });
    } else {
      this.options = [];
    }
  }
}

export abstract class Entity {
  data!: any[];

  dataToArray(): Option[] {
    return this.data.map(({ text, value, disabled }) => ({ value, display: value + '-' + text, search: (value + '-' + text).toUpperCase(), disabled: disabled }));
  }
}

export class EntityCountry extends Entity {
  data = countries;
}

export class EntityCurrency extends Entity {
  data = currencies;
}

export class EntityIncoterm extends Entity {
  data = incoterms;
}

export class EntityTranslatable extends Entity {
  constructor(type: 'boxes' | 'other_containers' | 'air_containers' | 'trailer_types' | 'documents', translate: TranslateService) {
    super();
    this.data = translatableValues[type].map(value => ({ value, display: translate.instant('autocompleteOptional.' + value) }));
  }

  dataToArray(): Option[] {
    return this.data.map(({ display, value }) => ({ value, display, search: display.toUpperCase() })).sort((a, b) => a.display.localeCompare(b.display));
  }
}

export class EntityLocodes extends Entity {

  dataToArray(): Option[] {
    if (!this.data) return [];
    return Object.entries(this.data as Record<string, any>).map(([code, { name, disabled }]) => ({ value: code, display: code + '-' + name, search: code.toUpperCase(), disabled }));
  }
}

export class EntityTimezone extends Entity {
  data = tz.names()
    .map(zone => ({ name: zone, offset: tz(zone).utcOffset(), display: `(GTM${tz(zone).format('Z')}) ${zone.replace(/_/g, ' ')}` }))
    .sort((a, b) => a.offset - b.offset);

  dataToArray(): Option[] {
    return this.data.map(({ name, display }) => ({ value: name, display, search: name.toUpperCase(), disabled: false }));
  }
}

export class EntityEventTemplate extends Entity {

  dataToArray(): Option[] {
    if (!this.data) return [];
    return this.data.map((e) => ({ value: e.id, display: e.name, search: e.name.toUpperCase() }));
  }
}
