import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { DEFAULT } from '../../models/constants';
import { Address } from '../../models/main';

@Component({
  selector: 'app-address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.scss'],
})
export class AddressDataComponent {

  _form!: FormGroup;
  @Input() set form(form: AbstractControl | null) {
    this._form = form as FormGroup;
  }

  constructor() {}

  static addressObjectFactory(address: Address) {
    return {
      astronomicalBody: address?.astronomicalBody || DEFAULT.ASTRONOMICAL_BODY,
      country: address?.country,
      subdivision: address?.subdivision,
      locality: address?.locality,
      street: address?.street,
      postalCode: address?.postalCode,
    };
  }
}
