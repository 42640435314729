import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DEFAULT } from '../../../../../../common/models/constants';
import { ActorReduced, Collaborator, OnSubmit, PERMACTION, User } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../../common/shared/custom-validators';
import { FormBase } from '../../../../../../common/shared/form-base';
import { CacheService } from '../../../core/services/cache.service';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-chat-invitations[form][chatId][actors][recordId][onSubmit][isInRecord]',
  templateUrl: './chat-invitations.component.html',
  styleUrls: ['./chat-invitations.component.scss'],
})
export class ChatInvitationsComponent extends FormBase implements OnInit, OnDestroy {
  @Input() form!: FormArray;
  @Input() chatId!: string;
  @Input() recordId!: string;
  @Input() actors: ActorReduced[] | undefined;
  @Input() onSubmit!: OnSubmit;
  @Input() isInRecord!: boolean;
  permissions!: Record<string, PERMACTION[]>;
  Helper = HelperService;
  user!: User;

  constructor(private fb: FormBuilder, private api: ApiService, private selfElement: ElementRef, private cache: CacheService) { super(); }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.form.insert(0, this.addCollaboratorInvitation());
    this.onSubmit.handlers.push({ cb: this.removeEmpty.bind(this, this.form), self: this.selfElement.nativeElement });
    this.onSubmit.validators.push({ cb: this.validate.bind(this, this.form), self: this.selfElement.nativeElement });
  }

  ngOnDestroy(): void {
    this.onSubmit.handlers = this.onSubmit.handlers.filter(handler => handler.self !== this.selfElement.nativeElement);
    this.onSubmit.validators = this.onSubmit.validators.filter(handler => handler.self !== this.selfElement.nativeElement);
  }

  addCollaboratorInvitation() {
    return ChatInvitationsComponent.collaboratorFormFactory({}, this.fb);
  }

  static collaboratorFormFactory(collaborator: Collaborator, fb: FormBuilder): FormGroup {
    return fb.group({
      email: [collaborator.email, [Validators.required, CustomValidators.validateEmail]],
      name: [collaborator.name || ''],
      locale: [collaborator.locale || DEFAULT.LOCALE],
      invitedToRecord: [collaborator.invitedToRecord || false, [Validators.required]],
      actorId: [collaborator.actorId, [Validators.required]],
    });
  }

  showWarn(form: FormGroup) {
    if (form.disabled) this.api.logWarn('toast.deleteCollaborators');
  }

  removeEmpty(form: FormArray) {
    for (let i = form.length; i > 0; i--) {
      if (!form.get([i - 1])?.get('email')?.value) form.removeAt(i - 1);
    }
  }

  validate(form: FormArray): boolean | void {
    const emailsDuplicated = form.getRawValue().map(e => e.email).filter((e, i, a) => a.indexOf(e) !== i);
    if (emailsDuplicated.length) return this.api.logError('toast.duplicatedEmails', true, { email: emailsDuplicated[0] });
    if (form.invalid) return this.api.logError('toast.formatEmails');
    return true;
  }
}
