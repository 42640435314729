import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HelperService } from 'projects/front/src/app/core/services/helper.service';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AutocompleteOrganization } from '../../models/main';
import { ApiService, CustomQuery } from '../../services/api.service';

@Component({
  selector: 'app-autocomplete-organization',
  templateUrl: './autocomplete-organization.component.html',
  styleUrls: ['./autocomplete-organization.component.scss'],
})
export class AutocompleteOrganizationComponent implements OnInit {
  Helper = HelperService;
  myControl = new FormControl();
  filteredOptions!: AutocompleteOrganization[];
  query: CustomQuery = { filter: { search: '' }, include: ['entity.record'] };
  @Input() actorSubtype?: string;
  @Input() actorId?: string;
  @Output() selectedOrganization = new EventEmitter();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.myControl.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(value => {
        if (this.actorSubtype) this.query.filter!.actorSubtype = this.actorSubtype;
        if (this.actorId) this.query.filter!.excludeActorId = this.actorId;
        this.query.filter!.search = value;
        return this.api.getAutocompleteList(this.query);
      }),
    ).subscribe((res: any) => {
      this.filteredOptions = res;
    });
  }

  emitAutocomplete(event: any) {
    this.selectedOrganization.emit(event.option.value);
  }

  displayFn(data: AutocompleteOrganization) {
    let result = '';
    if (data) {
      if (data.alias && data.name) result = data.alias + ' | ' + data.name;
      else if (data.alias) result = data.alias;
      else if (data.name) result = data.name;
    }
    return result;
  }
}
