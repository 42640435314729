<section class="dialog-container h-full relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l cursor-pointer absolute">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div mat-dialog-title class="overline-o1" data-test="eventTitle">
    {{eventName ? eventName : 'events.newEventModal.title' | translate}}
  </div>
  <hr class="divider">
  <form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
      <div class="content px-8 py-4">
        <div class="flex w-full space-x-4">
          <app-input [control]="form.get('name')" [label]="'events.newEventModal.name' | translate" class="w-1/2" data-test="eventName"></app-input>
          <app-input [control]="form.get('externalId')" [label]="'events.externalId' | translate" class="w-1/2" data-test="eventID"></app-input>
        </div>
        <div class="flex w-full space-x-4">
          <app-select [control]="form.get('system')" [label]="'events.system' | translate" [type]="'eventSystem'" class="w-1/2" data-test="eventSystemSelect"></app-select>
          <app-select [control]="form.get('class')" [label]="'events.class' | translate" [type]="'eventClass'" class="w-1/2" data-test="eventClassSelect"></app-select>
        </div>
        <div class="label mb-4 grid grid-cols-4 gap-2">
          <div class="col-span-3">{{'events.newEventModal.resources' | translate}}</div>
          <div class="pl-4">
            <mat-checkbox (change)="checkAllResources($event)" [checked]="allResourcesCheck" data-test="allResourcesCheck"><span class="pl-2">{{'events.all' | translate}}</span></mat-checkbox>
          </div>
        </div>
        <mat-selection-list #resourcesList>
          <div class="overline-o2 mb-4"><span>{{'events.newEventModal.actors' | translate}}</span></div>
          <div class="grid grid-cols-4 gap-1">
            <mat-list-option *ngFor="let actor of ACTORS" [value]="actor" [selected]="calculateCheck(actor,  'targetSubtypes')" (click)="allResourcesCheck = false" [checkboxPosition]="'before'" class="whitespace-nowrap" data-test="actorsList">{{('objects.' + actor) | translate}}</mat-list-option>
          </div>
          <div class="overline-o2 my-4"><span>{{'events.newEventModal.place' | translate}}</span></div>
          <div class="grid grid-cols-4 gap-1">
            <mat-list-option *ngFor="let place of PLACE_TYPE" [value]="place" [selected]="calculateCheck(place,  'targetSubtypes')" (click)="allResourcesCheck = false" checkboxPosition="before" class="whitespace-nowrap">{{('objects.' + place) | translate}}</mat-list-option>
          </div>
        </mat-selection-list>
        <div class="label my-5 grid grid-cols-4 gap-1">
          <div class="col-span-3">
            <div class="flex align-middle space-x-2">
              <div>{{'events.newEventModal.notification' | translate}}</div>
            </div>
          </div>
          <div class="pl-4">
            <mat-checkbox (change)="checkAllActors($event)" [checked]="allActorsCheck" data-test="allActorsCheck"><span class="pl-2">{{'events.all' | translate}}</span></mat-checkbox>
          </div>
        </div>
        <div>
          <mat-selection-list #actorsNotificationList>
            <div class="grid grid-cols-4 gap-1">
              <mat-list-option *ngFor="let actor of ACTORS" [value]="actor" [selected]="calculateCheck(actor,  'notifySubtypes')" (click)="allActorsCheck = false" [checkboxPosition]="'before'" class="whitespace-nowrap" data-test="actorsNotifyList">{{('objects.' + actor) | translate}}</mat-list-option>
            </div>
          </mat-selection-list>
        </div>
      </div>
    </mat-dialog-content>
    <hr class="divider">
    <mat-dialog-actions class="w-full flex">
      <app-button-secondary [type]="'submit'" data-test="saveEventTypeButton">{{'buttons.save' | translate}}</app-button-secondary>
    </mat-dialog-actions>
  </form>
</section>
