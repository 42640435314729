import { Component, Input } from '@angular/core';

type Groups = 'taxes' | 'pickUp' | 'delivery' | 'transport' | 'origin' | 'destination' | 'invoices' | 'resources';

@Component({
  selector: 'app-iterable-cell[arr][template][group]',
  templateUrl: './iterable-cell.component.html',
  styleUrls: ['./iterable-cell.component.scss'],
})
export class IterableCellComponent {

  @Input() arr!: any[];
  @Input() template!: any;
  @Input() limits!: Record<Groups, number>;
  @Input() group!: Groups;
  @Input() main = false;

  constructor() {
  }

  // El codigo es autoexplicativo PERO si te lo explican pues mejor.
  // Este componente recibe un elemento iterable. Si tiene mas de 1 elemento muestra el primero y un (+N) con el resto. En ese caso es clicable para desplegar el resto
  // Para que sea reutilizable recibe un template con como debe mostrarse cada elemento. A ese template se le inyecta el objeto iterado con el nombre de "item"
}
