import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';


@Component({
  selector: 'app-sortable-select[control][availableOptions]',
  templateUrl: './sortable-select.component.html',
  styleUrls: ['./sortable-select.component.scss'],
})
export class SortableSelectComponent {
  @Input() placeholder: string = '';
  @Input() i18nPath: string = '';
  @Input() availableOptions!: string[];

  @Input() set control(value: AbstractControl | null) {
    this._control = value as FormControl;
  }

  _control!: FormControl;

  constructor() {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.availableOptions, event.previousIndex, event.currentIndex);

    // creamos un objeto con par item - orden en el array
    const referenceOrder: Record<string, number> = {};
    this.availableOptions.forEach((item, index) => {
      referenceOrder[item] = index;
    });

    // ordenamos los valores en el control para que el padre los pueda replicar,
    this._control.value.sort((a: number, b: number) => referenceOrder[a] - referenceOrder[b]);
    this._control.setValue(this._control.value || []);
  }

}
