import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';

import { NOTIFICATION_TYPES, NotificationCard } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { EventService } from '../../../core/services/event.service';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-notification-card[notification]',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notification!: NotificationCard;
  @Output() notificationChange = new EventEmitter();
  isMessage: boolean = false;
  avatarType: 'image' | 'initials' | 'icon' | 'iconInitials' = 'icon';
  avatarStyle: 'notification' | 'notification-warn' = 'notification';

  data: { title: string, avatarData: string, message: string, recordUrl: string, bodyUrl: string, queryParams: Record<string, any>, messageTitle: string } = { title: '', avatarData: '', message: '', recordUrl: '', bodyUrl: '', queryParams: {}, messageTitle: '' };

  constructor(private api: ApiService, private eventService: EventService, private translate: TranslateService, private router: Router) {
  }

  ngOnInit() {
    this.startCard(this.notification);
  }

  startCard(notification: NotificationCard) {
    if (notification.params.recordId) {
      const notificationCardId = HelperService.getShortId(notification.params.recordId);
      this.data.title = notification.params.recordReference ? `${notificationCardId} | ${notification.params.recordReference}` : notificationCardId;
    }
    this.data.message = notification.text;
    this.avatarStyle = 'notification';
    if (notification.params.recordId) this.data.recordUrl = `/record-detail/${notification.params.recordId}/canvas`;
    switch (notification.name) {
      case NOTIFICATION_TYPES.RecordInviteCollaborator:
      case NOTIFICATION_TYPES.RecordInviteOrganization:
        this.data.avatarData = 'invite';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/canvas`;
        break;
      case NOTIFICATION_TYPES.RecordUploadNewDocumentVersion:
        this.data.avatarData = 'folder';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/documents`;
        this.data.queryParams = {
          documentId: notification.params.documentId,
          versionId: notification.params.versionId,
        };
        break;
      case NOTIFICATION_TYPES.RecordUpdateDocument:
        this.data.avatarData = 'folder';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/documents`;
        this.data.queryParams = {
          documentId: notification.params.documentId,
          versionId: notification.params.versionId,
        };
        break;
      case NOTIFICATION_TYPES.RecordUploadNewDocument:
        this.data.avatarData = 'folder';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/documents`;
        this.data.queryParams = {
          documentId: notification.params.documentId,
          versionId: notification.params.versionId,
        };
        break;
      case NOTIFICATION_TYPES.RecordUpdateActor:
        this.data.avatarData = 'user';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/actors/${notification.params.actorSubtype}/${notification.params.actorId}/data`;
        break;
      case NOTIFICATION_TYPES.RecordUpdateInvoices:
        this.data.avatarData = 'file-text';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/canvas`;
        break;
      case NOTIFICATION_TYPES.RecordUpdatePlace:
        this.data.avatarData = 'location-pin';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/places/${notification.params.placeSubtype}/${notification.params.placeId}/data`;
        break;
      case NOTIFICATION_TYPES.ChatInvite:
        this.data.avatarData = 'message-new';
        if (notification.params.recordId && notification.params.chatId) this.data.bodyUrl = `/record-detail/${notification.params.recordId}/messages/${notification.params.chatId}`;
        else if (notification.params.chatId) this.data.bodyUrl = `/messages/${notification.params.chatId}`;
        break;
      case NOTIFICATION_TYPES.ChatNewMessage:
        this.data.avatarData = 'messages';
        if (notification.params.recordId && notification.params.chatId) this.data.bodyUrl = `/record-detail/${notification.params.recordId}/messages/${notification.params.chatId}`;
        else if (notification.params.chatId) this.data.bodyUrl = `/messages/${notification.params.chatId}`;
        this.isMessage = true;
        if (notification.params.userName) {
          if (notification.params.organizationName) this.data.messageTitle = notification.params.userName + ' | ' + notification.params.organizationName;
          else this.data.messageTitle = notification.params.userName;
        } else if (notification.params.organizationName) {
          this.data.messageTitle = notification.params.organizationName;
        } else {
          this.data.messageTitle = notification.params.userMail;
        }
        break;
      case NOTIFICATION_TYPES.ChatExclude:
        this.data.avatarData = 'messages-out';
        this.data.bodyUrl = '/messages';
        this.avatarStyle = 'notification-warn';
        break;
      case NOTIFICATION_TYPES.NewOrganizationMember:
        this.avatarType = 'iconInitials';
        if (notification.params.userName) this.data.avatarData = notification.params.userName;
        this.data.bodyUrl = '/profile/organization';
        break;
      // cargox
      case NOTIFICATION_TYPES.AcidNumberAdded:
        this.data.avatarData = 'cargox';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}/actors/taxes/${notification.params.actorId}`;
        break;
      case NOTIFICATION_TYPES.AcidNumberNoTaxesActor:
      case NOTIFICATION_TYPES.AcidNumberMultipleTaxesActors:
        this.data.avatarData = 'cargox';
        this.avatarStyle = 'notification-warn';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}`;
        break;
      case NOTIFICATION_TYPES.AcidNumberMultipleRecords:
      case NOTIFICATION_TYPES.AcidNumberNoRecord:
        this.data.avatarData = 'cargox';
        this.avatarStyle = 'notification-warn';
        this.data.bodyUrl = '/record-list';
        break;
      case NOTIFICATION_TYPES.RecordEventCustomRegularCreated:
      case NOTIFICATION_TYPES.RecordEventCustomIssueOpen:
      case NOTIFICATION_TYPES.RecordEventCustomIssueReopen:
      case NOTIFICATION_TYPES.RecordEventCustomIssueClosed:
        this.data.avatarData = 'calendar';
        this.data.bodyUrl = `/record-detail/${notification.params.recordId}`;
        break;
    }
  }

  markAsRead(notification: NotificationCard) {
    notification.isRead = !notification.isRead;
    this.notificationChange.emit(notification);
    this.api.markNotificationAsRead(notification.id, notification.isRead).subscribe(noop);
  }

  async navigate(configuration: Record<string, any>, notification: NotificationCard, url: string) {
    if (!notification.isRead) this.markAsRead(notification);
    if (url) await this.router.navigate([url], { queryParams: configuration.queryParams });
  }
}
