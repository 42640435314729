<div class="rounded-xl flex justify-between items-center mt-2 p-6 bg-grey-100 shadow-standard" [ngClass]="{'disabled': control.disabled}">
  <mat-panel-title class="flex items-start w-full">
    <app-avatar-circle *ngIf="icon" [type]="'icon'" size="m" [data]="icon" [style]="control.disabled ? 'disabled' : 'primary'" class="mr-4" [disabled]="control.disabled"></app-avatar-circle>
    <div class="w-full">
      <div class="flex justify-between">
        <mat-slide-toggle [formControl]="control" labelPosition="before">
          <span class="title-t2 mr-2">{{label}}</span>
        </mat-slide-toggle>
        <mat-icon *ngIf="!control.disabled && isExpandable" (click)="toggleExpansionPanel()" [svgIcon]="expansion.expanded ? 'chevron-up' : 'chevron-down'" color="primary" class="mx-1"></mat-icon>
      </div>
      <div *ngIf="description" class="mt-4 body-b2">{{description}}</div>
    </div>
  </mat-panel-title>
</div>

<mat-expansion-panel [expanded]="panelExpanded" #expansion class="bg-grey-100">
  <ng-content></ng-content>
</mat-expansion-panel>

