import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom, noop } from 'rxjs';

import { ACTORS, OnSubmit, Organization, PERMACTION, PLACE_TYPE } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { EventService } from '../../../core/services/event.service';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-favorites-modal',
  templateUrl: './favorites-modal.component.html',
  styleUrls: ['./favorites-modal.component.scss'],
})
export class FavoritesModalComponent implements OnInit {
  form!: FormGroup;
  organization: Organization = {} as Organization;
  resourceSubtype: ACTORS | PLACE_TYPE | null = null;

  onSubmit: OnSubmit = { validators: [], handlers: [] };
  permission: PERMACTION[] = [PERMACTION.read, PERMACTION.update];

  protected readonly ACTORS = ACTORS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { favoriteId?: string },
    private api: ApiService,
    private fb: FormBuilder,
    private location: Location,
    private dialogRef: MatDialogRef<FavoritesModalComponent>,
    private eventService: EventService,
  ) { }

  ngOnInit(): void {
    this.startComponent(this.data.favoriteId).then(noop);
  }

  async startComponent(favoriteId?: string) {
    if (favoriteId) {
      const res = await firstValueFrom(this.api.getFavoriteDetail(favoriteId));
      this.organization = res.data;
      this.resourceSubtype = res.resourceSubtype;
    }
    this.form = this.fb.group({});
  }

  saveFavorite() {
    const aliasControl = this.form.get('alias')!;
    aliasControl.addValidators(Validators.required);
    aliasControl.updateValueAndValidity();

    if (HelperService.isValidatorsFailing(this.onSubmit)) return;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    const favorite = this.form.getRawValue();
    if (favorite.alias === '') favorite.alias = null;
    const data = { subtype: 'actor', name: favorite.alias, data: favorite, resourceSubtype: this.resourceSubtype };

    const observable = this.data.favoriteId ? this.api.editFavorite(this.data.favoriteId, data) : this.api.createFavorite(data);
    observable.subscribe(() => {
      this.api.log('favorites.toast.saved');
      this.api.logWarn('favorites.toast.warn');
      this.eventService.changeFavorites.emit();
      this.close();
    });
  }

  close() {
    this.location.replaceState('/favorites');
    this.dialogRef.close();
  }

  deleteFavorite(favoriteId: string) {
    this.api.deleteFavorite(favoriteId).subscribe(() => {
      this.api.log('favorites.toast.deleted');
      this.eventService.changeFavorites.emit();
      this.close();
    });
  }
}
