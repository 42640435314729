<div *ngIf="matrix && rows.length && columns.length" class="h-content-matrix">
  <div class="body-b2 table-container h-full">
    <table>
      <thead>
      <tr>
        <th></th>
        <th *ngFor="let column of columns">
          <div class="flex flex-col matrix-table__header">
            <div *ngIf="!conf.onlyOneResource" [class.th-border]="!column.displayName" class=" flex flex-col my-6 text-left">
              {{('objects.' + column.subtype) | translate}}
              <span *ngIf="column.displayName" class="th-border mt-2">{{column.displayName}}</span>
            </div>
            <div class="flex justify-start">
                <span *ngFor="let action of actions" class="mr-4">
                  <mat-icon [svgIcon]="iconsAvailable[view][action]" [matTooltip]="('matrix.tooltips.' + action) | translate"></mat-icon>
                </span>
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of rows">
        <th>
            <span class="ml-2" [ngClass]="{'places': places.includes(row.subtype), 'documents': documents.includes(row.subtype) || row.subtype === RECORD_DETAILS}">
              {{('objects.' + row.subtype) | translate}}
            </span>
          <span *ngIf="row.displayName" [ngClass]="{'places': places.includes(row.subtype), 'documents': documents.includes(row.subtype) || row.subtype === RECORD_DETAILS}" class="ml-2">{{row.displayName | truncate: 20}}</span>
        </th>
        <td *ngFor="let column of columns">
            <span *ngFor="let action of actions" class="checkbox">
              <input [id]="id+action+getIdOrSubType(column)+getIdOrSubType(row)" type="checkbox" class="checkbox__input" [checked]="matrix[getIdOrSubType(column)][getIdOrSubType(row)].includes(action)" (change)="changeValue(getIdOrSubType(row), getIdOrSubType(column), action,  $event)" [disabled]="action===PERMACTION.read && row.id && row.id === column.id">
              <label [for]="id+action+getIdOrSubType(column)+getIdOrSubType(row)" class="checkbox__label" [ngClass]="{disabled: action===PERMACTION.read && row.id && row.id === column.id}"></label>
            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="border-top h-7 flex justify-end items-center bg-grey-50 p-4 overflow-hidden">
  <app-button-link (click)="restoreMatrix()" text="{{'buttons.restore' | translate }}" [style]="'primary'" iconLeft="redo" [disabled]="!conf.canSave" class="mr-4" data-test="undoChanges"></app-button-link>
  <app-button-secondary (click)="saveMatrix()" [type]="'submit'" [disabled]="!conf.canSave" class="mr-8" data-test="savePermissionsButton">{{'buttons.save' | translate}}</app-button-secondary>
</div>

<div *ngIf="!rows.length || !columns.length" class="h-96 flex flex-col items-center justify-center">
  <img src="../../assets/img/icon-usyncro_green-200.png" alt="{{'matrix.noPermissions' | translate}}" class="opacity-30 mt-12">
  <span *ngIf="view !== 'documents'" class="mt-12">{{'matrix.noPermissions' | translate}}</span>
  <span *ngIf="view === 'documents'" class="mt-12">{{'matrix.noDocuments' | translate}}</span>
  <span *ngIf="view !== 'documents'">{{'matrix.addElements' | translate}}</span>
</div>
