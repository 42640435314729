<main class="error-404 flex flex-col justify-start items-center p-6">
  <div class="mb-20 mt-10">
    <img src="/assets/img/logo.png" alt="Usyncro">
  </div>

  <div class="flex flex-col text-center">
    <h1 class="text-5xl font-bold mb-8">{{ ('error404.' + title) | translate }} </h1>
    <p class="mb-8 body-b1">{{ ('error404.' + message) | translate }}</p>

    <a routerLink="/" *ngIf="return">
      <app-button-secondary class="mat-flat-button--black">{{ 'error404.back' | translate }}</app-button-secondary>
    </a>
  </div>
</main>
