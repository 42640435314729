import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ApiService } from '../../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../../common/shared/custom-validators';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})

export class PasswordRecoveryComponent {

  recoveryForm: FormGroup = this.createForm();

  constructor(public dialogRef: MatDialogRef<PasswordRecoveryComponent>, private formBuilder: FormBuilder, private api: ApiService, private router: Router) { }

  createForm(): FormGroup {
    return this.formBuilder.group({
      email: [null, [Validators.required, CustomValidators.validateEmail]],
    });
  }

  sendRequest() {
    if (this.recoveryForm.invalid) {
      this.recoveryForm.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const data = this.recoveryForm?.getRawValue();
    data.email = data.email?.trim();
    this.api.recoverPassword(data).subscribe(() => {
      this.api.log('toast.recoveryEmail', true);
      this.close();
    });
  }

  close() {
    this.dialogRef.close();
  }
}
