import { ElementRef, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { Matrix } from './administration';
import { ResourceGoods, TransportData } from './transport';

export enum INVITATION_STATUS { accepted = 'accepted', pending = 'pending', rejected = 'rejected'}

// A efectos es un objeto, puedes iterar los locales con Object.values(LOCALES)
export enum LOCALES { US = 'en-US', ES = 'es-ES', CL = 'es-CL', MX = 'es-MX' }

export enum USER_TYPE { USERS = 'users', ADMINS = 'admins' }

// tipos de codigo de identificación de organizaciones
export enum COMPANY_ID_TYPE { EORI = 'eori', NIF = 'nif', TIN = 'tin', RUT = 'rut' }

// matriz permisos
export enum PERMACTION { create = 'c', read = 'r', update = 'u', invite = 'i', delete = 'd', compliance = 'n', subscribe = 's', execute = 'x', file = 'f', event = 'e'}

// En el back los llaman alreves. PERMACTION el enumerado de la matriz de permisos
export enum PERMISSION { create = '@:c', read = '@:r', update = '@:u', updateStatus = 'status:u', readInvoice = 'invoices:r', updateInvoice = 'invoices:u'}

export enum ACTORS {
  Addressee = 'addressee', Authority = 'authority', Buyer = 'buyer', Consignee = 'consignee', Consignor = 'consignor', ExternalService = 'externalService',
  ForwarderDestination = 'fwd', ForwarderOrigin = 'fwo', InspectionService = 'inspectionService', Manager = 'manager', Notify = 'notify', Supplier = 'supplier', Taxes = 'taxes',
  Transporter = 'transporter', Watcher = 'watcher', Custom = 'custom', Warehouse = 'warehouse', CustomsBroker = 'customsBroker', Vendor = 'vendor'
}

export enum PLACE_TYPE { PickUp = 'pickup', Origin = 'origin', Destination = 'destination', Delivery = 'delivery' }

// Para poder usarse desde los tests
export const RESOURCES_BY_MENU = {
  MENU_1: [ACTORS.Supplier, ACTORS.ForwarderOrigin, ACTORS.Consignor, PLACE_TYPE.PickUp, PLACE_TYPE.Origin, ACTORS.Vendor],
  MENU_2: [PLACE_TYPE.Destination, PLACE_TYPE.Delivery, ACTORS.ForwarderDestination, ACTORS.Notify, ACTORS.Consignee, ACTORS.Addressee, ACTORS.Buyer],
  MENU_3: [ACTORS.Transporter, ACTORS.ExternalService, ACTORS.InspectionService, ACTORS.Taxes, ACTORS.Warehouse, ACTORS.Custom, ACTORS.CustomsBroker, ACTORS.Manager, ACTORS.Watcher, ACTORS.Authority],
};

export const RECORD_DETAILS = 'invoices';

export const ACTOR_CREATOR = 'creator';

export type DocumentKeys = keyof typeof DOCUMENTS;

export enum DOCUMENTS {
  AdditionalInstructions = 'additionalInstructions',
  AmericanAccount = 'americanAccount',
  AnalysisCertificate = 'analysisCertificate',
  AttachedDocumentWarehouseExit = 'attachedDocumentWarehouseExit',
  AttachedDocumentWarehouseReceipt = 'attachedDocumentWarehouseReceipt',
  AuthenticityCertificate = 'authenticityCertificate',
  AuthorityPermission = 'authorityPermission',
  BlRelease = 'blRelease',
  Cmr = 'cmr',
  CompleteCustomLegalFile = 'completeCustomLegalFile',
  ContainerDelayDebtDocument = 'containerDelayDebtDocument',
  CustomCrossingDocument = 'customCrossingDocument',
  CustomsAuthorization = 'customsAuthorization',
  CustomsDocumentCalculationSheet = 'customsDocumentCalculationSheet',
  CustomsDocumentMerchandiseValue = 'customsDocumentMerchandiseValue',
  CustomsRequirement = 'customsRequirement',
  DeliveryNote = 'deliveryNote',
  DestinationExpensesInvoice = 'destinationExpensesInvoice',
  DocumentByCustomAuthority = 'documentByCustomAuthority',
  EdocEbl = 'edocEbl',
  EdocEcmr = 'edocEcmr',
  EndClientInvoice = 'endClientInvoice',
  EndClientVerification = 'endClientVerification',
  EquipmentInterchangeReceipt = 'equipmentInterchangeReceipt',
  Eur1Certificate = 'eur1Certificate',
  EvidenceOfDeliveryDocument = 'evidenceOfDeliveryDocument',
  ExportCustomClearanceSimplifiedVersion = 'exportCustomClearanceSimplifiedVersion',
  ExportQuotation = 'exportQuotation',
  ExportRelease = 'exportRelease',
  ExportSad = 'exportSad',
  Form = 'form',
  FreeTradeAgreement = 'freeTradeAgreement',
  GoodsPhotos = 'goodsPhoto',
  GuideNumber = 'guideNumber',
  Hawb = 'hawb',
  Hbl = 'hbl',
  Hcmr = 'hcmr',
  ImoDeclaration = 'imoDeclaration',
  ImportCustomClearanceSimplifiedVersion = 'importCustomClearanceSimplifiedVersion',
  ImportQuotation = 'importQuotation',
  ImportRelease = 'importRelease',
  ImportSad = 'importSad',
  Insurance = 'insurance',
  InsuranceDocuments = 'insuranceDocument',
  Invoice = 'invoice',
  LetterOfCredit = 'letterOfCredit',
  LiberationLetter = 'liberationLetter',
  LoiLetter = 'loiLetter',
  Mawb = 'mawb',
  Mbl = 'mbl',
  MerchandiseReviewDocumentCountryOrigin = 'merchandiseReviewDocumentCountryOrigin',
  OpeningCard = 'openingCard',
  OriginCertificate = 'originCertificate',
  OriginExpensesInvoice = 'originExpensesInvoice',
  Others = 'others',
  PackingList = 'packingList',
  PaymentLetter = 'paymentLetter',
  PaymentReceipt = 'paymentReceipt',
  Proforma = 'proforma',
  PurchaseQuote = 'purchaseQuote',
  QualityCertificate = 'qualityCertificate',
  ResultOfTheReviewOfCustomsAgentGoodsInCustoms = 'resultOfTheReviewOfCustomsAgentGoodsInCustoms',
  Sad = 'sad',
  TaxPayment = 'taxPayment',
  TransferPayment = 'transferPayment',
  TransportDocument = 'transportDocument',
  UtilityBillUSD = 'utilityBillUSD',
  Vgm = 'vgm',
  WarehouseExit = 'warehouseExit',
  WarehouseReceipt = 'warehouseReceipt',
  WeightCertificate = 'weightCertificate',
  Arc = 'arc',
  HalalCertificate = 'halalCertificate',
  HealthCertificate = 'healthCertificate',
  MaterialSafetyDataSheet = 'materialSafetyDataSheet',
  CertificateOfInsurance = 'certificateOfInsurance',
  BillOfMaterials = 'billOfMaterials',
  CertificateOfFumigation = 'certificateOfFumigation',
  CoverLetter = 'coverLetter',
  PhytosanitaryCertificate = 'phytosanitaryCertificate',
  VeterinaryCertificate = 'veterinaryCertificate',
  CreditNoteOrigin = 'creditNoteOrigin',
  CreditNoteDestination = 'creditNoteDestination',
  SWIFT = 'SWIFT',
  Booking = 'booking',
  DepartureConfirmationSailing = 'departureConfirmationSailing',
  ArrivalNotice = 'arrivalNotice',
  CNTAddressConfirmation = 'CNTAddressConfirmation',
  CNTReturnLetter = 'CNTReturnLetter',
  WithdrawalOrderAtOrigin = 'withdrawalOrderAtOrigin',
  WithdrawalOrderToDestination = 'withdrawalOrderToDestination',
  SenderShippersWaybillDestination = 'senderShippersWaybillDestination',
  CarrierShippersWaybillDestination = 'carrierShippersWaybillDestination',
  Sli = 'sli',
  ProformaCustomsBrokerInvoice = 'proformaCustomsBrokerInvoice',
  Bl = 'bl',
  AuthorithyCertificate = 'authorityCertificate',
  ShippingCutoff = 'shippingCutoff',
  PreliminarCommercialQuotation = 'preliminarCommercialQuotation',
  DigitalFile = 'digitalFile',
  LegalFile = 'legalFile',
  ShippingInvoice = 'shippingInvoice',
  SimplifiedSad = 'simplifiedSad',
  PreliminaryResult = 'preliminaryResult',
}

export const AI_ALLOWED_DOCS = [DOCUMENTS.Invoice, DOCUMENTS.OriginExpensesInvoice, DOCUMENTS.DestinationExpensesInvoice, DOCUMENTS.EndClientInvoice, DOCUMENTS.TransportDocument, DOCUMENTS.Cmr, DOCUMENTS.DeliveryNote, DOCUMENTS.PackingList];

export enum RECORD_PLACE_LOCATION { UnLocodeLocation = 'locode', GlnLocation = 'gln', AddressLocation = 'address' }

export enum RELEASE_TYPES { Usyncro = 'usyncro', Original = 'original', Telex = 'telex', Express = 'express' }

export enum TRANSPORT_TYPES { None = 'none', Air = 'air', Maritim = 'maritim', Road = 'road', Railway = 'railway', River = 'river', Interplanetary = 'interplanetary'}

export enum FUEL_TYPES_AIR {
  SAF = 'saf',
  NonSAF = 'nonSaf',
  Others = 'others'
}

export enum FUEL_TYPES_MARITIM {
  DieselGasOil = 'dieselGasOil',
  LightFuelOil = 'lightFuelOil',
  HeavyFuelOil = 'heavyFuelOil',
  LPG = 'lpg',
  LNG = 'lng',
  Others = 'others'
}

export enum FUEL_TYPES_ROAD {
  Electric = 'electric',
  Gasoline = 'gasoline',
  Diesel = 'diesel',
  Bioethanol = 'bioethanol',
  Biodiesel = 'biodiesel',
  Hydrogen = 'hydrogen',
  Others = 'others'
}

export enum FUEL_TYPES_RAILWAY {
  DieselGasOil = 'dieselGasOil',
  Electric = 'electric',
  DieselElectric = 'dieselElectric',
  NaturalGas = 'naturalGas',
  Others = 'others'
}

export enum FUEL_TYPES_RIVER {
  DieselGasOil = 'dieselGasOil',
  LightFuelOil = 'lightFuelOil',
  HeavyFuelOil = 'heavyFuelOil',
  LPG = 'lpg',
  LNG = 'lng',
  Others = 'others'
}

export enum FUEL_TYPES_INTERPLANETARY {
  Hidrolox = 'hidrolox',
  Methane = 'methane',
  Methalox = 'methalox',
  Hypergolics = 'hypergolics',
  Kerolox = 'kerolox',
  Others = 'others'
}

export enum DISTANCE { nmi = 'nmi', mi = 'mi', km = 'km', yd = 'yd', m = 'm' }

export enum WEIGHTS { kg = 'kg', lb = 'lb'}

export enum LENGTHS { cm = 'cm', in = 'in'}

export enum TEMPERATURES { K = 'K', C = 'degC', F = 'degF' }

export enum VOLUMES {'l' = 'l', 'm3' = 'm3', 'gal' = 'gal'}

export enum ASTRONOMICAL_BODY {
  Earth = 'earth',
  ISS = 'iss',
  Moon = 'moon',
  Mercury = 'mercury',
  Venus = 'venus',
  Mars = 'mars',
  Jupiter = 'jupiter',
  Saturn = 'saturn',
  Uranus = 'uranus',
  Neptune = 'neptune'
}

export enum TERMINAL_TYPE { LCT = 'lct', TIMSA = 'timsa', EIT = 'eit', TILH = 'tilh', ICAVE = 'icave'}

export enum TERMINAL_COMPANY { SmartPortMx = 'smartPortMx'}

export enum PAYMENT_METHODS { LetterOfCredit = 'letterOfCredit', transfer = 'transfer', Days30 = 'days30', Days60 = 'days60', Days90 = 'days90', other = 'other' }

export enum PACKAGES { box = 'box', pallet = 'pallet', other = 'other' }

export enum RESOURCE_TYPE { Actor = 'actors', Place = 'places'}

export enum ROLES { Creator = 'records:actors:creator' }

export enum NOTIFICATION_CHANNEL { Bell = 'bell', Email = 'email', Browser = 'browser', Webhook = 'webhook' }

export enum NOTIFICATION_TIMES { Automatic = '0', Minutes15 = '15', Hours1 = '60', Hours4 = '240', Days1 = '1440', Days7 = '10080', Customized = 'customized' }

export enum NOTIFICATION_TYPES {
  RecordInviteOrganization = 'recordInviteOrganization',
  RecordInviteCollaborator = 'recordInviteCollaborator',
  RecordUploadNewDocument = 'recordUploadNewDocument',
  RecordUploadNewDocumentVersion = 'recordUploadNewDocumentVersion',
  RecordUpdateDocument = 'recordUpdateDocument',
  RecordUpdateInvoices = 'recordUpdateInvoices',
  RecordUpdateActor = 'recordUpdateActor',
  RecordUpdatePlace = 'recordUpdatePlace',
  ChatInvite = 'chatInvite',
  ChatNewMessage = 'chatNewMessage',
  ChatExclude = 'chatExclude',
  NewOrganizationMember = 'newOrganizationMember',
  RecordEventCustomRegularCreated = 'recordEventCustomRegularCreated',
  RecordEventCustomIssueOpen = 'recordEventCustomIssueOpen',
  RecordEventCustomIssueReopen = 'recordEventCustomIssueReopen',
  RecordEventCustomIssueClosed = 'recordEventCustomIssueClosed',
  AcidNumberAdded = 'acidNumberAdded',
  AcidNumberNoRecord = 'acidNumberNoRecord',
  AcidNumberNoTaxesActor = 'acidNumberNoTaxesActor',
  AcidNumberMultipleRecords = 'acidNumberMultipleRecords',
  AcidNumberMultipleTaxesActors = 'acidNumberMultipleTaxesActors'
}

export enum NOTIFICATION_MANAGER_DAYS { Monday = '1', Tuesday = '2', Wednesday = '3', Thursday = '4', Friday = '5', Saturday = '6', Sunday = '7' }

export enum TRAILER_TYPES {
  SEMI_TRAILER = 'semi_trailer',
  TARPAULIN = 'tarpaulin',
  REFRIGERATED = 'refrigerated',
  CAPITONNE = 'capitonne',
  GONDOLA = 'gondola',
  PLATFORM = 'platform',
  TANKER = 'tanker',
  CAR_CARRIER = 'car_carrier',
  CONTAINER_CARRIER = 'container_carrier'
}

export enum MONTHS {
  January = 'jan',
  February = 'feb',
  March = 'mar',
  April = 'apr',
  May = 'may',
  June = 'jun',
  July = 'jul',
  August = 'aug',
  September = 'sept',
  October = 'oct',
  November = 'nov',
  December = 'dec'
};

export enum CUSTOMS_CLEARANCE_CODES { Green = 'green', Yellow = 'yellow', Orange = 'orange', Red = 'red', NA = 'na'}

export enum PLANS {
  Default = 'default',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Semiannual = 'semiannual',
  Annual = 'annual',
  Promosub = 'promosub',
  Customspromo = 'customspromo',
  Enterprise = 'enterprise',
  Collaborator = 'collaborator'
}

export enum EVENT_CLASS { Input = 'input', Output = 'output' }

export enum EVENT_SYSTEM { Magaya = 'magaya', CargoWise = 'cargoWise', Soia = 'soia', SmartportMexico = 'smartportMexico', Manual = 'manual', }

export type Theme = 'light' | 'dark'

export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export interface GenericWrapper<T> {
  data: GenericElement<T>,
  included: Array<GenericElement<any>>;
}

export interface GenericWrapperArray<T> {
  data: Array<GenericElement<T>>,
  included: Array<GenericElement<any>>;
  meta: {
    total: number;
  }
}

export interface GenericElement<T> {
  id: string;
  type: string;
  attributes: T
  meta: any;
}

export interface PaginatedWrapper<T> {
  data: T[];
  total: number;
}

export interface UserWrapper {
  user: User,
  organization?: OrganizationExtended,
}

export interface LoginWrapper extends UserWrapper {
  token: string;
  validationCode?: string; // Para el bypass del onboarding
}

export interface LoginData {
  password: string,
  email: string,
  type: string
}

export interface User {
  status: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  emailValidatedAt: Date;
  isAdmin: boolean,
  isManager: boolean;
  id: string;
  locale: LOCALES;
  organization?: OrganizationExtended;
  legalTerms: {
    acceptedHash: string;
    acceptedAt: Date;
  }[];
  notifications: UserNotification;
  external: {
    segment: string;
  }
  apiKey?: {
    expiresAt?: string;
  }
  view: Record<VIEWS_TYPE, string>,
  type: USER_TYPE;
}

export interface UserNotification {
  aggregateTime: number;
  alertTime: number;
  pushSubscription: any;
  settings: Record<NOTIFICATION_TYPES, Array<NOTIFICATION_CHANNEL>>;
  webhook?: string;
}

export interface Organization {
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  name: string;
  displayName: string;
  email: string;
  alias: string | null;
  address?: Address;
  public: boolean,
  legalId: string;
  phone: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  id: string;
  customFields: CustomField[];
  subscription?: Subscription;
  logo?: string;
}

// Para manejar datos de pago o settings usamos esta. Para manejar cosas mas sencillas como favoritos Organization
export interface OrganizationExtended extends Organization {
  settings: ManagerSettings;
  billing: BillingData;
  subscription: Subscription;
  integrations: IntegrationsData;
  blockchain: Record<string, BlockChainTransaction>;
}

export interface BillingData {
  name?: string;
  address?: Address;
  email?: string;
  taxId?: string;
}

export interface IntegrationsData {
  cargox: {
    available: boolean
    enabled?: boolean
  },
  iaDataExtraction: {
    enabled: boolean
  },
  edocs: {
    enabled: boolean
  },
  powerBi: {
    enabled: boolean
    url: string
  }
}

export interface CargoXDocumentsData {
  documentTokenId: string;
  hash: string;
  signature: null | string | undefined;
}

export interface ManagerSettings {
  addCreatorAsManager?: boolean,
  customRecordEventsTemplates?: EventTemplate[],
  sendInactiveRecordNotifications?: boolean,
  inactiveRecordNotificationWeekday?: number,
  inactiveRecordNotificationTime?: string,
  inactiveRecordNotificationTimezone?: string,
}

export class CustomField {
  name?: string;
  value: string;
  type: string;

  constructor(data?: any) {
    this.value = data?.value || '';
    this.type = data?.type || '';
    if (data?.name !== undefined) this.name = data?.name;
  }
}

export interface ActorReduced extends Organization {
  subtype: ACTORS | typeof ACTOR_CREATOR;
  collaborators: Collaborator[];
  recordReference: string;
  perms: PERMISSION[];
}

export interface CreatorActor extends ActorReduced {
  organization: Organization;
}

export interface Actor extends ActorReduced {
  displayName: string;
  invite: boolean;
  welcomeMessage?: string;
  meta?: any;
}

export interface TaxesData extends ResourceGoods {
  customsNumber: string | null;
  customsClearance: string | null;
  customsClearanceDate: Date | null;
  notes?: string
}

export interface TaxesActor extends Actor {
  taxesData: TaxesData;
}

export interface WarehouseData extends ResourceGoods {
  exportAuthorizedWarehouse: { date?: Date | null, code: string },
  notes?: string
}

export interface ActorWarehouse extends Actor {
  warehouseData: WarehouseData
}

export interface Collaborator {
  name?: string;
  email?: string;
  locale?: LOCALES;
  user?: { id: string, type: string, name: string }; // Siempre hay
  invitedToRecord?: boolean;
  actorId?: string;
}

export interface RecordInfo extends Omit<RecordEntity, 'organization'> {
  organization: Organization,
  ownReferences: string[],
}

export interface Place extends ResourceGoods {
  type: string;
  subtype?: string;
  name?: string;
  displayName?: string;
  transportType?: string;
  alias?: string;
  boxes?: string;
  issues?: string;
  location?: GlnAddress | PlacesAddress | LocodeAddress;
  id: string;
  organization: string;
  record: string;
  notes: string;
}

export interface Address {
  astronomicalBody: ASTRONOMICAL_BODY;
  country: string;
  subdivision: string;
  locality: string;
  street: string;
  postalCode: string;
  phone?: string;
  notes?: string;
}

export interface PlacesAddress extends Address {
  type?: RECORD_PLACE_LOCATION.AddressLocation;
  terminalCompany: TERMINAL_COMPANY;
  terminal: TERMINAL_TYPE;
}

export interface LocodeAddress {
  type: RECORD_PLACE_LOCATION.UnLocodeLocation;
  country: string;
  locode: string;
  terminalCompany: TERMINAL_COMPANY;
  terminal: TERMINAL_TYPE;
}

export interface GlnAddress {
  type: RECORD_PLACE_LOCATION.GlnLocation;
  country: string;
  gln: string;
  terminalCompany: TERMINAL_COMPANY;
  terminal: TERMINAL_TYPE;
}

export interface Transport extends Actor {
  transportData: TransportData;
}

export interface InvoicePackageGroup {
  hsCode?: string;
  tariffValue?: string;
  description?: string;
  originCountry?: string;
  weight?: MeasureWithUnit;
  boxes?: string;
  value?: Amount;
  unitValue?: Amount;
  units?: null | number;
  discount?: null | number;
  sku?: string;
}

// Esto es un mix de cosas que tiene el transporte. Deberia cambiar al transporte completo cuando este la feature completa
export interface DocumentAITransporter extends ResourceGoods {
  truckPlate: string,
  trailerPlate: string,
  deliveryNote: string,
  name: string,
  arrivalEstimated: FullDate,
  fuelType: null | FUEL_TYPES_ROAD
  distance: { value: number, unit: DISTANCE }
  baseEmissionsPerDistanceUnit: null | number
  estimatedEmissionsPerDistanceUnit: null | number
  actualEmissionsPerDistanceUnit: null | number
}

export interface DocumentAIData {
  buyer: Actor,
  supplier: Actor,
  transporter: DocumentAITransporter
  images: string[]
}

export interface FullDate {
  date?: Date;
  time?: string;
  timeZone?: string;
}

export interface MeasureWithUnit {
  value?: string;
  unit?: LENGTHS | WEIGHTS | TEMPERATURES | VOLUMES | DISTANCE;
}

export interface RecordInvoice {
  id?: string;
  number?: string;
  date?: string;
  createdBy?: string;
  providerReference?: string;
  referencePlace?: string;
  value?: Amount;
  paymentMethod?: {
    type?: string;
    typeText?: string;
    bankWarrantyEnding?: string;
  }
  tariffHeadings?: InvoicePackageGroup[]
}

interface Position2D {
  x: number;
  y: number;
}

export interface Amount {
  amount?: string;
  currency?: string;
}

interface EntityIdentifier {
  id: string;
  type: string;
}

export interface RecordCanvasNode {
  id: string;
  type: RESOURCE_TYPE;
  subtype: ACTORS | PLACE_TYPE;
  resource: EntityIdentifier;
  position: Position2D;
  displayName: string;
  connections: string[];
}

export interface RecordEntity {
  id: string;
  alias: string; // WTF?
  creatorReference?: string;
  transportTypes: TRANSPORT_TYPES[];
  createdAt: Date,
  updatedAt: Date,
  organization: Organization,
  perms: PERMISSION[],
  roles: string[],
  status: 'archived' | 'active'
}

export interface DashboardEntity {
  id: string;
  alias: string;
  creatorReference?: string;
  transportTypes: TRANSPORT_TYPES[];
  createdAt: string;
  updatedAt: string;
  organization: Organization;
  perms: PERMISSION[];
  roles: string[];
  status: 'archived' | 'active';
  recordPickupData: any[];
  limits: Record<string, number>;
}

export interface RecordDetails {
  weight?: MeasureWithUnit,
  transportIncoterms: Array<{ type: TRANSPORT_TYPES, incoterms: Array<string> }>,
  taxesIncoterms: string[],
  invoicesIncoterms?: string[],
  invoicesValues?: Array<Amount>,
}

export interface GenericModalData {
  title?: string;
  content?: string;
  template?: TemplateRef<any>;
  buttons: {
    label: string;
    value: any;
    type: 'button' | 'link';
    icon?: string;
  }[];
}

export interface Template {
  name: string;
  id: string;
  organization?: string;
}

export interface RecordDocument {
  id: string;
  alias?: string;
  displayName: string;
  description?: string;
  subtype: DOCUMENTS;
  createdAt: string;
  versions: DocumentVersion[];
}

export interface DocumentVersion {
  id: string;
  fileName: string;
  hash: string;
  createdAt: string;
  createdBy: User;
  blockchain: Record<string, BlockChainTransaction>;
  mimeType?: string;
  size?: number;
}

export interface BlockChainTransaction {
  status: string;
  transactionLink: string;
  transactionHash: string;
  network: string;
  // En docs se usan upload, en eventos se usan update y en digital identities registration
  upload?: { id: string }; // Esto es lo que tiene antes de hacer el populate.
  update?: { id: string }; // Esto es lo que tiene antes de hacer el populate.
  registration?: { id: string }; // Esto es lo que tiene antes de hacer el populate.
}

export interface ModalParams {
  recordId: string;
  type: RESOURCE_TYPE;
  subtype: string;
  resourceId: string;
  section: string;
  permissions: PERMACTION[];
}

export interface OnSubmit {
  validators: { self: ElementRef, cb: () => boolean | void }[];
  handlers: { self: ElementRef, cb: () => void }[];
}

export interface Participant {
  id?: string,
  name: string,
  email: string,
  locale?: string,
  organization?: Organization;
}

export interface Message {
  senderUser: User,
  content: string,
  sentAt: Date,
}

export interface Chat {
  id: string,
  participants: Participant[],
  title: string,
  createdAt?: Date,
  updatedAt: Date,
  deletedAt?: Date,
  sentAt?: Date,
  record: RecordEntity,
  totalMessagesCount: number,
  lastMessage: Message,
  owner: Participant,
  isVisible?: boolean,
  muted: boolean,
  read: number,
  unread: number,
}

export interface ConfirmedChatParticipant extends Collaborator {
  isInvited: boolean,
  id?: string,
}

export interface PossibleChatParticipant extends ConfirmedChatParticipant {
  roles: Set<ACTORS | typeof ACTOR_CREATOR>,
  disabled?: boolean,
  actors: Set<string>,
}

export interface ConfigForm<T> {
  form: FormGroup;
  data: T;
  onSubmit: OnSubmit;
  permissions: PERMACTION[];
  key?: string;
  requiredFields?: string[];
  disabledFields?: string[];
}

export interface NotificationCard {
  id: string,
  name: NOTIFICATION_TYPES,
  params: Record<string, any>,
  isRead: boolean,
  createdAt: Date,
  user: { id: string },
  type: string;
  text: string,
}

export interface Favorite {
  createdAt: Date;
  createdBy: Record<string, any>;
  data: Organization;
  id: string;
  name: string;
  alias: string;
  organization: Record<string, any>;
  subtype: string;
  resourceSubtype: ACTORS | PLACE_TYPE | null;
  type: string;
  updatedAt: Date;
}

export interface AutocompleteOrganization extends Organization {
  autocompleteType: string,
  autocompleteRecord: { id: string, alias: string },
}

export interface Usage {
  periodEndAt: Date;
  periodMonth: string;
  periodStartAt: Date;
  isLive: boolean;
  month: string;
  year: string;
  products: {
    records: { period: string; total: string };
    members: { period: string; total: string };
    storage: { period: string; total: string };
  }
}

export interface CanSaveMatrix {
  saveMatrix: (Matrix: Matrix) => void;
}

export interface Subscription {
  plan: {
    id: string,
    type?: 'plans'
  }
  monthlyPrice: Amount,
  products: {
    records: ProductSubscription,
    members: ProductSubscription,
    storage: ProductSubscription,
  }
}

interface ProductSubscription {
  included: string | null,
  extraPrice: Amount,
  limit: string | null,
}

export interface Plan extends Subscription {
  id: string;
  key: string,
}

export interface LimitMessage {
  text: string;
  usage: string;
  included: string | null;
  progress: number;
  step: 'in-limit' | 'over-included' | 'warning' | 'regular',
  i18n: {
    product: string;
    products: string;
  }
}

export interface EventTemplate {
  id: string,
  name: string,
  externalId: string,
  system?: EVENT_SYSTEM,
  class?: EVENT_CLASS,
  isActive: boolean,
  isUsedInIntegrations?: boolean,
  areNotifActive: boolean,
  targetSubtypes: string[],
  notifySubtypes: string[],
}

export interface EventCard {
  id: string;
  entity: {
    type: 'record-actors' | 'record-places',
    id: string,
    subtype: string,
  };
  customData?: {
    name: string,
    templateId: string;
    date: {
      plainDate: string;
      plainTime: string;
      timeZone: string;
    }
    subtype: 'issue' | 'regular';
    system: EVENT_SYSTEM,
    containers?: string;
    notes?: string;
    status: 'open' | 'closed';
  };
  status?: 'active' | 'archived' | 'deleted';
  createdBy?: {
    id: string;
    type: '$root' | 'users'
    name?: string;
    organization?: Organization;
  };
  record: {
    id: string,
    type: 'records'
  }
  createdAt?: string;
  blockchain?: Record<string, BlockChainTransaction>;
  timeline: TimelineEvent[];
  subtype: 'creation' | 'custom';
  permissions?: PERMISSION[];
  metadata?: EventMetadata
}

export interface EventMetadata {
  smartPortMxEvent?: {
    containerDetails: {
      Data: [Record<string, string>]
    }
  }
  soiaEvent?: Record<string, string>
}

export interface TimelineEvent {
  event: string;
  instant: string;
  params: {
    customIssueStatus?: 'open' | 'closed';
    status?: 'active' | 'archived';
  };
  subject: {
    organization?: Organization;
    name: string;
    email: string;
  };
  blockchain: Record<string, BlockChainTransaction>;
}

export enum VIEWS_TYPE { MAIN = 'main', TAXES = 'taxes', TRANSPORT = 'transports', EVENTS = 'events' }

export enum EVENT_STATUS { CLOSED = 'closed', OPEN = 'open', ARCHIVED = 'archived' }


export interface ValidatedEdoc {
  organization: OrganizationExtended;
  edoc: {
    api: string,
    createdAt: string,
    fileName: string,
    hash: string,
    id: string,
    issuedAt: string,
  };
  reason: string;
  valid: boolean;
}
