<form *ngIf="notificationConfigurationForm" [formGroup]="notificationConfigurationForm" (ngSubmit)="save()">
  <div class="flex space-x-4 pl-4">
    <app-select [control]="notificationConfigurationForm.get('aggregateTime')" [label]="'notifications.label' | translate" [type]="'notificationTime'" data-test="aggregateTimeSelect"></app-select>
    <div class="flex items-center" *ngIf="notificationConfigurationForm.get('aggregateTime')?.value === NOTIFICATION_TIMES.Customized">
      <app-input [control]="notificationConfigurationForm.get('customised')" [type]="'time'"></app-input>
      <span class="ml-4">h</span>
    </div>
  </div>
  <hr class="divider m-0 w-1/2">
  <div class="pb-5 px-5">
    <div class="mb-2 mt-4 body-b2"><span>{{'notifications.tableTitle' | translate}}</span></div>
    <div *ngIf="matrix && rows.length && columns.length">
      <div class="flex">
        <table class="configuration-table w-2/3 shadow-dark border">
          <thead>
          <tr class="table-head actions-a3">
            <th></th>
            <th *ngFor="let column of columns">
              <div *ngIf="column !== 'browser'" class=" flex text-left py-3 mx-6">
                {{('notifications.' + column) | translate}}
              </div>
              <div *ngIf="column === 'browser'" class="flex text-left py-3 mx-6">
                {{('notifications.' + column) | translate}}
                <mat-icon *ngIf="!browserPermissions" svgIcon="info" [matTooltip]="'notifications.tooltip' | translate" class="pl-1"></mat-icon>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of rows">
            <th class="body-b2 bottom py-4 px-6 text-left">
              <span>
                {{('notifications.type.' + row) | translate}}
              </span>
            </th>
            <td *ngFor="let column of columns; let i = index" class="bottom text-center">
              <mat-checkbox [checked]="matrix[row].includes(column)" (change)="changeValue(row, column, i, $event)" class="mx-6"></mat-checkbox>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="py-6 w-full">
      <div class="flex flex-col w-1/6">
        <mat-label class="label">{{'notifications.webhook' | translate}}</mat-label>
        <app-input [control]="notificationConfigurationForm.get('webhook')" [placeholder]="'Webhook'" [type]="'text'" tabindex="1" data-test="webhookInput"></app-input>
      </div>
      <div class="mt-2 w-1/3">
        <p class="mb-1 body-b2 secondary-text">{{'notifications.no-developers' | translate}}</p>
      </div>
    </div>
    <app-button-secondary [type]="'submit'" data-test="saveNotificationsButton">{{'buttons.save' | translate}}</app-button-secondary>
  </div>
</form>
