import { Location } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { MainHelperService } from '../../../../../common/shared/main-helper.service';

@Injectable({ providedIn: 'root' })
export class HelperService extends MainHelperService {

  constructor(protected translate: TranslateService,
              protected fb: FormBuilder,
              protected location: Location,
              protected router: Router,
              @Inject(LOCALE_ID) protected locale: string) {
    super(translate, fb, location, router, locale);
  }
}
