import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { noop } from 'rxjs';

import { ApiService } from '../../../../../common/services/api.service';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { CacheService } from '../../core/services/cache.service';
import { EnvService } from '../../core/services/env.service';

@UntilDestroy()
@Component({
  selector: 'app-login-guest',
  templateUrl: './login-guest.component.html',
  styleUrls: ['./login-guest.component.scss'],
})

export class LoginGuestComponent implements OnInit {

  return: string = '/';

  constructor(private env: EnvService, private fb: FormBuilder, private router: Router, private api: ApiService, public dialog: MatDialog, private ar: ActivatedRoute, private reCaptchaV3Service: ReCaptchaV3Service, private cache: CacheService) { };

  ngOnInit(): void {
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.token && params.return) {
        this.return = params.return;
        this.sendLogin(params.token).then(noop);
      }
    });
  }

  async sendLogin(token: string) {
    const captchaResponse = this.env.captchaKey ? await this.reCaptchaV3Service.executeAsPromise(this.env.captchaKey, 'loginGuest', { useGlobalDomain: true }) : '';
    this.api.loginGuest(token, captchaResponse).subscribe(res => {
      this.cache.setUser(res.user);
      MainEventService.login.emit(res);
      this.router.navigateByUrl(this.return);
    });
  }

}
