import { AbstractControl, FormArray } from '@angular/forms';

/**
 * Clase base para heredar en aquellas con formularios dinamicos. Te proporciona los metodos de añadir y borrar.
 *
 * Desde la vista añade el metodo builder a tu funcion para que te devuelva AbstractControls. Eje: (click)="addFormElement(form, builder(createNewElement))"
 */
export class FormBase {
  deleteFormElement(form: AbstractControl | null, index: number): void {
    const array = form as FormArray;
    array.removeAt(index);
  }

  addFormElement(form: AbstractControl | null, field: AbstractControl): void {
    const array = form as FormArray;
    array.push(field);
  }

  builder(func: Function): AbstractControl {
    return func.call(this);
  }
}
