import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox-list[elements][form]',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent {
  @Input() elements!: Array<{ id: string, text: string }>;
  @Input() form!: FormControl;
  @Input() i18nPath!: string;
  @Input() icon = '';


  isChecked(id: string): boolean {
    return this.form.value.includes(id);
  }

  onCheckboxChange(event: MatCheckboxChange, id: string) {
    const { checked } = event;
    const ids = new Set(this.form.getRawValue());
    if (checked) ids.add(id);
    else ids.delete(id);

    this.form.setValue(Array.from(ids));
  }

  selectAll() {
    if (this.form.value.length !== this.elements.length) {
      const allElements = this.elements.map(element => element.id);
      this.form.setValue(allElements);
    } else {
      this.form.setValue([]);
    }
  }
}
