// Ponemos esto como constante por si hay que cambiarlo, si hay algún sitio mejor...
import { Amount, ASTRONOMICAL_BODY, DISTANCE, LENGTHS, LOCALES, MeasureWithUnit, TEMPERATURES, VOLUMES, WEIGHTS } from './main';

export const INFO_EMAIL = 'info@usyncro.com';
export const CONTACT_EMAIL = 'digitalizatulogistica@usyncro.com';

export const INFO_PHONE = '+34 653 63 48 48';
export const URL_TRACKING = 'https://connect.track-trace.com/for/usyncro/';

export const TERMS_AND_CONDITIONS_HASH = 'gAvP6bjSESbptjTgVbXEwrhJ2aajXXeIZSRnkfsRjiA|6lE9F8hGIwAOEuJNYkl7X0aU2J3PRYDN0AhzgRYQTXc|a9YjLb0-0JBiQB9r248N3ySZ3JZPrezU64dqJ39uudY|lYYnJQzdJ4AjXKs81y-uhfD0EdzECXfwgqpbzkEfzLw';

export const DEFAULT = {
  WEIGHT_UNIT: WEIGHTS.kg,
  DISTANCE_UNIT: DISTANCE.km,
  LENGTHS_UNIT: LENGTHS.cm,
  TEMPERATURE_UNIT: TEMPERATURES.C,
  VOLUME_UNIT: VOLUMES.l,
  WEIGHT: (data: MeasureWithUnit = {}): MeasureWithUnit => ({ value: data?.value, unit: data?.unit || DEFAULT.WEIGHT_UNIT }),
  DISTANCE: (data: MeasureWithUnit = {}): MeasureWithUnit => ({ value: data?.value, unit: data?.unit || DEFAULT.DISTANCE_UNIT }),
  LENGTHS: (data: MeasureWithUnit = {}): MeasureWithUnit => ({ value: data?.value, unit: data?.unit || DEFAULT.LENGTHS_UNIT }),
  TEMPERATURE: (data: MeasureWithUnit = {}): MeasureWithUnit => ({ value: data?.value, unit: data?.unit || DEFAULT.TEMPERATURE_UNIT }),
  VOLUME: (data: MeasureWithUnit = {}): MeasureWithUnit => ({ value: data?.value, unit: data?.unit || DEFAULT.VOLUME_UNIT }),
  AMOUNT: (data: Amount = {}): Amount => ({ amount: data?.amount, currency: data?.currency || DEFAULT.CURRENCY }),
  LOCALE: LOCALES.ES,
  TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  CURRENCY: 'EUR',
  FORMAT_NUMBER: '1.2-2',
  ASTRONOMICAL_BODY: ASTRONOMICAL_BODY.Earth,
  PAGE_SIZE_OPTIONS: [10, 25, 100],
};

export const MODAL_WIDTH = { width: '62vw', minWidth: 650, maxWidth: 900 };

export const MODAL_WIDTH_SMALL = { width: '45vw', minWidth: 650, maxWidth: 700 };

export const MODAL_WIDTH_XS = { width: '35vw', minWidth: 420, maxWidth: 500 };

export const MAX_VERSIONS = 10;
