<div class="card w-64 mt-2 shadow-standard">
  <div class="flex justify-center py-4 overline-o2 font-grey">{{title}}</div>
  <div class="flex justify-center">
    <app-avatar-circle [type]="'icon'" [data]="icon" [color]="'primary'"></app-avatar-circle>
    <div class="pt-1 ml-2 title-t1 font-green" data-test="value">{{value | number: '1.0-2' : 'en'}} {{unit}}</div>
  </div>
  <div *ngIf="type!=='recordsMonth'" class="flex justify-center py-4 font-grey caption-c1">{{'usageCard.from' | translate}} {{date | date: 'shortDate'}}</div>
  <div *ngIf="type==='recordsMonth'" class="flex justify-center py-4 font-grey caption-c1">
    {{'usageCard.from' | translate}} {{data.periodStartAt| date: 'shortDate'}} {{'usageCard.to' | translate}} {{data.periodEndAt| date: 'shortDate'}}</div>
</div>
