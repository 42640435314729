import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import get from 'lodash-es/get';

import { CropperImageModalComponent } from '../../../../../../../common/components/cropper-image-modal/cropper-image-modal.component';
import { MODAL_WIDTH } from '../../../../../../../common/models/constants';
import { ASTRONOMICAL_BODY, COMPANY_ID_TYPE, OnSubmit, OrganizationExtended, PERMACTION, User } from '../../../../../../../common/models/main';
import { ApiService } from '../../../../../../../common/services/api.service';
import { CacheService } from '../../../../core/services/cache.service';
import { EventService } from '../../../../core/services/event.service';
import { HelperService } from '../../../../core/services/helper.service';
import { DigitalIdentityModalComponent } from '../digital-identity-modal/digital-identity-modal.component';

@Component({
  selector: 'app-organization-data',
  templateUrl: './organization-data.component.html',
  styleUrls: ['./organization-data.component.scss'],
})

export class OrganizationDataComponent implements OnInit {
  ASTRONOMICAL_BODY = ASTRONOMICAL_BODY;
  onSubmit: OnSubmit = { validators: [], handlers: [] };
  types = Object.keys(COMPANY_ID_TYPE);
  user!: User;
  organization!: OrganizationExtended;
  form!: FormGroup;
  permission: PERMACTION[] = [PERMACTION.read, PERMACTION.update];
  editForm: boolean = false;
  showContactLabel = false;
  showInfoLabel = false;

  constructor(private fb: FormBuilder, private api: ApiService, private cache: CacheService, private dialog: MatDialog, private eventService: EventService) {
  }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.organization = this.user.organization as OrganizationExtended;
    this.startComponent();
  }

  startComponent() {
    this.form = this.fb.group({ public: this.organization.public });

    this.showContactLabel = !!(this.organization.contactName || this.organization.contactEmail || this.organization.contactPhone);
    this.showInfoLabel = !!(this.organization.alias || this.organization.phone || this.organization.customFields.length > 0);
    const optionalFields = ['alias', 'location.astronomicalBody', 'location.country', 'location.subdivision', 'location.locality', 'location.address', 'location.postalCode', 'location.organizationContact.email', 'location.organizationContact.phone', 'location.humanContact.name', 'location.humanContact.email', 'location.humanContact.phone'];
    // Recorre los campos que no se piden en el onboarding para ver si alguno ha sido completado. En funcion de ello, mostrara la vista de edicion o no.
    for (const field of optionalFields) {
      if (get(this.organization, field)) this.editForm = false;
    }
  }

  saveOrganizationData() {
    const nameControl = this.form.get('name')!;
    nameControl.addValidators(Validators.required);
    nameControl.updateValueAndValidity();

    if (HelperService.isValidatorsFailing(this.onSubmit)) return;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const data = this.form.getRawValue();

    if (data.contactEmail) data.contactEmail = data.contactEmail.trim();
    if (data.email) data.email = data.email?.trim();
    if (data.alias === '') data.alias = null;

    this.api.editOrganizationData(data, this.organization.id).subscribe((res: OrganizationExtended) => {
      this.api.log('toast.saved');
      this.organization = res;
      this.user.organization = res;
      this.cache.setUser(this.user);
      this.editForm = false;
      this.startComponent();
    });
  }

  openCropperModal() {
    this.dialog.open(CropperImageModalComponent, { ...MODAL_WIDTH }).afterClosed().subscribe(value => {
      if (value) {
        this.api.uploadOrganizationLogo(value, this.organization.id).subscribe(() => {
          this.api.log('toast.saved');
          this.editForm = false;
          window.location.reload();
        });
      }
    });
  }

  redirectCargoX() {
    return this.api.getCargoXUrl().subscribe((res) => {
      window.location.href = res.registrationUrl;
    });
  }

  openDigitalIdentityModal() {
    this.dialog.open(DigitalIdentityModalComponent, { width: '650px', disableClose: true });
  }
}
