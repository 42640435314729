import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent {
  @Input() iconLeft?: string;
  @Input() iconRight?: string;
  @Input() disabled = false;
  @Input() text?: string;
  @Input() style: 'primary' | 'warn' = 'primary';
  @Input() optionalClasses = '';
}
