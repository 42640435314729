<footer class="footer fixed flex flex-col justify-between items-center w-screen bottom-0 z-10 text-xs lg:text-sm sm:flex-row">
  <div class="flex pl-4">
    <p class="caption-c2 footer__copyright uppercase primary-text">{{'footer.copyright' | translate}} {{currentYear}}</p>
    <p class="caption-c2 text-disabled pl-1">· V{{version}}</p>
  </div>
  <div class="footer__contact grid grid-cols-2 md:flex-row">
    <a class="link footer-item no-underline" href="https://usyncro.com" target="_blank">usyncro.com</a>
    <a class="link footer-item no-underline -ml-2" href="mailto:{{contactEmail}}" target="_blank">{{contactEmail}}</a>
  </div>

  <div class="footer__links flex flex-col mb-2 md:flex-row md:mb-0">
    <a [href]="'assets/documents/'+lang+'/terms-and-conditions.pdf'" target="_blank" id="footer-terms-and-conditions" class="link footer-item no-underline">{{'footer.terms' | translate}}</a>
    <a [href]="'assets/documents/'+lang+'/privacy.pdf'" target="_blank" id="footer-privacy-policy" class="link footer-item no-underline">{{'footer.privacy' | translate}}</a>
    <div class="grid grid-cols-2">
      <a [href]="'assets/documents/'+lang+'/legal-advice.pdf'" target="_blank" id="footer-legal-advice" class="link footer-item no-underline">{{'footer.legal' | translate}}</a>
      <a [href]="'assets/documents/'+lang+'/cookies.pdf'" target="_blank" id="footer-cookies" class="link footer-item no-underline -ml-8">Cookies</a>
    </div>
  </div>
</footer>
