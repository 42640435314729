import { Component, EventEmitter, Input, Output } from '@angular/core';

import { URL_TRACKING } from '../../../models/constants';
import { TRANSPORT_TYPES } from '../../../models/main';


@Component({
  selector: 'app-nested-n2[title]',
  templateUrl: './nested-n2.component.html',
  styleUrls: ['./nested-n2.component.scss'],
})
export class NestedN2Component {

  @Input() disabled = false;
  @Input() title!: string;
  @Input() white = false;
  @Output() delete = new EventEmitter<void>();
  @Input() margin = false;
  @Input() controlId!: string;
  @Input() transportType!: string;

  TRANSPORT_TYPES = TRANSPORT_TYPES;
  URL_TRACKING = URL_TRACKING;

  constructor() { }

}
