import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';

import { ACTORS, EVENT_CLASS, EVENT_SYSTEM, EventTemplate, PLACE_TYPE, User } from '../../../../../../../common/models/main';
import { ApiService } from '../../../../../../../common/services/api.service';
import { FormBase } from '../../../../../../../common/shared/form-base';

@Component({
  selector: 'app-new-event-type',
  templateUrl: './new-event-type.component.html',
  styleUrls: ['./new-event-type.component.scss'],
})
export class NewEventTypeComponent extends FormBase implements OnInit {
  @ViewChild('actorsNotificationList') actorsNotificationList!: MatSelectionList;
  @ViewChild('resourcesList') resourcesList!: MatSelectionList;
  @Input() user!: User;
  form = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    externalId: ['', [Validators.required]],
    system: new FormControl<EVENT_SYSTEM | undefined>(undefined, { validators: Validators.required, nonNullable: true }),
    class: new FormControl<EVENT_CLASS | undefined>(undefined, { validators: Validators.required, nonNullable: true }),
    targetSubtypes: new FormArray<any>([]),
    notifySubtypes: new FormArray<any>([]),
    isActive: true,
    areNotifActive: true,
  });

  event!: EventTemplate;
  ACTORS = Object.values(ACTORS);
  PLACE_TYPE = Object.values(PLACE_TYPE);
  eventName = ''; // Para que no se vaya actualizando cuando cambie el valor del form
  allResourcesCheck = false;
  allActorsCheck = false;
  constructor(
    private fb: NonNullableFormBuilder,
    private api: ApiService,
    private dialogRef: MatDialogRef<NewEventTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User, eventId: string | undefined },
  ) { super(); }

  ngOnInit(): void {
    this.user = this.data.user;
    if (this.data.eventId) {
      this.api.getEventTemplate(this.user.organization!.id, this.data.eventId).subscribe((res: EventTemplate) => {
        this.eventName = res.name;
        this.form.patchValue(res);
        res.targetSubtypes.forEach(e => this.form.controls.targetSubtypes.push(new FormControl(e)));
        res.notifySubtypes.forEach(e => this.form.controls.notifySubtypes.push(new FormControl(e)));
      });
    }
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const data = this.form.getRawValue();
    data.targetSubtypes = this.resourcesList.selectedOptions.selected.map(e => e.value);
    data.notifySubtypes = this.actorsNotificationList.selectedOptions.selected.map(e => e.value);
    const observable = this.data.eventId ? this.api.updateEventTemplate(this.data.user.organization!.id, this.data.eventId, data) : this.api.createEventTemplate(this.data.user.organization!.id, data);
    observable.subscribe(() => {
      this.api.log('toast.saved');
      this.dialogRef.close(true);
    });
  }

  calculateCheck(actor: string, control: 'notifySubtypes' | 'targetSubtypes') {
    return this.form.controls[control].controls.some((e) => e.value === actor);
  }

  close() {
    this.dialogRef.close(false);
  }

  checkAllResources(event: MatCheckboxChange) {
    if (event.checked) {
      this.resourcesList.selectAll();
      this.allResourcesCheck = true;
    } else {
      this.resourcesList.deselectAll();
      this.allResourcesCheck = false;
    }
  }

  checkAllActors(event: MatCheckboxChange) {
    if (event.checked) {
      this.actorsNotificationList.selectAll();
      this.allActorsCheck = true;
    } else {
      this.actorsNotificationList.deselectAll();
      this.allActorsCheck = false;
    }
  }
}
