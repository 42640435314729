<section class="h-full overflow-y-auto p-8">
  <div class="flex justify-between mb-8">
    <div class="mx-4 pt-5">
      <div class="overline-o1 mb-1 primary-400 secondary-text">{{'recordList.dashboard' | translate}}</div>
      <div class="title-t1">{{'favorites.title' | translate}}</div>
    </div>
    <app-button-secondary *ngIf="!isGuest" (click)="openFavoritesModal()" class="mt-6" data-test="newFavoriteButton">{{'favorites.button' | translate}}</app-button-secondary>
  </div>

  <div class="p-3 pb-10">
    <div class="table-header flex justify-between mt-4 p-3 items-end space-x-5">
      <div class="filter w-1/2 pr-10">
        <mat-form-field appearance="fill" class="w-full" data-test="searcher">
          <input matInput [placeholder]="'favorites.searchTooltip' | translate" #input>
          <mat-icon svgIcon="search" class="p-4" matSuffix></mat-icon>
        </mat-form-field>
      </div>
    </div>

    <app-table-no-data [dataSource]="dataSource" [subscription]="subscription" [message]="('favorites.noData' | translate)" class="pb-2">
      <div class="shadow-dark border-tb overflow-auto whitespace-nowrap">

        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortFavorites($event)" class="w-full" data-test="favoriteTable">


          <!-- name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{'favorites.columns.name' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex flex-col">
                <div><span class="link" (click)="openFavoritesModal(element.id)"> {{element.data.alias}}</span></div>
                <span>{{element.data.name}}</span>
              </div>
            </td>
          </ng-container>

          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>{{'favorites.columns.id' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex items-center">
                <span *ngIf="element.id" class="ml-2">{{element.data.legalId}}</span>
                <span *ngIf="!element.id" class="ml-2">-</span>
              </div>
            </td>
          </ng-container>

          <!-- created Date Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>{{'favorites.columns.createdAt' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'shortDate'}} </td>
          </ng-container>

          <!-- addBy Column -->
          <ng-container matColumnDef="addBy">
            <th mat-header-cell *matHeaderCellDef>{{'favorites.columns.createdBy' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex items-center">
                <span>{{element.createdBy.name}}</span>
                <span *ngIf="element.createdBy.name && element.createdBy.email" class="mx-1">|</span>
                <span>{{element.createdBy.email}}</span>
              </div>
            </td>
          </ng-container>

          <!--Action Column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-center items-center">
                <app-button-link (click)="openFavoritesModal(element.id)" text="{{'buttons.edit' | translate}}" iconLeft="edit-2" [style]="'primary'" class="mr-2" data-test="editButton"></app-button-link>
                <app-button-link (click)="deleteFavorite(element.id)" text="{{'buttons.delete' | translate}}" iconLeft="trash" [style]="'warn'" data-test="deleteButton"></app-button-link>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

        </table>

      </div>

      <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource.data.length}" class="border-paginator"></mat-paginator>
    </app-table-no-data>
  </div>

</section>
