import { Component, Input } from '@angular/core';

import { OrganizationExtended } from '../../models/main';

@Component({
  selector: 'app-billing-data[organization]',
  templateUrl: './billing-data.component.html',
  styleUrls: ['./billing-data.component.scss'],
})
export class BillingDataComponent {
  @Input() isBackOffice = false;
  @Input() editForm = false;
  @Input() organization!: OrganizationExtended;
  constructor() { }

}
