import { Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import { DEFAULT } from '../models/constants';
import { LOCALES, Theme, User } from '../models/main';
import { ApiService } from '../services/api.service';
import { MainEventService } from './main-event.service';


@Injectable({ providedIn: 'root' })
export class MainCacheService {
  protected _$user: ReplaySubject<User> | undefined;
  protected _locale: LOCALES = localStorage.getItem('locale') as LOCALES || DEFAULT.LOCALE;
  protected api!: ApiService;

  constructor(protected translate: TranslateService, protected injector: Injector, protected dateAdapter: DateAdapter<Date>) {
  }

  init(): Promise<any> {
    MainEventService.socketConnected.subscribe(socketId => this.socketId = socketId);
    this.api = this.injector.get(ApiService); // No tocar. Para evitar dependencias circulares en el APP_INITIALIZER
    this.translate.setDefaultLang(this.locale);
    return firstValueFrom(this.translate.use(this.locale));
  }

  // Setea un usuario pero no emite evento
  setUser(user: User) {
    if (!this._$user) this._$user = new ReplaySubject<User>(1);
    this._$user.next(user);
  }

  setToken(token: string, saveOnSessionStorage: boolean = false) {
    saveOnSessionStorage ? window.sessionStorage.setItem('token', token) : window.localStorage.setItem('token', token);
  }

  getToken(): string {
    return sessionStorage.getItem('token') || localStorage.getItem('token') || '';
  }

  get socketId(): string {
    return sessionStorage.getItem('socketId') || '';
  }

  set socketId(socketId: string) {
    sessionStorage.setItem('socketId', socketId);
  }

  get theme(): Theme {
    return localStorage.getItem('theme') as Theme || 'light';
  }

  set theme(theme: Theme) {
    localStorage.setItem('theme', theme);
  }

  get locale(): LOCALES {
    return this._locale;
  }

  set locale(locale: LOCALES) {
    localStorage.setItem('locale', locale);
    if (this._locale !== locale) this.translate.resetLang(this._locale);
    this._locale = locale;
    this.translate.use(locale);
    this.dateAdapter.setLocale(locale);
    MainEventService.localeChanged.emit(locale);
  }

  get lang(): string {
    return this._locale.substring(0, 2);
  }

  clearStorage() {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this._$user = undefined;
  }

  // Este metodo es sobreescrito por sus hijos. En runtime nunca llega a usarse. A efectos practicos MainCache es una clase abstracta
  getUser() {
    return new ReplaySubject<User>(1);
  }

}
