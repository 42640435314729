<div class="p-6">
  <h3 class="overline-o2 secondary-text">{{'organizationUsage.usageTitle' | translate }}</h3>
  <div *ngFor="let item of usages">
    <h4 class="overline-o1 my-4">{{'organizationUsage.months.' + item.month | translate}}  {{item.year}}</h4>
    <div class="mt-6 flex justify-start items-center space-x-4">
      <app-usage-card [type]="'recordsMonth'" [data]="item" data-test="recordsMonth"></app-usage-card>
      <app-usage-card [type]="'records'" [data]="item" data-test="records"></app-usage-card>
      <app-usage-card *ngIf="isBackOffice" [type]="'users'" [data]="item"></app-usage-card>
      <app-usage-card [type]="'storage'" [data]="item" data-test="storage"></app-usage-card>
    </div>
  </div>
  <div class="flex space-x-8">
    <div *ngIf="totalUsages > 1 && usages.length !== totalUsages" (click)="getPage()" class="flex action my-6 items-center ml-2">
      <mat-icon svgIcon="eye" color="primary" class="mr-2"></mat-icon>
      <span class="link edit pt-1">{{'organizationUsage.seeMore' | translate}}</span>
    </div>
    <div (click)="getPage(true)" *ngIf="usages.length > 1" class="flex action my-6 items-center ml-2">
      <mat-icon svgIcon="eye" color="primary" class="mr-2"></mat-icon>
      <span class="link edit pt-1" >{{'organizationUsage.seeFirst' | translate}}</span>
    </div>
  </div>
</div>
