import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { InvitedMembers, Matrix, OrganizationInviteRequest, Permissions, PermissionsWrapper } from '../models/administration';
import { Actor, ActorReduced, AutocompleteOrganization, BillingData, CargoXDocumentsData, Chat, DashboardEntity, DocumentAIData, EventCard, EventTemplate, Favorite, GenericWrapper, GenericWrapperArray, IntegrationsData, LOCALES, LoginData, LoginWrapper, ManagerSettings, Message, NotificationCard, OrganizationExtended, PaginatedWrapper, Place, Plan, RecordCanvasNode, RecordDetails, RecordDocument, RecordEntity, RecordInfo, RecordInvoice, RESOURCE_TYPE, Subscription, Template, Transport, Usage, User, UserNotification, UserWrapper, VIEWS_TYPE } from '../models/main';
import { MainEnvService } from '../shared/main-env.service';
import { MainHelperService } from '../shared/main-helper.service';
import { FormatHttp } from './format-api-responses';


export interface CustomQuery {
  filter?: Record<string, string | boolean>;
  pagination?: { offset: number, limit: number };
  sort?: string[];
  // Pueden ser anidado: Si se quiere acceder a atributos usamos la barra baja '_'. Para includes de includes el punto '.'
  // 'actor.record.organization' incluiria el actor, el record del actor, y la org del record del actor
  // 'collaborators_user' incluiria los usuarios dentro del array de colaboradores
  include?: string[];
  populate?: boolean // Inserta los elementos del included alla donde esten
}

@Injectable({ providedIn: 'root' })
export class ApiService {

  endpoint = this.env.url;

  constructor(private env: MainEnvService,
              private router: Router,
              private http: HttpClient,
              private snackBar: ToastrService,
              private translate: TranslateService,
              private helper: MainHelperService) {
  }

  login(data: LoginData, captcha: string): Observable<LoginWrapper> {
    return this.http.post<GenericWrapper<any>>(`${this.endpoint}/auth/login/password?include=entity,entity.organization,entity.organization.blockchain_*_registration`, this.formatData(data, captcha)).pipe(map(FormatHttp.formatLogin), catchError(err => this.handleError(err)));
  }

  loginGuest(token: string, captcha: string): Observable<LoginWrapper> {
    return this.http.post<GenericWrapper<any>>(`${this.endpoint}/auth/login/guest`, this.formatData({ token }, captcha)).pipe(map(FormatHttp.formatLogin), catchError(err => this.handleError(err)));
  }

  signUp(data: any, captcha: string): Observable<LoginWrapper> {
    return this.http.post<any>(`${this.endpoint}/users/sign-up`, this.formatData(data, captcha)).pipe(map(FormatHttp.formatSignUp), catchError(err => this.handleError(err)));
  }

  validateEmail(data: { emailValidationCode: string }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/users/me/email-validation/confirm`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  sendEmailValidation(): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/users/me/email-validation/initiate`, this.formatData({})).pipe(catchError(err => this.handleError(err)));
  }

  recoverPassword(data: any): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/auth/password-recovery/initiate`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getUser(): Observable<UserWrapper> {
    return this.http.get<any>(`${this.endpoint}/users/me?include=organization,organization.blockchain_*_registration`).pipe(map(FormatHttp.formatUserMe), catchError(err => this.handleError(err)));
  }

  editUser(data: { name?: string, locale?: LOCALES, notifications?: UserNotification, view?: Record<string, string> }): Observable<UserWrapper> {
    return this.http.patch<GenericWrapper<User>>(`${this.endpoint}/users/me?include=organization,organization.blockchain_*_registration`, this.formatData(data)).pipe(map(FormatHttp.formatUserMe), catchError(err => this.handleError(err)));
  }

  acceptTermAndConditions(hash: string): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/users/me/accept-legal-terms`, this.formatData({ hash })).pipe(catchError(err => this.handleError(err)));
  }

  createApiKey(id: string, data: { days: string }) {
    return this.http.post<GenericWrapper<{ value: string, expiresAt: string }>>(`${this.endpoint}/users/${id}/api-key`, this.formatData(data)).pipe(map(res => res.data.attributes), catchError(err => this.handleError(err)));
  }

  deleteApiKey(id: string) {
    return this.http.delete<void>(`${this.endpoint}/users/${id}/api-key`).pipe(catchError(err => this.handleError(err)));
  }

  confirmPasswordChange(data: { login: string, code: string, password: string }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/auth/password-recovery/confirm`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  editPassword(data: { password: string; }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/auth/password-update`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getOrganization(id: string, query?: CustomQuery): Observable<OrganizationExtended> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/organizations/${id}`, { params }).pipe(map(result => FormatHttp.populateResponse<OrganizationExtended>(result, [['subscription.plan']])), catchError(err => this.handleError(err)));
  }

  editOrganizationData(data: OrganizationExtended | { billing: BillingData } | { settings: ManagerSettings } | { integrations: IntegrationsData }, organizationId: string): Observable<OrganizationExtended> {
    return this.http.patch<any>(`${this.endpoint}/organizations/${organizationId}`, this.formatData(data)).pipe(map(result => FormatHttp.populateResponse<OrganizationExtended>(result, [['organization']])), catchError(err => this.handleError(err)));
  }

  uploadOrganizationLogo(file: any, organizationId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`${this.endpoint}/organizations/${organizationId}/logo`, formData).pipe(catchError(err => this.handleError(err)));
  }

  acceptOrganizationInvitation(data: { user: { email: string, name: string, locale: string, legalTermsHash: string, password: string } }, invitationCode: string): Observable<LoginWrapper> {
    return this.http.post<any>(`${this.endpoint}/organization-invites/${invitationCode}/accept`, this.formatData(data)).pipe(map(result => FormatHttp.formatSignUp(result)), catchError(err => this.handleError(err)));
  }

  resendInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/organization-invites/${id}/resend`, {}).pipe(catchError(err => this.handleError(err)));
  }

  cancelInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/organization-invites/${id}/cancel`, {}).pipe(catchError(err => this.handleError(err)));
  }

  getInvitation(id: string): Observable<any> {
    return this.http.get<void>(`${this.endpoint}/organization-invites/${id}`).pipe(catchError(err => this.handleError(err)));
  }

  resendInvitationModal(id: string, actorId: string, data: any): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/records/${id}/actors/${actorId}/resend-collaborator-invite`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  checkIfLegalIdExists(id: string) {
    return this.http.get<void>(`${this.endpoint}/organizations/legal-id/${id}`).pipe(catchError(err => {
      if (err.error.code === 'OrganizationNotFound') return of(null);
      else return this.handleError(err);
    }));
  }

  // PROFILE //

  inviteMemberToOrganization(data: OrganizationInviteRequest): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/organization-invites`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getMembersFormOrganization(organizationId: string, query?: CustomQuery): Observable<PaginatedWrapper<InvitedMembers>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/organizations/${organizationId}/invited-members`, { params }).pipe(map(FormatHttp.formatInvitedMembers), catchError(err => this.handleError(err)));
  }

  updateMembersStatus(id: string, status: string): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/users/${id}/status`, this.formatData({ status: status })).pipe(catchError(err => this.handleError(err)));
  }

  updateManagerOrAdminStatus(organizationId: string, userId: string, column: string, value: boolean): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/organizations/${organizationId}/members/${userId}`, this.formatData({ [column]: value })).pipe(catchError(err => this.handleError(err)));
  }

  updateInviteManagerOrAdminStatus(inviteId: string, column: string, value: boolean): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/organization-invites/${inviteId}`, this.formatData({ [column]: value })).pipe(catchError(err => this.handleError(err)));
  }

  // USAGE //

  getUsages(id: string, query?: CustomQuery): Observable<PaginatedWrapper<Usage>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/organizations/${id}/usages`, { params }).pipe(map(FormatHttp.formatUsages), catchError(err => this.handleError(err)));
  }

  getLastUsage(id: string): Observable<Usage> {
    return this.http.get<any>(`${this.endpoint}/organizations/${id}/usages/now`).pipe(map(result => FormatHttp.formatUsage(result.data)), catchError(err => this.handleError(err)));
  }

  // MATRIXES //

  getMatrixLevel2(organizationId: string): Observable<PermissionsWrapper> {
    return this.http.get<any>(`${this.endpoint}/template-matrices/organizations/${organizationId}`).pipe(map(result => result.data.attributes), catchError(err => this.handleError(err)));
  }

  setMatrixLevel2(organizationId: string, data: Matrix): Observable<PermissionsWrapper> {
    return this.http.put<any>(`${this.endpoint}/template-matrices/organizations/${organizationId}`, this.formatData({ permissions: data })).pipe(map(result => result.data), catchError(err => this.handleError(err)));
  }

  getMatrixLevel3(recordId: string): Observable<PermissionsWrapper> {
    return this.http.get<any>(`${this.endpoint}/template-matrices/records/${recordId}`).pipe(map(result => result.data.attributes), catchError(err => this.handleError(err)));
  }

  setMatrixLevel3(recordId: string, data: Matrix): Observable<PermissionsWrapper> {
    return this.http.put<any>(`${this.endpoint}/template-matrices/records/${recordId}`, this.formatData({ permissions: data })).pipe(map(result => result.data.attributes), catchError(err => this.handleError(err)));
  }

  // ToDo: tipar esto. El includes era: { subtype: string, displayName: string }
  getMatrixLevel4(recordId: string, resourceId?: string): Observable<GenericWrapper<PermissionsWrapper>> {
    const filter = resourceId ? `?filter.columnId=${resourceId}` : '';
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/permissions${filter}`).pipe(catchError(err => this.handleError(err)));
  }

  setMatrixLevel4(recordId: string, data: Matrix): Observable<GenericWrapper<PermissionsWrapper>> {
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/permissions`, this.formatData({ permissions: data })).pipe(catchError(err => this.handleError(err)));
  }

  getMyPermissions(recordId: string): Observable<Permissions> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/permissions/combined`).pipe(map(res => res.data.attributes.permissions), catchError(err => this.handleError(err)));
  }

  // RECORD //
  createRecordWithTemplate(templateId: string): Observable<{ data: { id: string } }> {
    return this.http.post<any>(`${this.endpoint}/record-templates/${templateId}/create-record`, this.formatData({})).pipe(catchError(err => this.handleError(err)));
  }

  deleteTemplate(templateId: string): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/record-templates/${templateId}`).pipe(catchError(err => this.handleError(err)));
  }

  cloneRecord(recordId: string): Observable<{ data: { id: string } }> {
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/clone`, this.formatData({})).pipe(catchError(err => this.handleError(err)));
  }

  getRecords(query?: CustomQuery): Observable<PaginatedWrapper<RecordEntity>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/records`, { params }).pipe(map(FormatHttp.formatRecordList), catchError(err => this.handleError(err)));
  }

  getDashboard(query: CustomQuery, view?: string, columns?: Record<string, boolean>): Observable<PaginatedWrapper<DashboardEntity>> {
    query.include = ['record', 'record.organization']; // Para popular el record y la orga
    let params = this.httpParamsFactory(query);
    if (columns) {
      Object.entries(columns).forEach(([key, value]) => {
        params = params.append(`columns.${key}`, value.toString());
      });
    }
    return this.http.get<any>(`${this.endpoint}/dashboard${view === VIEWS_TYPE.MAIN ? '' : '/' + view}`, { params }).pipe(map(FormatHttp.formatDashboardEntityList), catchError(err => this.handleError(err)));
  }

  archiveRecord(recordId: string, archived: boolean): Observable<string> {
    const data = { status: archived ? 'active' : 'archived' };
    return this.http.put<any>(`${this.endpoint}/records/${recordId}/status`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getRecord(id: string): Observable<RecordInfo> {
    return this.http.get<any>(`${this.endpoint}/records/${id}`).pipe(map(res => FormatHttp.populateResponse<RecordInfo>(res, [['organization']], { meta: true })), catchError(err => this.handleError(err)));
  }

  getRecordActors(recordId: string): Observable<ActorReduced[]> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/actors?&include=collaborators_user,organization`).pipe(map(FormatHttp.formatActors), catchError(err => this.handleError(err)));
  }

  getActor(recordId: string, actorId: string): Observable<Actor | Transport> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/actors/${actorId}?injectIncluded=true`).pipe(map(FormatHttp.formatActor), catchError(err => this.handleError(err)));
  }

  getRecordDetails(recordId: string): Observable<RecordDetails> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/details`).pipe(map(result => ({ ...result.data.attributes, id: result.data.id }), catchError(err => this.handleError(err))));
  }

  createActor(recordId: string, data: any): Observable<Actor> {
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/actors/`, this.formatData(data)).pipe(map(FormatHttp.formatActor), catchError(err => this.handleError(err)));
  }

  setActor(recordId: string, actorId: string, data: Partial<Actor | Transport>): Observable<Actor | Transport> {
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/actors/${actorId}`, this.formatData(data)).pipe(map(FormatHttp.formatActor), catchError(err => this.handleError(err)));
  }

  getPlace(recordId: string, actorId: string): Observable<Place> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/places/${actorId}?injectIncluded=true`).pipe(map(FormatHttp.formatPlace), catchError(err => this.handleError(err)));
  }

  setPlace(recordId: string, placeId: string, data: Partial<Place>): Observable<Place> {
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/places/${placeId}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  deleteResource(recordId: string, placeId: string, type: RESOURCE_TYPE): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/records/${recordId}/${type}/${placeId}`).pipe(catchError(err => this.handleError(err)));
  }

  getRecordInvoices(recordId: string, query: CustomQuery): Observable<PaginatedWrapper<RecordInvoice>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<GenericWrapperArray<RecordInvoice>>(`${this.endpoint}/records/${recordId}/invoices/`, { params }).pipe(map(res => FormatHttp.formatPaginatedList<RecordInvoice>(res, [['createdBy'], ['createdBy.organization']])), catchError(err => this.handleError(err)));
  }

  createRecordInvoice(recordId: string, data: RecordInvoice): Observable<RecordInvoice> {
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/invoices`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  setRecordInvoice(recordId: string, data: RecordInvoice): Observable<RecordInvoice> {
    return this.http.put<any>(`${this.endpoint}/records/${recordId}/invoices/${data.id}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  deleteRecordInvoice(recordId: string, invoiceId: string): Observable<void> {
    return this.http.delete<any>(`${this.endpoint}/records/${recordId}/invoices/${invoiceId}`).pipe(catchError(err => this.handleError(err)));
  }

  getRecordPermissions(recordId: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/permissions/entity/records/${recordId}`).pipe(catchError(err => this.handleError(err)));
  }

  // TEMPLATES //

  saveRecordTemplate(data: any) { // guardo un canvas, tipar ese data cuando tengamos tipado el canvas @pablo
    return this.http.post<any>(`${this.endpoint}/record-templates`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getRecordTemplates(): Observable<Template[]> {
    return this.http.get<any>(`${this.endpoint}/record-templates`).pipe(map(FormatHttp.formatTemplatesList), catchError(err => this.handleError(err)));
  }

  // CANVAS //
  getCanvas(id: string): Observable<{ id: string, nodes: RecordCanvasNode[] }> {
    return this.http.get<any>(`${this.endpoint}/records/${id}/canvas`).pipe(map(FormatHttp.formatCanvasList), catchError(err => this.handleError(err)));
  }

  updateNode(recordId: string, node: any): Observable<RecordCanvasNode> { // El nodo que se envia tiene atributos extra
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/canvas`, { data: node }).pipe(map(FormatHttp.formatCanvasNode), catchError(err => this.handleError(err)));
  }

  // CHATS //

  getChats(query?: CustomQuery): Observable<PaginatedWrapper<Chat>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/chats`, { params }).pipe(map((FormatHttp.formatChatList), catchError(err => this.handleError(err))));
  }

  getSingleChat(chatId: string): Observable<Chat> {
    return this.http.get<any>(`${this.endpoint}/chats/${chatId}`).pipe(map(FormatHttp.formatChat), catchError(err => this.handleError(err)));
  }

  createNewChat(data: any): Observable<GenericWrapper<Chat>> {
    return this.http.post<any>(`${this.endpoint}/chats`, { data }).pipe(catchError(err => this.handleError(err)));
  }

  updateChat(id: string, data: { index?: number, muted?: boolean, message?: string, participants?: any[] }): Observable<Chat> {
    return this.http.patch<any>(`${this.endpoint}/chats/${id}`, { data }).pipe(map((res: any) => FormatHttp.populateResponse<Chat>(res, [['lastMessage.senderUser']])), catchError(err => this.handleError(err)));
  }

  getChatMessages(id: string): Observable<Message[]> {
    return this.http.get<any>(`${this.endpoint}/chats/${id}/messages`).pipe(map(res => FormatHttp.populateResponseArray<Message>(res, [['senderUser']])), catchError(err => this.handleError(err)));
  }

  userSelfInvite(id: string): Observable<Chat> {
    const params = this.httpParamsFactory({ include: ['participants'] });
    return this.http.post<any>(`${this.endpoint}/chats/${id}/join`, {}, { params }).pipe(map(res => FormatHttp.populateResponse<Chat>(res, [['participants']])), catchError(err => this.handleError(err)));
  }

  resendChatInvitation(id: string | undefined, data: any): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/chats/${id}/resend`, data).pipe(catchError(err => this.handleError(err)));
  }

  // OTHERS //
  getLocodes(country: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/data/locodes/${country}`).pipe(map(result => result.data.attributes.locodes), catchError(err => this.handleError(err)));
  }

  // DOCUMENTS
  uploadFile(recordId: string, data: any, file: File): Observable<RecordDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify(data));
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/documents`, formData).pipe(map(FormatHttp.formatDocument), catchError(err => this.handleError(err)));
  }

  downloadFile(recordId: string, documentId: string, versionId: string): Observable<string> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/documents/${documentId}/versions/${versionId}/download`).pipe(map(result => (result.data.attributes.downloadLink)), catchError(err => this.handleError(err)));
  }

  downloadMultipleFile(recordId: string, documentIds: string[]): Observable<Blob> {
    return this.http.post(`${this.endpoint}/records/${recordId}/documents/download`, { data: { documentIds } }, { responseType: 'blob' }).pipe(catchError(err => this.handleError(err)));
  }

  getDocuments(recordId: string, query?: CustomQuery): Observable<PaginatedWrapper<RecordDocument>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/documents`, { params }).pipe(map(FormatHttp.formatDocuments), catchError(err => this.handleError(err)));
  }

  getDocumentDetail(recordId: string, documentId: string): Observable<RecordDocument> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/documents/${documentId}`).pipe(map(FormatHttp.formatDocumentDetail), catchError(err => this.handleError(err)));
  }

  editDocument(recordId: string, data: any, file?: File, documentId?: string): Observable<RecordDocument> {
    const formData: FormData = new FormData();
    // Si se manda data se disparan notificaciones de que el doc/version ha cambiado
    if (file) formData.append('file', file);
    else formData.append('data', JSON.stringify(data));
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/documents/${documentId}`, formData).pipe(map(FormatHttp.formatDocument), catchError(err => this.handleError(err)));
  }

  // FAVORITES
  getAllFavorites(query?: CustomQuery): Observable<PaginatedWrapper<Favorite>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/favorites`, { params }).pipe(map(FormatHttp.formatFavorites), catchError(err => this.handleError(err)));
  }

  getFavoriteDetail(favoriteId: string): Observable<Favorite> {
    return this.http.get<any>(`${this.endpoint}/favorites/${favoriteId}`).pipe(map(res => FormatHttp.fixJSONAPI<Favorite>(res.data)), catchError(err => this.handleError(err)));
  }

  createFavorite(data: { subtype: string, name: string, data: Favorite }): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/favorites`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  deleteFavorite(favoriteId: string): Observable<void> {
    return this.http.delete<any>(`${this.endpoint}/favorites/${favoriteId}`).pipe(catchError(err => this.handleError(err)));
  }

  editFavorite(favoriteId: string, data: { subtype: string, name: string, data: Favorite }): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/favorites/${favoriteId}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  // NOTIFICATIONS
  getNotificationDetail(notificationId: string): Observable<NotificationCard> {
    return this.http.get<any>(`${this.endpoint}/notifications/${notificationId}`).pipe(map(result => FormatHttp.fixJSONAPI<NotificationCard>(result.data)), catchError(err => this.handleError(err)));
  }

  getNotifications(query?: CustomQuery): Observable<NotificationCard[]> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/notifications`, { params }).pipe(map(FormatHttp.formatResponseArray<NotificationCard>), catchError(err => this.handleError(err)));
  }

  updateNotificationsConfiguration(userId: string, data: UserNotification): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/users/${userId}/notifications-settings`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  markAllNotificationsAsRead(): Observable<void> {
    return this.http.put<any>(`${this.endpoint}/notifications`, {}).pipe(catchError(err => this.handleError(err)));
  }

  markNotificationAsRead(notificationId: string, isRead: boolean): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/notifications/${notificationId}`, this.formatData({ isRead: isRead })).pipe(catchError(err => this.handleError(err)));
  }

  // AUTOCOMPLETE
  getAutocompleteList(query?: CustomQuery): Observable<AutocompleteOrganization[]> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/autocomplete/actor`, { params }).pipe(map(FormatHttp.formatAutocomplete), catchError(err => this.handleError(err)));
  }

  // BACKOFFICE
  getAdmin(): Observable<UserWrapper> {
    return this.http.get<any>(`${this.endpoint}/admins/me`).pipe(map(FormatHttp.formatUserMe), catchError(err => this.handleError(err)));
  }

  loginBO(data: LoginData, captcha: string): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/auth/login/admin`, this.formatData(data, captcha)).pipe(map(FormatHttp.formatLogin), catchError(err => this.handleError(err)));
  }

  recoverPasswordBO(data: { email: string }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/admins/password-recovery/initiate`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  confirmPasswordChangeBO(data: { email: string, code: string, password: string }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/admins/password-recovery/confirm`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getOrganizations(query?: CustomQuery): Observable<PaginatedWrapper<OrganizationExtended>> {
    const params = this.httpParamsFactory(query);

    return this.http.get<any>(`${this.endpoint}/organizations?include=createdBy`, { params }).pipe(map(FormatHttp.formatOrganizations), catchError(err => this.handleError(err)));
  }

  updateSubscription(id: string, data: Subscription): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/organizations/${id}/subscription`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getPlans(): Observable<Plan[]> {
    return this.http.get<any>(`${this.endpoint}/plans`).pipe(map(FormatHttp.formatResponseArray<Plan>), catchError(err => this.handleError(err)));
  }

  updateAdmin(id: string, data: any): Observable<UserWrapper> {
    return this.http.patch<any>(`${this.endpoint}/admins/${id}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  // EVENTS //

  createEvent(data: EventCard): Observable<EventCard> {
    return this.http.post<any>(`${this.endpoint}/record-events`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getEventList(query?: CustomQuery): Observable<EventCard[]> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/record-events`, { params }).pipe(map(res => FormatHttp.formatEventList(res)), catchError(err => this.handleError(err)));
  }

  updateEventStatus(data: any, eventId: string): Observable<EventCard> {
    return this.http.put<any>(`${this.endpoint}/record-events/${eventId}/status?include=entity,timeline_blockchain_*_update,timeline_subject.organization`, this.formatData(data)).pipe(map(res => FormatHttp.formatEvent(res)), catchError(err => this.handleError(err)));
  }

  updateEventIssueStatus(data: any, eventId: string): Observable<EventCard> {
    return this.http.put<any>(`${this.endpoint}/record-events/${eventId}/issue-status?include=entity,timeline_blockchain_*_update,timeline_subject.organization`, this.formatData(data)).pipe(map(res => FormatHttp.formatEvent(res)), catchError(err => this.handleError(err)));
  }

  createEventTemplate(organizationId: string, data: EventTemplate): Observable<EventTemplate> {
    return this.http.post<any>(`${this.endpoint}/organizations/${organizationId}/events-templates`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getEventTemplate(organizationId: string, eventId: string): Observable<EventTemplate> {
    return this.http.get<any>(`${this.endpoint}/organizations/${organizationId}/events-templates/${eventId}`).pipe(map(res => FormatHttp.fixJSONAPI<EventTemplate>(res.data)), catchError(err => this.handleError(err)));
  }

  getEventTemplateList(organizationId: string, query?: CustomQuery): Observable<PaginatedWrapper<EventTemplate>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/organizations/${organizationId}/events-templates`, { params }).pipe(map(res => FormatHttp.formatPaginatedList<EventTemplate>(res, [])), catchError(err => this.handleError(err)));
  }

  deleteEventTemplate(organizationId: string, eventId: string): Observable<void> {
    return this.http.delete<any>(`${this.endpoint}/organizations/${organizationId}/events-templates/${eventId}`).pipe(catchError(err => this.handleError(err)));
  }

  updateEventTemplate(organizationId: string, eventId: string, data: Partial<EventTemplate>): Observable<EventTemplate> {
    return this.http.patch<any>(`${this.endpoint}/organizations/${organizationId}/events-templates/${eventId}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  // INTEGRATIONS

  envelopesSend(data: { ethereumAddress: string; recordId: string; taxesActorId: string; documentsIds: string[]; }) {
    return this.http.post<any>(`${this.endpoint}/envelopes/send`, this.formatData(data)).pipe(map(res => ({ id: res.data.id, externalData: res.data.attributes.externalData })), catchError(err => this.handleError(err)));
  }

  envelopesIssue(data: { ethereumAddress: string; envelopeId: string; signedDocuments: CargoXDocumentsData[] | undefined; }) {
    return this.http.post<void>(`${this.endpoint}/envelopes/${data.envelopeId}/issue`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getCargoXUrl() {
    return this.http.post<any>(`${this.endpoint}/envelopes/activate`, {}).pipe(map(res => ({ registrationUrl: res.data.attributes.registrationUrl })), catchError(err => this.handleError(err)));
  }

  getDocumentAIData(recordId: string, documentId: string, versionId: string): Observable<DocumentAIData> {
    return this.http.get<any>(`${this.endpoint}/documentai/${recordId}/documents/${documentId}/versions/${versionId}`).pipe(catchError(err => this.handleError(err)));
  }

  // EDOCS //

  validateEdoc(data: File | string, captcha: string) {
    let formData: FormData | { data: { id: string }, meta?: { captchaResponse: string } };
    if (data instanceof File) {
      formData = new FormData();
      formData.append('file', data);
      formData.append('meta', JSON.stringify({ captchaResponse: captcha }));
    } else {
      formData = this.formatData({ id: data }, captcha);
    }
    return this.http.post<any>(`${this.endpoint}/edocs/guardian/validate-edoc`, formData).pipe(map(res => {
      if (res.data.attributes.organization) {
        FormatHttp.populateBlockchain(res, res.data.attributes.organization.blockchain);
        return res.data.attributes;
      }
      return res.data.attributes;
    }), catchError(err => this.handleError(err)));
  }

  // COMMON //

  formatData(reqData: any, captcha?: string) {
    const data: { data: any, meta?: { captchaResponse: string } } = { data: reqData };
    if (captcha) data.meta = { captchaResponse: captcha };
    return data;
  }

  /**
   * Devuelve el valor de la version en el txt que se actualiza con cada despliegue
   */
  getLastVersion(): Observable<string> {
    return this.http.get('/assets/version.txt', { responseType: 'text' }).pipe(map(res => res.replace('\n', '')), catchError(err => this.handleError(err)));
  }

  // LOG - TOAST
  // Los 3 metodos reciben un mensaje que se mostrara como literal si translate es false y que se usara como clave de traducciones si translate es true
  /**
   * Muestra un toast con un mensaje de informacion. Se usa para notificar de eventos poco relevantes como un "elemento guardado"
   */
  log(message: string, translate: boolean = true, params?: Object) {
    this.openSnackBar(message, translate, 'success', params);
  }

  /**
   * Muestra un toast con un mensaje de aviso. Se usa para notificar de eventos algo relevantes como que una sesion ha expirado
   */
  logWarn(message: string, translate: boolean = true, params?: Object) {
    this.openSnackBar(message, translate, 'warning', params);
  }

  /**
   * Muestra un toast con un mensaje de error. Se usa para notificar de eventos muy relevantes como que algo ha fallado
   */
  logError(message: string, translate: boolean = true, params?: Object) {
    this.openSnackBar(message, translate, 'error', params);
  }

  private openSnackBar(message: string, translate: boolean, method: 'success' | 'warning' | 'error', params?: Object) {
    if (translate) message = this.translate.instant(message, params);
    this.snackBar[method](message, undefined, { timeOut: method === 'success' ? 5000 : 15000 });
  }

  /**
   * Manejador de errores comun para todos los servicios
   */
  private handleError(httpError: Error): Observable<never> {
    if (!(httpError instanceof HttpErrorResponse)) return throwError(httpError);
    const error = httpError.error;
    if (httpError.status === 0 || ((httpError.status === 503 || httpError.status === 502) && !httpError.headers.get('access-control-allow-origin'))) {
      return new Observable((subscriber) => {
        this.http.get('https://catfact.ninja/fact').subscribe({
          next: () => {
            this.logError('errorsHTTP.NoServerConnection', true);
            subscriber.error(this.translate.instant('errorsHTTP.NoServerConnection'));
          },
          error: () => {
            this.logError('errorsHTTP.NoConnection', true);
            subscriber.error(this.translate.instant('errorsHTTP.NoConnection'));
          },
        });
      });
    }
    if (!error.code) {
      this.logError('errorsHTTP.BackCodeNotFound', true, { error: httpError.message });
      return throwError(httpError);
    }
    if (['TokenInvalid', 'NotAuthorized', 'TokenExpired'].includes(error.code)) this.helper.redirectToLogin();
    if (error.code === 'RecordNotFound') {
      this.router.navigate(['record-not-found', error.meta.request.path.split('/')[3]]);
      return throwError(httpError);
    }
    if (error.code === 'OrganizationProductLimit') {
      return throwError(error);
    }
    if (error.code === 'Validation') {
      const issue = error.meta.info.issues[0];
      const message = `Validation error: ${issue.code} | ${issue.expected} | ${issue.received} | ${issue.message} | ${issue.path.join('.')}`;
      this.logError(message, false);
      return throwError(error);
    }
    this.logError('errorsHTTP.' + error.code, true, error.meta.info);
    return throwError(httpError);
  }

  private httpParamsFactory(query?: CustomQuery): HttpParams {
    let params = new HttpParams();
    if (!query) return params;
    if (query.sort && query.sort.length) params = params.set('sort', query.sort.reduce((a, b) => a + ',' + b));
    if (query.include && query.include.length) params = params.set('include', query.include.reduce((a, b) => a + ',' + b));
    if (query.populate) params = params.set('injectIncluded', true);
    if (query.pagination) {
      params = params.set('page.offset', query.pagination.offset);
      params = params.set('page.limit', query.pagination.limit);
    }
    if (query.filter) Object.entries(query.filter).forEach(([key, value]) => params = params.set(`filter.${key}`, value));
    return params;
  }
}
