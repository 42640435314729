import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastrModule } from 'ngx-toastr';

import { AddressDataComponent } from './components/address-data/address-data.component';
import { ApplicationsFormComponent } from './components/applications-form/applications-form.component';
import { AutocompleteOrganizationComponent } from './components/autocomplete-organization/autocomplete-organization.component';
import { AutocompleteSelectComponent } from './components/autocomplete-select/autocomplete-select.component';
import { AvatarCircleComponent } from './components/avatar-circle/avatar-circle.component';
import { BillingDataComponent } from './components/billing-data/billing-data.component';
import { ButtonLinkComponent } from './components/button-link/button-link.component';
import { ButtonPrimaryComponent } from './components/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './components/button-secondary/button-secondary.component';
import { ButtonWarnComponent } from './components/button-warn/button-warn.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { ChipComponent } from './components/chip/chip.component';
import { ControlErrorComponent } from './components/control-error/app-control-error.component';
import { CropperImageModalComponent } from './components/cropper-image-modal/cropper-image-modal.component';
import { CustomActorComponent } from './components/custom-actor/custom-actor.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { Error404Component } from './components/error-404/error-404.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { IconSimpleCardComponent } from './components/icon-simple-card/icon-simple-card.component';
import { ImageAvatarComponent } from './components/image-avatar/image-avatar.component';
import { InfoHoverComponent } from './components/info-hover/info-hover.component';
import { InputComponent } from './components/input/input.component';
import { InputWithUnitComponent } from './components/input-with-unit/input-with-unit.component';
import { MenuLocaleComponent } from './components/locale-menu/menu-locale.component';
import { MatrixComponent } from './components/matrix/matrix.component';
import { NestedN1Component } from './components/nested/nested-n1/nested-n1.component';
import { NestedN2Component } from './components/nested/nested-n2/nested-n2.component';
import { OrganizationDataFormComponent } from './components/organization-data-form/organization-data-form.component';
import { SelectComponent } from './components/select/select.component';
import { SortableSelectComponent } from './components/sortable-select/sortable-select.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TableNoDataComponent } from './components/table-no-data/table-no-data.component';
import { ToggleCardComponent } from './components/toggle-card/toggle-card.component';
import { UsageCardComponent } from './components/usage-card/usage-card.component';
import { UsageProgressBarComponent } from './components/usage-progress-bar/usage-progress-bar.component';
import { UsagesComponent } from './components/usages/usages.component';
import { UserComponent } from './components/user/user.component';
import { DEFAULT } from './models/constants';
import { CastPipe, HighlightPipe, NewlineToBrPipe, SafePipe, SortTranslationPipe, TranslateCustom, TranslateJsonPipe, TruncatePipe } from './pipes/customPipes';
import { PaginatorIntlService } from './services/paginator-intl';
import { AuthInterceptor } from './shared/authInterceptor';
import { CustomErrorStateMatcher } from './shared/custom-error-state-matcher';
import { CloseAtScrollDirective, NumericDirective } from './shared/directives';

// AQUI TODOS LOS MAT QUE SE NECESITEN
const MATERIAL_MODULES = [
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  OverlayModule,
  ScrollingModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
];

// AQUI TODOS LOS COMPONENTES QUE DECLAREN Y EXPORTEN
const COMPONENTS = [
  ButtonLinkComponent,
  ButtonPrimaryComponent,
  ButtonSecondaryComponent,
  ButtonWarnComponent,
  ControlErrorComponent,
  CastPipe,
  HighlightPipe,
  SortTranslationPipe,
  NewlineToBrPipe,
  TranslateJsonPipe,
  TranslateCustom,
  TruncatePipe,
  SafePipe,
  Error404Component,
  AutocompleteSelectComponent,
  InfoHoverComponent,
  SelectComponent,
  NumericDirective,
  CloseAtScrollDirective,
  CustomFieldsComponent,
  InputComponent,
  AddressDataComponent,
  GenericModalComponent,
  MatrixComponent,
  ChipComponent,
  TableNoDataComponent,
  DateInputComponent,
  AvatarCircleComponent,
  ImageAvatarComponent,
  AutocompleteOrganizationComponent,
  NestedN1Component,
  NestedN2Component,
  InputWithUnitComponent,
  UsageCardComponent,
  OrganizationDataFormComponent,
  UsageProgressBarComponent,
  UsagesComponent,
  BillingDataComponent,
  UserComponent,
  CustomActorComponent,
  CropperImageModalComponent,
  StepperComponent,
  FileUploaderComponent,
  ApplicationsFormComponent,
  ToggleCardComponent,
  IconSimpleCardComponent,
  MenuLocaleComponent,
  SortableSelectComponent,
  CheckboxListComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    ...MATERIAL_MODULES,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxCaptchaModule,
    RouterModule,
    MatExpansionModule,
    ImageCropperModule,
    NgOptimizedImage,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translate: TranslateService) => new PaginatorIntlService(translate),
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: LOCALE_ID, useFactory: () => localStorage.getItem('locale') || DEFAULT.LOCALE }, // Hola yo del futuro: esto no hace lo q crees. Esta funcion no se llama cada vez que un componente nace. Por eso metiste el reload del navegador al cambiar de locale
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [
    HttpClientModule,
    TranslateModule,
    ...MATERIAL_MODULES,
    ...COMPONENTS,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule, // Hola yo del futuro: se que usar el snackbar de material parece una buena idea pero no permite notificaciones concurrentes. Deja de intentarlo por favor. Espero que tus gatos sigan bien.
    NgxCaptchaModule,
  ],
})

export class MyCommonModule {
}
