<div [matTooltip]="_limit.text" class="ml-2">
  <div class="caption-c2 capitalize">{{text1}}</div>
  <div class="my-1 bar bar-container">
    <div class="bar" [ngStyle]="{'background-color': color, 'width.%': _limit.progress}"></div>
  </div>
  <div class="flex items-center">
    <div class="body-b4 grey-700">{{text2}}</div>
    <mat-icon *ngIf="_limit.progress>80" color="primary" svgIcon="info" class="ml-1"></mat-icon>
  </div>
</div>
