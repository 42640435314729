<mat-accordion class="w-full bg-input-light mb-4">
  <mat-expansion-panel hideToggle [expanded]="true" #expansion [ngClass]="{'white' : white}">
    <mat-expansion-panel-header>
      <mat-panel-title class="flex justify-between m-0">

        <app-button-link text="{{title}}" [style]="'primary'" iconRight="{{expansion.expanded ? 'chevron-up' : 'chevron-down'}}" optionalClasses="full ml-2 overline-o2"></app-button-link>

        <div class="flex items-center space-x-3">
          <a *ngIf="controlId && (transportType === TRANSPORT_TYPES.Maritim || transportType === TRANSPORT_TYPES.River)" [href]="URL_TRACKING + 'container/' + controlId + '/action,direct'" target="_blank" class="block mt-5">
            <app-button-link text="{{'transportModal.tracking' | translate: { type: 'containerCard.title' | translate } }}" [style]="'primary'" iconLeft="location-pin" class="mr-1"></app-button-link>
          </a>
          <app-button-link *ngIf="!disabled" (click)="delete.emit()" [disabled]="disabled" [style]="'warn'" iconLeft="trash"></app-button-link>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [ngClass]="{'nested-container': margin}">
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>



