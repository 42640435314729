<div class="flex flex-col absolute inset-0">
  <mat-toolbar class="flex justify-between border-bottom" [ngClass]="{'alternative-background': env.name === 'sandbox'}">

    <div class="flex items-center -ml-1">
      <app-image-avatar [user]="user" [size]="'s'"></app-image-avatar>

      <div class="mx-4">{{ organizationName }}</div>
      <div *ngIf="env.name !== 'open'" class="overline-o2 grey-700" style="margin-top: 4px;">{{ env.name }}</div>
    </div>

    <div class="navbar flex items-center">
      <div *ngIf="!production" class="navbar__item flex justify-center items-center">
        <mat-icon [svgIcon]="theme==='dark' ? 'sun' : 'moon'" class="action mat-icon-l" (click)="changeThemeMode()"></mat-icon>
      </div>

      <div class="navbar__item flex justify-center items-center">
        <mat-icon routerLink="/profile/user-data" svgIcon="settings" class="action mat-icon-l" data-test="configurationButton"></mat-icon>
      </div>

      <div class="navbar__item flex justify-center items-center" (click)="showNotifications = !showNotifications" data-test="notificationsBellButton">
        <mat-icon [svgIcon]="newNotification ? 'bell-new' : 'bell'" class="action mat-icon-l" data-test="notificationsBellIcon"></mat-icon>
      </div>

      <div *ngIf="isGuest" (click)="exit('/onboarding')">
        <app-button-primary [type]="'button'">
          {{'buttons.registerFree' | translate}}
        </app-button-primary>
      </div>

      <div class="navbar__item flex justify-center items-center">
        <mat-icon (click)="exit('/login')" svgIcon="exit" class="action mat-icon-l" data-test="logoutButton"></mat-icon>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="flex-1">
    <mat-sidenav opened [mode]="'side'" [ngClass]="{'alternative-background': env.name === 'sandbox'}">
      <mat-nav-list>
        <a mat-list-item routerLink="/record-list">
          <mat-icon svgIcon="clipboard" class="mat-icon-l" data-test="recordListButton"></mat-icon>
        </a>
        <a *ngIf="!isGuest" mat-list-item routerLink="/favorites">
          <mat-icon svgIcon="heart" class="mat-icon-l" data-test="favoritesButton"></mat-icon>
        </a>
        <a mat-list-item routerLink="/messages">
          <mat-icon svgIcon="messages" class="mat-icon-l" data-test="messagesButton"></mat-icon>
        </a>
        <a *ngIf="user.organization && user.organization.integrations.powerBi.enabled && user.organization.integrations.powerBi.url" mat-list-item routerLink="/iframe">
          <mat-icon svgIcon="growth" class="mat-icon-l"></mat-icon>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="fix-content">
      <router-outlet></router-outlet>
      <app-notification-list [ngClass]="showNotifications ? '' : 'hidden'" (toggleVisibility)="showNotifications = !showNotifications" (fetchMore)="getNextNotifications()" (unreadNotifications)="newNotification = $event" [notifications]="notifications" [newNotification]="newNotification"></app-notification-list>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
