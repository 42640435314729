import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';

import { LOCALES, LoginWrapper } from '../models/main';
import { MainEnvService } from './main-env.service';


@Injectable({ providedIn: 'root' })
export class MainEventService {

  // Hola yo del futuro: te preguntaras "xq toda esta movida de estaticos? Toma asiento. Hay una herencia del MainEventService con el EventService de front y de BO. Eso esta bien pero como son servicios para angular son singletons, cada uno con su propio emiter. Lo suyo es que el componente de TableNoDataComponent dependiera del MainEventService y no de una especializacion como EventService. Eso implicaba que usara emitters distintos y que no funcionara."
  static login: EventEmitter<LoginWrapper | void> = new EventEmitter<LoginWrapper | void>();
  static localeChanged: EventEmitter<LOCALES> = new EventEmitter<LOCALES>();
  static socketConnected: EventEmitter<string> = new EventEmitter<string>();
  static loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected socket!: Socket;
  protected pendingEvents: { type: string, args: any }[] = [];

  constructor(protected env: MainEnvService) {
    MainEventService.login.subscribe((logged) => {
      logged?.token ? this.connect(logged.token) : this.disconnect();
    });
  }

  connect(token: string) {
    this.socket = io(this.env.socketUrl, { query: { token }, path: this.env.socketPath });

    // Listening events
    this.socket.on('connect', () => {
      // Lanzo todos los eventos que se hayan intentado mandar hasta que se ha establecido la conexion
      this.pendingEvents.forEach(elem => this.socket.emit(elem.type, elem.args));
      this.pendingEvents = [];
      MainEventService.socketConnected.emit(this.socket.id);
    });
  }

  socketSubscribe(type: string, id: string) {
    if (this.socket) {
      this.socket.emit('subscribe', { type, id });
    } else {
      // El socket no esta listo, lo guardo para que se lance cuando se establezca la conexion
      this.pendingEvents.push({ type: 'subscribe', args: { type, id } });
    }
  }

  socketUnsubscribe(type: string, id: string) {
    if (this.socket) this.socket.emit('unsubscribe', { type, id });
    else console.error('Se esta intentando cerrar el socket sin que exista');
  }

  disconnect() {
    if (this.socket) this.socket.disconnect();
  }

  loadingOn<T>(obs: Observable<T>) {
    MainEventService.loading.next(true);
    return obs.pipe(finalize(() => {
      MainEventService.loading.next(false);
    }));
  }

  loadingOnPromise<T>(p: Promise<T>) {
    return firstValueFrom(this.loadingOn(from(p)));
  }
}
