import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class LoadIconsService {
  private path: string = './assets/img/svg-icons.json';
  constructor(private domSanitizer: DomSanitizer, public matIconRegistry: MatIconRegistry) {
  }

  loadIcons(): Promise<void> {
    return fetch(this.path).then(res => res.json()).then(res => {
      Object.entries(res).map(([key, value]) => {
        this.matIconRegistry.addSvgIconLiteral(key, this.domSanitizer.bypassSecurityTrustHtml(value as string));
      });
    });
  }
}
