import { Component, EventEmitter, Input, Output } from '@angular/core';
import { noop } from 'rxjs';

import { NotificationCard } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';

@Component({
  selector: 'app-notification-list[notifications]',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent {
  @Output() toggleVisibility = new EventEmitter();
  @Output() fetchMore = new EventEmitter();
  @Output() unreadNotifications = new EventEmitter();
  @Input() set notifications(notifications: NotificationCard[]) {
    this._notifications = notifications;
    this.calculateHeight = false;
  }

  @Input() newNotification!: boolean;
  _notifications!: NotificationCard[];
  calculateHeight: boolean = false;


  constructor(private api: ApiService) { }

  toggleNotificationVisibility() {
    this.toggleVisibility.emit();
  }

  markAllAsRead() {
    this._notifications.forEach(notification => notification.isRead = true);
    this.newNotification = false;
    this.unreadNotifications.emit(false);
    this.api.markAllNotificationsAsRead().subscribe(noop);
  }

  getMoreNotifications(event: any) {
    if (this.calculateHeight) return;
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if ((scrollTop + clientHeight) > (scrollHeight - 50)) {
      this.calculateHeight = true;
      this.fetchMore.emit();
    }
  }

  calculateNotificationsRead(notification: NotificationCard) {
    if (!notification.isRead) {
      this.newNotification = true;
      this.unreadNotifications.emit(true);
    } else {
      this.newNotification = this._notifications.some(notification => !notification.isRead);
      if (this.newNotification) this.unreadNotifications.emit(true);
      else this.unreadNotifications.emit(false);
    }
  }
}
