import { Injectable } from '@angular/core';

import { MainEnvService } from '../../../../../common/shared/main-env.service';

@Injectable({
  providedIn: 'root',
})
export class EnvService extends MainEnvService {

  // Ver la clase padre para tener ejemplos
  bo = true;
}
