import { Component, Input, OnInit } from '@angular/core';

import { OrganizationExtended, Usage } from '../../models/main';
import { ApiService, CustomQuery } from '../../services/api.service';

@Component({
  selector: 'app-usages[organization]',
  templateUrl: './usages.component.html',
  styleUrls: ['./usages.component.scss'],
})
export class UsagesComponent implements OnInit {

  @Input() organization!: OrganizationExtended;
  @Input() isBackOffice = false;
  usages: Usage[] = [];
  totalUsages: number = 1;
  query: CustomQuery = { pagination: { offset: 0, limit: 1 } };

  constructor(private api: ApiService) {
  }

  ngOnInit(): void {
    this.getUsages(this.query);
  }

  getPage(onlyFirst: boolean = false) {
    if (onlyFirst) {
      this.query = { pagination: { offset: 0, limit: 1 } };
      this.usages = [];
    } else {
      const increase = this.query.pagination!.limit === 1 ? 1 : 4;
      this.query.pagination!.offset = this.query.pagination!.offset + increase;
      this.query.pagination!.limit = 4;
    }
    this.getUsages(this.query);
  }

  getUsages(query?: any) {
    this.api.getUsages(this.organization.id, query).subscribe(res => {
      this.usages = this.usages.concat(res.data);
      this.totalUsages = res.total;
    });
  }
}
