import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-control-error',
  templateUrl: './app-control-error.component.html',
  styleUrls: ['./app-control-error.component.scss'],
})

export class ControlErrorComponent {
  @Input() control!: AbstractControl | null;

  constructor() { }

  /**
   * Maps forms error messages with corresponding translation
   */
  get errorMessage(): string | null {
    if (this.control?.touched) {
      for (const propertyName in this.control?.errors) {
        if (this.control.errors.hasOwnProperty(propertyName)) {
          return propertyName;
        }
      }
    }
    return null;
  }
}
