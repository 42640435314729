import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { HelperService } from '../../../front/src/app/core/services/helper.service';

@Component({
  selector: 'app-avatar-circle[type][data]',
  templateUrl: './avatar-circle.component.html',
  styleUrls: ['./avatar-circle.component.scss'],
})
export class AvatarCircleComponent {
  Helper = HelperService;
  @Input() type!: 'image' | 'initials' | 'icon' | 'iconInitials';
  @Input() data!: string;
  @Input() disabled!: boolean;
  @Input() style: string = '';
  @Input() color: ThemePalette = 'primary';
  @Input() size: 's' | 'm' = 's';
}
