<div *ngIf="_form">
  <div class="flex space-x-4">
    <app-input [type]="'text'" [control]="_form.get('street')" [placeholder]="'organizationData.addressPlaceholder' | translate" [label]="'organizationData.address' | translate" class="w-full" data-test="address"></app-input>

    <div class="flex w-full space-x-4">
      <app-input [type]="'text'" [control]="_form.get('postalCode')" [placeholder]="'organizationData.postalCodePlaceholder' | translate" [label]="'organizationData.postalCode' | translate" class="w-1/2" data-test="postalCode"></app-input>
      <app-input [type]="'text'" [control]="_form.get('locality')" [placeholder]="'organizationData.cityPlaceholder' | translate" [label]="'organizationData.city' | translate" class="w-1/2" data-test="city"></app-input>
    </div>

  </div>
  <div class="flex space-x-4">
    <app-input [control]="_form.get('subdivision')" [placeholder]="'organizationData.provincePlaceholder' | translate" [label]="'organizationData.province' | translate" class="w-full" data-test="province"></app-input>

    <div class="flex w-full space-x-4">
      <app-autocomplete-select [type]="'country'" [placeholder]="'organizationData.countryPlaceholder'|translate" [control]="_form.get('country')" [label]="'organizationData.country' | translate" class="w-1/2" data-test="country"></app-autocomplete-select>
      <app-select [control]="_form.get('astronomicalBody')" [label]="'organizationData.planet' | translate" [type]="'astronomicalBody'" class="w-1/2" data-test="astronomicalBody" data-test="astronomicalBody"></app-select>
    </div>
  </div>
</div>

