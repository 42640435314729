import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '../../../../../../../common/services/api.service';

@Component({
  selector: 'app-save-template-modal',
  templateUrl: './save-template-modal.component.html',
  styleUrls: ['./save-template-modal.component.scss'],
})

export class SaveTemplateModalComponent implements OnInit {

  templateName: FormControl = new FormControl();
  recordCanvas: any; // tipar esto cuando tengamos el canvas tipado @pablo

  constructor(private api: ApiService, public dialogRef: MatDialogRef<SaveTemplateModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { recordId: string }) {};

  ngOnInit() {
    this.api.getCanvas(this.data.recordId).subscribe((res) => {
      this.recordCanvas = res;
    });
  }

  saveTemplate() {
    const data = {
      recordId: this.data.recordId,
      name: this.templateName.value,
      canvas: this.recordCanvas,
    };
    this.api.saveRecordTemplate(data).subscribe(() => {
      this.api.log('toast.templateSaved');
      this.dialogRef.close();
    });
  }
}
