// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

/*
 * Hola yo del futuro, se que vas a querer borrar esto xq piensas que la conf de cada entorno la cargas con un JSON a parte
 * pero esto lo necesitas para que la build arranque en modo prod o en modo dev
 *
 * if (environment.production) {
 *   enableProdMode();
 * }
 *
 */
