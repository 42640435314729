<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l cursor-pointer absolute">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div class="dialog-container__header">
    <div mat-dialog-title class="overline-o1">
      {{ 'events.metadataModal.title' | translate }}
    </div>
  </div>
  <hr class="divider">
  <div class="body-b2 px-6 overflow-y-auto modal-height">
    <p *ngFor="let item of data | keyvalue"><span class="label mr-1">{{item.key}}:</span>{{item.value}}</p>
  </div>
</section>
