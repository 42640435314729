<div *ngIf="type !== 'customsClearance'" class="form-container__form-field flex flex-col w-full">
  <mat-label class="label mb-1 form-container__form-field__label whitespace-nowrap" [ngClass]="{'text-danger mat-form-field-invalid' : _control.invalid && _control.touched, 'text-disabled' : _control.disabled}">{{ label || '' }}</mat-label>

  <mat-form-field>
    <mat-select placeholder="{{placeholder}}" [formControl]="_control">
      <!--      Si es transportType o distance no se ordena-->
      <mat-option *ngFor="let value of type==='transportType' || 'distance' ? data : data | sortTranslation: i18nPath" [value]="value">
        <mat-icon *ngIf="iconPrefix" [svgIcon]="iconPrefix + value"></mat-icon>
        <span>{{(i18nPath + value) | translate}}</span>
      </mat-option>
      <mat-select-trigger *ngIf="_control.value !== null">
        <mat-icon *ngIf="iconPrefix" [svgIcon]="iconPrefix + _control.value" class="icon"></mat-icon>
        <span>{{(i18nPath + _control.value) | translate}}</span>
      </mat-select-trigger>
    </mat-select>
  </mat-form-field>
  <app-control-error [control]="_control"></app-control-error>
</div>

<div *ngIf="type === 'customsClearance'" class="form-container__form-field flex flex-col">
  <mat-label class="label mb-1 form-container__form-field__label mb-1 whitespace-nowrap" [ngClass]="{'text-danger mat-form-field-invalid' : _control.invalid && _control.touched, 'text-disabled' : _control.disabled}">{{ label || '' }}</mat-label>
  <mat-form-field>
    <mat-select placeholder="{{placeholder}}" [formControl]="_control">
      <mat-select-trigger *ngIf="_control.value">
        <app-chip [data]="i18nPath + _control.value | translate" [color]="_control.value === 'na' ? 'grey' : _control.value" [additionalClasses]="'label uppercase'" [size]="'small'"></app-chip>
        <span class="ml-2 body-b2">{{i18nPath + 'code.' + _control.value | translate}}</span>
      </mat-select-trigger>
      <mat-option [value]="null" class="body-b2">
        <span class="ml-2 ">{{'select.customsClearance.null' | translate}}</span>
      </mat-option>
      <mat-option *ngFor="let value of data | sortTranslation: ('select.' + type + '.')" [value]="value">
        <app-chip [data]="i18nPath + value | translate" [color]="value === 'na' ? 'grey' : $any(value)" [additionalClasses]="'label uppercase'" [size]="'small'"></app-chip>
        <span class="ml-2 body-b2">{{i18nPath + 'code.' + value | translate}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-control-error [control]="_control"></app-control-error>
</div>
