<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <form [formGroup]="recoveryForm" (ngSubmit)="sendRequest()" *ngIf="recoveryForm" class="dialog-container__form">
    <div class="dialog-container__header">
      <div mat-dialog-title class="overline-o1">
        {{'passwordRecovery.title' | translate}}
      </div>
    </div>
    <hr class="divider">
    <div class="py-5 mx-8">
      <mat-dialog-content>
        <app-input [control]="recoveryForm.get('email')" [type]="'text'" [label]="'passwordRecovery.email' | translate" [placeholder]="'passwordRecovery.emailPlaceholder' | translate"></app-input>
      </mat-dialog-content>
    </div>
    <hr class="my-6 ml-0">
    <mat-dialog-actions class="w-full">
      <app-button-secondary [type]="'submit'">{{'login.continue' | translate}}</app-button-secondary>
    </mat-dialog-actions>
  </form>
</section>
