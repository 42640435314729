import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent {

  @Input() label?: string | undefined;
  @Input() placeholder?: string | undefined;
  @Input() set control(value: AbstractControl | null) { this._control = value as FormControl; }

  _control!: FormControl;
}
