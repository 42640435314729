import { Component, OnInit } from '@angular/core';

import packageJSON from '../../../../../../package.json';
import { INFO_EMAIL } from '../../../../../common/models/constants';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { CacheService } from '../../core/services/cache.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  currentYear: number = new Date().getFullYear();
  contactEmail: string = INFO_EMAIL;
  lang: string = this.cache.lang;
  version = packageJSON.version;

  constructor(private cache: CacheService) {
  };

  ngOnInit() {
    MainEventService.localeChanged.subscribe(() => this.lang = this.cache.lang);
  }

}
