import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeCl from '@angular/common/locales/es-CL';
import localeMx from '@angular/common/locales/es-MX';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import isString from 'lodash-es/isString';
import merge from 'lodash-es/merge';
import { forkJoin, Observable } from 'rxjs';

import { LOCALES } from '../models/main';


// Magia arcana para meter el puto espacio entre el ISO code y el valor numerico
// @ts-ignore
localeEn[14][2] = '¤ #,##0.00';
// @ts-ignore
localeCl[14][2] = '¤ #,##0.00';

registerLocaleData(localeEs);
registerLocaleData(localeEn);
registerLocaleData(localeCl);
registerLocaleData(localeMx);

export class HttpCustomLoader implements TranslateLoader {

  constructor(private http: HttpClient) {}

  getTranslation(locale: LOCALES): Observable<any> {
    return new Observable((observer) => {
      const obs = [this.http.get(`/assets/i18n/common/${locale}.json`), this.http.get(`/assets/i18n/${locale}.json`)];
      if (locale === LOCALES.CL || locale === LOCALES.MX) {
        obs.push(this.http.get(`/assets/i18n/common/${LOCALES.ES}.json`));
        obs.push(this.http.get(`/assets/i18n/${LOCALES.ES}.json`));
      }
      forkJoin(obs).subscribe(res => {
        let translations = merge(res[0], res[1]);
        if (locale === LOCALES.CL || locale === LOCALES.MX) {
          const esTranslations = merge(res[2], res[3]);
          translations = merge(esTranslations, translations);
        }
        observer.next(translations);
      });
    });
  }
}

export const compareI18N = (esObject: any, enObject: any, path: string): { equals: boolean, reason: string } => {
  const esKeys = Object.keys(esObject);
  const enKeys = Object.keys(enObject);
  if (esKeys.length !== enKeys.length) return { equals: false, reason: `Not same number of keys in ${path} - ${esKeys} vs ${path} - ${enKeys}` };
  for (let i = 0; i < esKeys.length; i++) {
    if (esKeys[i] !== enKeys[i]) return { equals: false, reason: `Not same value in position ${i}: ${path} - ${esKeys[i]} !== ${path} - ${enKeys[i]}` };
    if (!isString(esObject[esKeys[i]])) {
      const res = compareI18N(esObject[esKeys[i]], enObject[enKeys[i]], path + ' - ' + esKeys[i]);
      if (!res.equals) return res;
    }
  }
  return { equals: true, reason: '' };
};

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.error('Valor i18n no encontrado: ' + params.key);
    return params.key;
  }
}
