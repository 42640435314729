import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  file: File | undefined;
  emptyFile = false;
  // Se puede recibir el fichero con el emitter o con el metodo getFile
  @Output() newFileEvent = new EventEmitter();

  constructor() {
  }

  fileBrowseHandler(target: any) {
    this.file = target.files[0];
    this.checkFile();
    this.newFileEvent.emit(this.file);
  }

  checkFile() {
    this.emptyFile = !this.file;
  }

  getFile() {
    this.checkFile();
    return this.file;
  }

}
