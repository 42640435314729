import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, noop } from 'rxjs';

import { GenericModalComponent } from '../../../../../../common/components/generic-modal/generic-modal.component';
import { MODAL_WIDTH } from '../../../../../../common/models/constants';
import { CanDeactivateComponent, GenericModalData, NOTIFICATION_CHANNEL, NOTIFICATION_TIMES, NOTIFICATION_TYPES, User, UserNotification } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { CacheService } from '../../../core/services/cache.service';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-notifications-configuration',
  templateUrl: './notifications-configuration.component.html',
  styleUrls: ['./notifications-configuration.component.scss'],
})
export class NotificationsConfigurationComponent implements OnInit, CanDeactivateComponent {
  NOTIFICATION_TIMES = NOTIFICATION_TIMES;
  webhookValidated = true;
  notificationConfigurationForm!: FormGroup;
  matrix!: Record<string, Array<string>> | undefined;
  matrixCopy!: Record<string, Array<string>> | undefined;
  rows!: string[];
  columns!: string[];
  user!: User;
  browserPermissions: boolean = true;

  constructor(private api: ApiService, private cache: CacheService, private fb: FormBuilder, private dialog: MatDialog, private helper: HelperService) { }

  ngOnInit() {
    this.startComponent();
    this.checkBrowserPermissions().then(noop);
  }

  startComponent() {
    this.api.getUser().subscribe(res => {
      this.user = res.user;
      this.matrix = res.user.notifications.settings;
      this.matrixCopy = JSON.parse(JSON.stringify(this.matrix));
      this.notificationConfigurationForm = this.createNotificationConfigurationForm(this.user.notifications);
    });
    // quitamos newOrganizationMember porque no tiene que aparecer en la matriz de configuración
    this.rows = Object.values(NOTIFICATION_TYPES).filter(type => type !== NOTIFICATION_TYPES.NewOrganizationMember);
    this.columns = Object.values(NOTIFICATION_CHANNEL);
  }

  createNotificationConfigurationForm(notification: UserNotification) {
    let aggregateTime = '';
    let customTime = '00:00';
    const times = Object.values(NOTIFICATION_TIMES) as string[];
    if (!times.includes(notification.aggregateTime.toString())) {
      const hours = Math.floor(Number(notification.aggregateTime) / 60);
      const minutes = Number(notification.aggregateTime) % 60;
      customTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`; // ñapa para que salgan dos ceros
      aggregateTime = 'customized';
    } else {
      aggregateTime = notification.aggregateTime.toString();
    }
    return this.fb.group({
      aggregateTime: [aggregateTime],
      customised: [customTime],
      alertTime: [0],
      webhook: [notification?.webhook],
    });
  }

  save() {
    const form = this.notificationConfigurationForm.getRawValue();
    let webhookNeeded = false;
    this.rows.forEach(row => {
      if (this.matrix![row].some(col => col === NOTIFICATION_CHANNEL.Webhook)) webhookNeeded = true;
    });
    this.webhookValidated = this.validateWebhook(form.webhook);
    if (!this.webhookValidated) return this.api.logError('toast.URLFormatWrong', true);
    if (!form.webhook && webhookNeeded) return this.api.logWarn('toast.webhookNeeded', true);
    if (form.aggregateTime === NOTIFICATION_TIMES.Customized) {
      const time = form.customised.split(':');
      form.aggregateTime = (Number(time[0]) * 60) + Number(time[1]);
    } else {
      form.aggregateTime = Number(form.aggregateTime);
    }
    form.settings = this.matrix;
    this.api.updateNotificationsConfiguration(this.user.id, form).subscribe(() => {
      this.startComponent();
      this.api.log('toast.saved');
    });
  }

  changeValue(row: string, column: string, index: number, event: MatCheckboxChange) {
    if (this.matrix) {
      if (event.checked) {
        this.matrix[row].splice(index, 0, column);
        if (row === 'recordEventCustomIssueOpen') {
          this.matrix['recordEventCustomIssueReopen'].splice(index, 0, column);
          this.matrix['recordEventCustomIssueClosed'].splice(index, 0, column);
        }
      } else {
        this.matrix[row] = this.matrix[row].filter(e => e !== column);
        if (row === 'recordEventCustomIssueOpen') {
          this.matrix['recordEventCustomIssueReopen'] = this.matrix['recordEventCustomIssueReopen'].filter(e => e !== column);
          this.matrix['recordEventCustomIssueClosed'] = this.matrix['recordEventCustomIssueClosed'].filter(e => e !== column);
        }
      }
    }
  }

  validateWebhook(data: string): boolean {
    if (!data) return true;
    return (/^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/).test(data);
  }

  async checkBrowserPermissions() {
    const notificationPermission = await Notification.requestPermission();
    if (notificationPermission !== 'granted') this.browserPermissions = false;
  }

  async canDeactivate(): Promise<boolean> {
    const matrixToString = JSON.stringify(this.matrix);
    const matrixCopyToString = JSON.stringify(this.matrixCopy);
    if (this.notificationConfigurationForm.touched || matrixToString !== matrixCopyToString) {
      const data: GenericModalData = this.helper.translateModal({
        title: 'modalUnsavedChanges.title',
        content: 'modalUnsavedChanges.message',
        buttons: [{ label: 'buttons.back', value: false, type: 'link' }, { label: 'modalUnsavedChanges.button', value: true, type: 'button' }],
      });
      return await firstValueFrom(this.dialog.open(GenericModalComponent, { ...MODAL_WIDTH, data, disableClose: true }).afterClosed());
    }
    return true;
  }
}
