import { Component, OnInit } from '@angular/core';

import { CacheService } from '../../core/services/cache.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  isGuest: boolean = true;
  isAdmin: boolean = false;
  constructor(private cache: CacheService) { }

  ngOnInit() {
    this.cache.getUser().subscribe((user) => {
      this.isGuest = !user.organization;
      this.isAdmin = user.isAdmin;
    });
  }
}
