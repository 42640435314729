import { Component, Input } from '@angular/core';

import { LOCALES, USER_TYPE } from '../../models/main';
import { ApiService } from '../../services/api.service';
import { MainCacheService } from '../../shared/main-chache.service';


@Component({
  selector: 'app-menu-locale',
  templateUrl: './menu-locale.component.html',
  styleUrls: ['./menu-locale.component.scss'],
})
export class MenuLocaleComponent {

  @Input() isMenuShown = true;
  @Input() cornerPosition = false;

  LOCALES: LOCALES[] = Object.values(LOCALES);

  constructor(private cache: MainCacheService, private api: ApiService) {
  }

  changeLocale(locale: LOCALES) {
    this.cache.locale = locale;

    if (this.cache.getToken()) {
      this.cache.getUser().subscribe(res => {
        const obs = res.type === USER_TYPE.USERS ? this.api.editUser({ locale }) : this.api.updateAdmin(res.id, { locale: locale });
        obs.subscribe(() => window.location.reload());
      });
    }
  }
}
