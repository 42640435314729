import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CacheService } from '../../../front/src/app/core/services/cache.service';
import { ASTRONOMICAL_BODY, ConfigForm, Organization, PERMACTION } from '../../models/main';
import { RecordService } from '../../services/record.service';
import { CustomValidators } from '../../shared/custom-validators';

@Component({
  selector: 'app-organization-data-form[conf]',
  templateUrl: './organization-data-form.component.html',
  styleUrls: ['./organization-data-form.component.scss'],
})

export class OrganizationDataFormComponent implements OnInit, OnDestroy {
  @Input() conf!: ConfigForm<Organization>;
  @Input() showFavoritesButton? = false;
  @Output() aliasChangeEvent = new EventEmitter();

  form!: FormGroup;
  isGuest = false;

  static fields = ['name', 'alias', 'legalId', 'email', 'address', 'phone', 'contactName', 'contactEmail', 'contactPhone'];

  constructor(private fb: FormBuilder, private recordService: RecordService, private cache: CacheService) {
  }

  ngOnInit() {
    this.form = this.conf.form;
    this.cache.getUser().subscribe(user => this.isGuest = !user.organization);
    OrganizationDataFormComponent.addControls(this.form, this.conf.data, this.fb, this.conf.permissions!);

    if (this.conf.disabledFields) this.conf.disabledFields.forEach(field => this.form.get(field)?.disable());
  }

  ngOnDestroy(): void {
    OrganizationDataFormComponent.removeControls(this.form);
    this.recordService.desyncForms();
  }

  // Es statico xq lo usa el custom actor
  static addControls(form: FormGroup, data: Organization, fb: FormBuilder, permissions: PERMACTION[]) {
    const orgForm = fb.group({
      name: [data.name],
      alias: [data.alias],
      legalId: [data.legalId],
      email: [data.email, CustomValidators.validateEmail],
      address: fb.group({
        astronomicalBody: [data.address?.astronomicalBody || ASTRONOMICAL_BODY.Earth],
        country: [data.address?.country],
        subdivision: [data.address?.subdivision],
        locality: [data.address?.locality],
        street: [data.address?.street],
        postalCode: [data.address?.postalCode],
      }),
      phone: [data.phone],
      contactName: [data.contactName],
      contactEmail: [data.contactEmail, CustomValidators.validateEmail],
      contactPhone: [data.contactPhone],
    });

    OrganizationDataFormComponent.fields.forEach(field => form.addControl(field, orgForm.get(field)!));
    if (!permissions?.includes(PERMACTION.update)) OrganizationDataFormComponent.fields.forEach(field => form.get(field)?.disable({ emitEvent: false }));
  }

  static removeControls(form: FormGroup) {
    OrganizationDataFormComponent.fields.forEach(field => form.removeControl(field));
  }
}
